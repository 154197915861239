@import "../styles/colors";

.SignIn {
	// border: 1px solid red;
	text-align: center;
	padding: 20px 0px;
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	gap: 20px;

	.SignIn-header {
		// width: 100%;
		// border: 1px solid green;

		h1 {
			font-size: 1.2em;
		}

		h2 {
			font-size: 1em;
			color: $darkerGrey;
		}
	}

	.AuthLogo {
		height: 32px;
		margin-bottom: 15px;
	}

	.SignIn-button {
		width: 200px;
		border-radius: 10px;
		background-color: white;
		font-weight: bold;
		border: none;
		padding: 10px;
		color: $pink;
		cursor: pointer;
		transition: 0.3s;
		border: 1px solid $pink;
		margin-bottom: 10px;
		background-color: $pink;
		color: white;

		&:hover {
			background-color: $darkerPink;
		}
	}
}
