.SignIn {
	text-align: center;
	padding: 20px 0px;
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
}
.SignIn .SignIn-header h1 {
	font-size: 1.2em;
}
.SignIn .SignIn-header h2 {
	font-size: 1em;
	color: #4b4b4b;
}
.SignIn .AuthLogo {
	height: 32px;
	margin-bottom: 15px;
}
.SignIn .SignIn-button {
	width: 200px;
	border-radius: 10px;
	background-color: white;
	font-weight: bold;
	border: none;
	padding: 10px;
	color: #d43f8c;
	cursor: pointer;
	transition: 0.3s;
	border: 1px solid #d43f8c;
	margin-bottom: 10px;
	background-color: #d43f8c;
	color: white;
}
.SignIn .SignIn-button:hover {
	background-color: #972c63;
} /*# sourceMappingURL=Auth.css.map */
