.gigauser-Icon-parent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: -moz-max-content;
	min-width: max-content;
	position: relative;
}
.gigauser-Icon-parent.icon-disabled {
	cursor: auto;
	opacity: 0.5;
}
.gigauser-Icon svg {
	margin: 0 auto;
}

.WhiteHoverTip {
	position: relative;
	margin-top: 0.125rem;
	z-index: 1000000000;
}

.HoverTip {
	margin-top: 60px;
	min-width: -moz-max-content;
	min-width: max-content;
	position: absolute;
	border-radius: 5px;
	color: white;
	padding: 4px 5px;
	animation-name: popup;
	animation-duration: 0.1s;
	font-size: 0.8rem;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #0e1015;
	z-index: 999;
}
.HoverTip.show-arrow.bottom:before {
	content: " ";
	height: 0;
	position: absolute;
	width: 0;
	bottom: 1.5rem;
	left: calc(50% - 6px);
	border: 6px solid transparent;
	border-right-color: #0e1015;
	rotate: 90deg;
	pointer-events: none;
}
.HoverTip.show-arrow.top {
	margin-bottom: 128px;
}
.HoverTip.show-arrow.top:before {
	content: " ";
	height: 0;
	position: absolute;
	width: 0;
	top: 1.1rem;
	left: calc(50% - 6px);
	border: 8px solid transparent;
	border-right-color: #0e1015;
	rotate: -90deg;
	pointer-events: none;
}
.HoverTip .hovertip-text {
	margin-left: 1px;
	font-size: 0.9em;
}
.HoverTip .hovertip-shortcut {
	background-color: white;
	border-radius: 3px;
	color: black;
	padding: 3px 6px 3px 5px;
	font-size: 0.85em;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	margin: 2px 1px 2px 6px;
}

@keyframes popup {
	0% {
		transform: scale(0.1, 0.1);
		padding: 5x;
	}
	100% {
		padding: 5x;
		transform: scale(1, 1);
	}
} /*# sourceMappingURL=Icon.css.map */
