@import "../../../../../../../styles/colors";
@import "../../../../../../../styles/shadows";

.ImgShape-container {
	position: absolute;
	border-radius: 3px;
	color: transparent;
	user-select: none;

	display: flex;
	align-items: center;
	justify-content: center;

	// border: 3px solid transparent;

	.ImgShape {
		height: 100%;
		width: 100%;
		transition-property: transform; /* This applies the transition only to the transform property */
		transition-duration: 0.3s; /* This sets the duration of the transition */

		&.rectangle,
		&.blur {
			// border-radius: 2px;
		}

		&.arrow {
			// border: 1px solid blue;

			.ArrowLine {
				// border-radius: 10px;
			}

			.ArrowHead {
				position: absolute;
				bottom: 0;
				right: 0;
				// transform: translate(140%, -50%) rotate(45deg); /* Position and align the arrowhead */
			}
		}

		&.circle {
			border-radius: 999999px;
		}

		&.blur {
			filter: blur(5px);
			backdrop-filter: blur(2px);
		}
	}
}
