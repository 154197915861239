.skeleton {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 0.933rem;
	border-radius: 4px;
}
.skeleton::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-100%);
	background-image: var(--shimmer-gradient);
	background-size: var(--sheen-width, 3.33rem) 100%;
	background-repeat: no-repeat;
	animation: shimmer var(--animation-duration, 2s) infinite linear;
	content: "";
}
.skeleton--circle {
	border-radius: 50%;
}
.skeleton--rounded {
	border-radius: 1.6rem;
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
} /*# sourceMappingURL=Skeleton.css.map */
