// @import url('https://fonts.googleapis.com/css?family=Nunito:800i&display=swap');

$zoomFactor: 0.3;

.ThemeSwitch-wrapper {
	box-sizing: inherit;
	font-size: 86px * $zoomFactor;
	margin-right: 40px * $zoomFactor;
	display: flex;
	align-items: center;

	//*
	box-sizing: inherit;
	font-size: 86px * $zoomFactor;
	margin-right: 40px * $zoomFactor;
	//   zoom: 0.3;
	display: flex;
	align-items: center;
}

#hide-checkbox {
	opacity: 0;
	height: 0;
	width: 0;
}

.toggle {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 200px * $zoomFactor;
	height: 100px * $zoomFactor;
	background: rgb(var(--gigauser-brand-color-rgb), 0.5);
	border-radius: 50px * $zoomFactor;
	transition: 500ms;
	overflow: hidden;
}

.toggle-button {
	position: absolute;
	display: inline-block;
	top: 7px * $zoomFactor;
	left: 6px * $zoomFactor;
	width: 86px * $zoomFactor;
	height: 86px * $zoomFactor;
	border-radius: 50%;
	background: #faeaf1;
	overflow: hidden;
	box-shadow: 0 0 35px * $zoomFactor 4px * $zoomFactor rgba(255, 255, 255);
	transition: all 500ms ease-out;
}

.crater {
	position: absolute;
	display: inline-block;
	background: #faeaf1;
	border-radius: 50%;
	transition: 500ms;
}

.crater-1 {
	background: #fffff9;
	width: 1em * $zoomFactor;
	height: 1em * $zoomFactor;
	left: 10px * $zoomFactor;
	bottom: 10px * $zoomFactor;
}

.crater-2 {
	width: 20px * $zoomFactor;
	height: 20px * $zoomFactor;
	top: -7px * $zoomFactor;
	left: 44px * $zoomFactor;
}

.crater-3 {
	width: 16px * $zoomFactor;
	height: 16px * $zoomFactor;
	top: 20px * $zoomFactor;
	right: -4px * $zoomFactor;
}

.crater-4 {
	width: 10px * $zoomFactor;
	height: 10px * $zoomFactor;
	top: 24px * $zoomFactor;
	left: 30px * $zoomFactor;
}

.crater-5 {
	width: 15px * $zoomFactor;
	height: 15px * $zoomFactor;
	top: 40px * $zoomFactor;
	left: 48px * $zoomFactor;
}

.crater-6 {
	width: 10px * $zoomFactor;
	height: 10px * $zoomFactor;
	top: 48px * $zoomFactor;
	left: 20px * $zoomFactor;
}

.crater-7 {
	width: 12px;
	height: 12px;
	bottom: 5px;
	left: 35px;
}

.star {
	position: absolute;
	display: inline-block;
	border-radius: 50%;
	background: #fff;
	box-shadow: 1px * $zoomFactor 0 2px * $zoomFactor 2px * $zoomFactor
		rgba(255, 255, 255);
}

.star-1 {
	width: 6px * $zoomFactor;
	height: 6px * $zoomFactor;
	right: 90px * $zoomFactor;
	bottom: 40px * $zoomFactor;
}

.star-2 {
	width: 8px * $zoomFactor;
	height: 8px * $zoomFactor;
	right: 70px * $zoomFactor;
	top: 10px * $zoomFactor;
}

.star-3 {
	width: 5px * $zoomFactor;
	height: 5px * $zoomFactor;
	right: 60px * $zoomFactor;
	bottom: 15px * $zoomFactor;
}

.star-4 {
	width: 3px * $zoomFactor;
	height: 3px * $zoomFactor;
	right: 40px * $zoomFactor;
	bottom: 50px * $zoomFactor;
}

.star-5 {
	width: 4px * $zoomFactor;
	height: 4px * $zoomFactor;
	right: 10px * $zoomFactor;
	bottom: 35px * $zoomFactor;
}

.star-6,
.star-7,
.star-8 {
	width: 10px * $zoomFactor;
	height: 2px * $zoomFactor;
	border-radius: 2px * $zoomFactor;
	transform: rotate(-45deg);
	box-shadow: 5px * $zoomFactor 0px 4px * $zoomFactor 1px * $zoomFactor #fff;
	animation-name: travel;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

.star-6 {
	right: 30px * $zoomFactor;
	bottom: 30px * $zoomFactor;
	animation-delay: -2s;
}

.star-7 {
	right: 50px * $zoomFactor;
	bottom: 60px * $zoomFactor;
}

.star-8 {
	right: 90px * $zoomFactor;
	top: 10px * $zoomFactor;
	animation-delay: -4s;
}

@keyframes travel {
	0% {
		transform: rotate(-45deg) translateX(70px);
	}

	50% {
		transform: rotate(-45deg) translateX(-20px);
		box-shadow: 5px * $zoomFactor 0px * $zoomFactor 6px * $zoomFactor 1px *
			$zoomFactor #fff;
	}

	100% {
		transform: rotate(-45deg) translateX(-30px);
		width: 2px * $zoomFactor;
		height: 2px * $zoomFactor;
		opacity: 0;
		box-shadow: none;
	}
}

#hide-checkbox:checked + .toggle {
	background: rgb(var(--gigauser-brand-color-rgb), 0.7);
}

#hide-checkbox:checked + .toggle .toggle-button {
	background: #f7ffff;
	transform: translateX(102px * $zoomFactor);
	box-shadow: 0 0 35px * $zoomFactor 5px * $zoomFactor rgba(255, 255, 255);
}

#hide-checkbox:checked + .toggle .toggle-button .crater {
	transform: rotate(-45deg) translateX(70px * $zoomFactor);
}

#hide-checkbox:checked + .toggle .star {
	animation: move 2s infinite;
	transform: none;
	box-shadow: none;
}

#hide-checkbox:checked + .toggle .star-1 {
	width: 40px * $zoomFactor;
	height: 10px * $zoomFactor;
	border-radius: 10px * $zoomFactor;
	background: #fff;
	left: 20px * $zoomFactor;
	top: 25px * $zoomFactor;
	box-shadow: none;
}

#hide-checkbox:checked + .toggle .star-2 {
	width: 12px * $zoomFactor;
	height: 12px * $zoomFactor;
	background: #fff;
	left: 26px * $zoomFactor;
	top: 23px * $zoomFactor;
	box-shadow: -1px * $zoomFactor 0 2px * $zoomFactor 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-3 {
	width: 16px * $zoomFactor;
	height: 16px * $zoomFactor;
	background: #fff;
	left: 35px * $zoomFactor;
	top: 19px * $zoomFactor;
	box-shadow: -1px * $zoomFactor 0 2px * $zoomFactor 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-4 {
	width: 14px * $zoomFactor;
	height: 14px * $zoomFactor;
	background: #fff;
	left: 46px * $zoomFactor;
	top: 21px * $zoomFactor;
	box-shadow: -1px * $zoomFactor 0 2px * $zoomFactor 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-5 {
	width: 60px * $zoomFactor;
	height: 15px * $zoomFactor;
	border-radius: 15px * $zoomFactor;
	background: #fff;
	left: 30px * $zoomFactor;
	bottom: 20px * $zoomFactor;
	box-shadow: none;
}

#hide-checkbox:checked + .toggle .star-6 {
	width: 18px * $zoomFactor;
	height: 18px * $zoomFactor;
	background: #fff;
	border-radius: 50%;
	left: 38px * $zoomFactor;
	bottom: 20px * $zoomFactor;
	box-shadow: -1px * $zoomFactor 0 2px * $zoomFactor 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-7 {
	width: 24px * $zoomFactor;
	height: 24px * $zoomFactor;
	background: #fff;
	border-radius: 50%;
	left: 52px * $zoomFactor;
	bottom: 20px * $zoomFactor;
	box-shadow: -1px * $zoomFactor 0 2px * $zoomFactor 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-8 {
	width: 21px * $zoomFactor;
	height: 21px * $zoomFactor;
	background: #fff;
	border-radius: 50%;
	left: 70px * $zoomFactor;
	top: 59px * $zoomFactor;
	box-shadow: -1px * $zoomFactor 0 2px * $zoomFactor 0 rgba(0, 0, 0, 0.1);
}

@keyframes move {
	0% {
		transform: none;
	}

	25% {
		transform: translateX(2px * $zoomFactor);
	}

	100% {
		transform: translateX(-2px * $zoomFactor);
	}
}

p.morning {
	background: #e67e22;
}
