@import "../../../../styles/hclayout";
@import "../../../../styles/colors";

.HelpCenterViewer {
	display: flex;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
	margin-left: $indexWidth;

	&.remove-margin {
		margin-left: 0;
		justify-content: center;
	}

	.ViewerContainer {
		height: 100%;
		padding: 0em 3em;
		box-sizing: border-box; /* Padding and border are included within the width */
		width: 900px;
		max-width: 100%;
		padding-top: 6em;

		&.CollectionViewer {
			width: 1000px;
		}
	}

	.TocContainer {
		width: 15%;
		box-sizing: border-box;
		padding: 2em 0em;
		text-align: left;

		&.hide {
			display: none;
		}
	}
}

.HelpCenter-private-container {
	padding: 20px;
	margin-top: 1em;
	text-align: left;
}

.gigauser-light {
	.HelpCenter-private-container {
		color: $darkerGrey;
	}

	.ViewerContainer {
		&.ViewOnly {
			border-left: 1px solid var(--gigauser-light-brand-color);
			border-right: 1px solid var(--gigauser-light-brand-color);
		}
	}

	.gigauser-viewer-branding {
		color: $darkGrey;
	}
}

.gigauser-dark {
	.HelpCenter-private-container {
		color: $mediumGrey;
	}

	.ViewerContainer {
		&.ViewOnly {
			// border-left: 1px solid var(--gigauser-light-brand-color);
			// border-right: 1px solid var(--gigauser-light-brand-color);
		}
	}

	.gigauser-viewer-branding {
		color: $darkGrey;
	}
}
