/* Common Styles */

@import "../../../../../styles/colors";
@import "../../../../../styles/shadows";
.video-slider-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0rem 1rem;
	border-radius: 10px;
}

.video-slider-video {
	max-width: 53rem;
	max-height: 26rem;
	border-radius: 8px;
	margin-bottom: 20px;
}

.video-slider-wrapper {
	display: flex;
	width: 100%; /* Take up the full width of its container */
	align-items: center; /* Center align vertically */
	margin-bottom: 20px;
}

.video-slider-input {
	cursor: pointer;
	outline: none;
	border-radius: 5px;
	appearance: none;
}
.video-slider-input {
	-webkit-appearance: none;
	appearance: none;
	background-repeat: no-repeat; /* Important for our gradient */
	height: 5px; /* Adjust as needed */
	flex-grow: 1;
}

.video-slider-inactive {
	display: inline-flex;
	height: 5px;
	flex-shrink: 0;
}

.video-slider-button {
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.video-slider-button:hover {
	background-color: #555;
}

.video-slider-button:focus {
	outline: none;
}
.video-slider-input::-webkit-slider-thumb {
	appearance: none;
}

.video-slider-explanation {
	// background-color: rgba(255, 255, 255, 0.8);  /* Slightly transparent white for dark mode, giving it an overlay effect */
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 15px;
	text-align: center;
	font-weight: 500;
	max-width: 90%;
	word-wrap: break-word;
	color: white;
}

/* Dark Mode Styles */

.gigauser-dark .video-slider-input {
	background: linear-gradient(to right, $pink, $pink 100%, #444 100%, #444);
}

.gigauser-dark .video-slider-inactive {
	background: #6f6f6f;
}

.gigauser-dark .video-slider-input::-webkit-slider-thumb,
.gigauser-dark .video-slider-input::-moz-range-thumb {
	background: #666;
}

.gigauser-dark .video-slider-button {
	background-color: #444;
	color: white;
}
/* Dark Mode Thumb Styles */
.gigauser-dark .video-slider-input::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	background: #666;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	cursor: pointer;
	transition: background 0.3s;
}

.gigauser-dark .video-slider-input::-moz-range-thumb {
	background: #666;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
	transition: background 0.3s;
}

/* Light Mode Thumb Styles */
.gigauser-light .video-slider-input {
	background: linear-gradient(
		to right,
		$pink,
		$pink 100%,
		#b0b0b0 100%,
		#b0b0b0
	);
}

.gigauser-light .video-slider-input::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	background: $lightGrey;
	border: 2px solid $pink;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
	transition: background 0.3s;
}

.gigauser-light .video-slider-input::-moz-range-thumb {
	background: #aaa;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	cursor: pointer;
	transition: background 0.3s;
}

/* Light Mode Styles */
.gigauser-light .video-slider-explanation {
	// background-color: rgba(0, 0, 0, 0.1);  /* Slightly transparent black for light mode */
}

.gigauser-light .video-slider-inactive {
	background: #f1f1f1;
}

.gigauser-light .video-slider-button {
	background-color: $pink;
	color: white;
}

.gigauser-light .video-slider-button:hover {
	background-color: $darkerPink;
}
