.WhiteHoverTip {
	margin-top: 0.125rem;
	animation-name: popupWhiteHoverTip;
	animation-duration: 0.15s;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 100;
	white-space: nowrap;
	opacity: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	z-index: 2147483647;
}
.WhiteHoverTip .WhiteHoverTip-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: -moz-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: fit-content;
	background: #fafbff;
	box-shadow: 0px 0.25rem 0.25rem 0px rgba(0, 0, 0, 0.2);
	border: 0;
	border-radius: 0.25rem;
	font-family: "Manrope";
	z-index: 999;
}
.WhiteHoverTip .WhiteHoverTip-inner .text-container {
	margin: 0.25rem 0.5rem;
}
.WhiteHoverTip .WhiteHoverTip-inner .text-container .text {
	color: #333545;
	font-size: 0.875rem;
	font-weight: 600;
	line-height: 1.25rem;
	margin: 0;
	padding: 0;
}
.WhiteHoverTip .WhiteHoverTip-inner .shortcut-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0.25rem 0.25rem 0.25rem 0;
}
.WhiteHoverTip
	.WhiteHoverTip-inner
	.shortcut-container
	.shortcut-icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #b1b5c9;
	border-radius: 0.25rem;
	width: 1.75rem;
	height: 1.75rem;
}
.WhiteHoverTip
	.WhiteHoverTip-inner
	.shortcut-container
	.shortcut-icon-container
	.shortcut-icon {
	width: 1.25rem;
	height: 1.25rem;
	color: #6a6c7b;
	margin: 0.25rem;
}
.WhiteHoverTip
	.WhiteHoverTip-inner
	.shortcut-container
	.shortcut-text-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 1.75rem;
	height: 1.75rem;
	border: 1px solid #b1b5c9;
	border-radius: 0.25rem;
}
.WhiteHoverTip
	.WhiteHoverTip-inner
	.shortcut-container
	.shortcut-text-container
	.shortcut-text {
	text-align: center;
	color: #6a6c7b;
	font-family: "Manrope";
	font-weight: 600;
	font-size: 0.75rem;
}
.WhiteHoverTip .WhiteHoverTip-inner .shortcut-container .shortcut-joiner {
	margin: 0.125rem;
	color: #6a6c7b;
	font-family: "Manrope";
	font-weight: 600;
	font-size: 0.75rem;
}

@keyframes popupWhiteHoverTip {
	from {
		opacity: 0;
		transform: translate(-50%, 10px); /* Start slightly below */
	}
	to {
		opacity: 1;
		transform: translate(-50%, 0); /* End perfectly aligned */
	}
} /*# sourceMappingURL=WhiteHoverTip.css.map */
