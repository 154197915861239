.gigauser-helpcenter-text-link {
	white-space: nowrap;
	padding: 5px;
	color: var(--gigauser-brand-color);
	background-color: #f5f7fc;
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s;
}
.gigauser-helpcenter-text-link:hover {
	background-color: var(--gigauser-light-brand-color);
}

.gigauser-dark .gigauser-helpcenter-text-link {
	white-space: nowrap;
	padding: 5px;
	color: var(--gigauser-darkMode-brand-color);
	background-color: rgba(75, 75, 75, 0.4);
	border-radius: 5px;
}
.gigauser-dark .gigauser-helpcenter-text-link:hover {
	background-color: rgba(75, 75, 75, 0.9);
}

.gigauser-dark .create-linkpopup-buttons {
	display: flex;
	align-items: center;
	gap: 1em;
}

.gigauser-dark .create-new-collection {
	margin-top: 1.5rem;
} /*# sourceMappingURL=Link.css.map */
