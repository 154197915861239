.HelpCenterBrowserTopbarContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	z-index: 4;
}

.HelpCenterBrowserTopbar {
	display: flex;
	background-color: rgb(var(--gigauser-background-color-rgb), 0.8);
	-webkit-backdrop-filter: blur(10px);
	backdrop-filter: blur(10px);
	justify-content: space-between;
	align-items: center;
	padding: 1em 3em;
	color: var(--gigauser-brand-color);
	font-weight: 500;
	border-bottom: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.1);
	width: 100%;
	box-sizing: border-box;
	max-width: 92em;
	height: 4.5em;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-left {
	display: flex;
	align-items: center;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-left div {
	cursor: pointer;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-left .brand-logo {
	width: 6.7em;
	padding-right: 20px;
	cursor: pointer;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-left .brand-logo.hide-border {
	border: none;
}
.HelpCenterBrowserTopbar
	.HelpCenterBrowserTopbar-left
	.HelpCenterBrowser-HomeText {
	padding-left: 1.4em;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-center {
	width: 40%;
}
.HelpCenterBrowserTopbar .searchIcon {
	height: 1.2em;
	width: 1.2em;
	padding: 0px 1em;
	box-sizing: content-box;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-search {
	width: 100%;
	min-width: 23em;
	border-radius: 5px;
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.2);
	transition: 0.3s;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-search:hover {
	border: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.6);
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.05);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.HelpCenterBrowserTopbar
	.HelpCenterBrowserTopbar-search
	.hc-searchbar-shortcut {
	font-size: 0.9em;
	width: 4em;
	font-weight: 300;
}
.HelpCenterBrowserTopbar
	.HelpCenterBrowserTopbar-search
	.HelpCenterBrowserTopbar-search-input {
	background-color: transparent;
	border: none;
	height: 100%;
	width: calc(100% - 8.5em);
	padding: 0.8em 0px;
	font-size: 0.9em;
	cursor: pointer;
	transition: 0.2s;
	text-align: left;
	font-weight: 400;
}
.HelpCenterBrowserTopbar
	.HelpCenterBrowserTopbar-search
	.HelpCenterBrowserTopbar-search-input:focus {
	outline: none;
}
.HelpCenterBrowserTopbar
	.HelpCenterBrowserTopbar-search
	.HelpCenterBrowserTopbar-search-input:focus::-moz-placeholder {
	color: transparent;
}
.HelpCenterBrowserTopbar
	.HelpCenterBrowserTopbar-search
	.HelpCenterBrowserTopbar-search-input:focus::placeholder {
	color: transparent;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-minisearch {
	width: 1.4em;
	height: 1.4em;
	display: flex;
	align-items: center;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-minisearch .searchIcon {
	height: 1.4em;
	width: 1.4em;
	padding: 0.6em;
	border-radius: 9999px;
	transition: 0.3s;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-minisearch .searchIcon:hover {
	background-color: var(--gigauser-light-brand-color);
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-right {
	display: flex;
	align-items: center;
	gap: 2em;
}
.HelpCenterBrowserTopbar .HelpCenterBrowserTopbar-right div {
	cursor: pointer;
}

.light .HelpCenterBrowserTopbar-left .HelpCenterBrowser-HomeText {
	border-left: 1px solid #d6e2f0;
}
.light .HelpCenterBrowserTopbar-search-input {
	color: var(--gigauser-brand-color);
}

.gigauser-dark .HelpCenterBrowserTopbar {
	background-color: rgba(15, 17, 23, 0.7);
	color: white;
	border-bottom: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1);
}
.gigauser-dark .HelpCenterBrowserTopbar-left .brand-logo.no-dark-logo {
	filter: invert(100%) saturate(0%) brightness(10000);
}
.gigauser-dark .HelpCenterBrowserTopbar-left .HelpCenterBrowser-HomeText {
	border-left: 1px solid rgba(255, 255, 255, 0.5);
}
.gigauser-dark .HelpCenterBrowserTopbar-search-input {
	color: var(--gigauser-light-brand-color);
}
.gigauser-dark .HelpCenterBrowserTopbar-minisearch .searchIcon:hover {
	background-color: #4b4b4b;
}
.gigauser-dark .HelpCenterBrowserTopbar-search {
	border: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.4);
}
.gigauser-dark .HelpCenterBrowserTopbar-search:hover {
	border: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.6);
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.05);
	box-shadow:
		0 2px 4px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2),
		0 3px 10px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2);
} /*# sourceMappingURL=HelpCenterBrowserTopbar.css.map */
