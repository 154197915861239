@import "../../../../styles/colors";
@import "../../../../styles/shadows";

.HelpCenterBrowserTopbarContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	z-index: 4;
	// background-color: white;
}

.HelpCenterBrowserTopbar {
	display: flex;
	background-color: rgb(var(--gigauser-background-color-rgb), 0.8);
	backdrop-filter: blur(10px);
	justify-content: space-between;
	align-items: center;
	padding: 1em 3em;
	color: var(--gigauser-brand-color);
	font-weight: 500;
	border-bottom: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.1);
	width: 100%;
	box-sizing: border-box;
	max-width: 92em;
	height: 4.5em;

	// &::before {
	//     content: "";
	//     position: absolute;
	//     left: 0;
	//     right: 0;
	//     bottom: 0;
	//     height: 3px; /* Adjust the thickness of the fading border */
	//     background-image: linear-gradient(to left, white, transparent, white);
	//     background-size: 110% 110%; /* Adjust the gradient size */
	//   }

	.HelpCenterBrowserTopbar-left {
		display: flex;
		align-items: center;

		div {
			cursor: pointer;
		}

		.brand-logo {
			width: 6.7em;
			padding-right: 20px;
			cursor: pointer;

			&.hide-border {
				border: none;
			}
		}

		.HelpCenterBrowser-HomeText {
			padding-left: 1.4em;
		}
	}

	.HelpCenterBrowserTopbar-center {
		width: 40%;
	}

	.searchIcon {
		height: 1.2em;
		width: 1.2em;
		padding: 0px 1em;
		box-sizing: content-box;
	}

	.HelpCenterBrowserTopbar-search {
		width: 100%;
		min-width: 23em;
		border-radius: 5px;
		background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
		display: flex;
		align-items: center;
		cursor: pointer;
		border: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.2);
		transition: 0.3s;

		&:hover {
			border: 1px solid rgb(var(--gigauser-brand-color-rgb), 0.6);
			background-color: rgb(var(--gigauser-brand-color-rgb), 0.05);
			box-shadow: $lighterBoxShadow;
		}

		.hc-searchbar-shortcut {
			font-size: 0.9em;
			width: 4em;
			font-weight: 300;
			// padding: 0em 0.6em;
		}

		.HelpCenterBrowserTopbar-search-input {
			background-color: transparent;
			border: none;
			height: 100%;
			width: calc(100% - 8.5em);
			padding: 0.8em 0px;
			font-size: 0.9em;
			cursor: pointer;
			transition: 0.2s;
			text-align: left;
			font-weight: 400;

			&:focus {
				outline: none;
				&::placeholder {
					color: transparent;
				}
			}
		}
	}

	.HelpCenterBrowserTopbar-minisearch {
		width: 1.4em;
		height: 1.4em;
		display: flex;
		align-items: center;

		.searchIcon {
			height: 1.4em;
			width: 1.4em;
			padding: 0.6em;
			// border: 1px solid var(--gigauser-brand-color);
			// background-color: var(--gigauser-light-brand-color);
			border-radius: 9999px;
			transition: 0.3s;

			&:hover {
				background-color: var(--gigauser-light-brand-color);
			}
		}
	}

	.HelpCenterBrowserTopbar-right {
		display: flex;
		align-items: center;
		gap: 2em;

		.Login-CTA {
			// background-color: var(--gigauser-brand-color);
			// padding: 0.6em 1em ;
			// border-radius: 999px;
			// color: white;
			// border: 1px solid transparent;
			// transition: 0.3s;

			// &:hover{
			//     border: 1px solid var(--gigauser-brand-color);
			//     background-color: white;
			//     color: var(--gigauser-brand-color);
			// }
		}

		div {
			cursor: pointer;
		}
	}
}

.ThemeSwitch-hcBrowserTopbar {
}

.light {
	.HelpCenterBrowserTopbar-left {
		.HelpCenterBrowser-HomeText {
			border-left: 1px solid $bluishGrey;
		}
	}

	.HelpCenterBrowserTopbar-search-input {
		color: var(--gigauser-brand-color);
	}
}

.gigauser-dark {
	.HelpCenterBrowserTopbar {
		background-color: transparentize($color: $darkModeBg, $amount: 0.3);
		color: white;
		border-bottom: 1px solid
			rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1);
	}

	.HelpCenterBrowserTopbar-left {
		.brand-logo.no-dark-logo {
			filter: invert(100%) saturate(0%) brightness(10000);
		}
		.HelpCenterBrowser-HomeText {
			border-left: 1px solid transparentize(white, 0.5);
		}
	}

	.HelpCenterBrowserTopbar-search-input {
		color: var(--gigauser-light-brand-color);
	}

	.HelpCenterBrowserTopbar-minisearch {
		.searchIcon {
			&:hover {
				background-color: $darkerGrey;
			}
		}
	}

	.HelpCenterBrowserTopbar-search {
		border: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.4);

		&:hover {
			border: 1px solid rgb(var(--gigauser-darkMode-brand-color-rgb), 0.6);
			background-color: rgb(var(--gigauser-brand-color-rgb), 0.05);
			box-shadow:
				0 2px 4px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2),
				0 3px 10px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2);
		}
	}
}
