@import "../../../styles/colors";
@import "../../../styles/shadows";

.gigauser-guide-viewer-container {
	position: relative;
	display: flex;
	flex-flow: column;

	&.gigauser-mobile {
		height: calc(100vh - 43px);
	}
	//overflow: hidden;
}

.gigauser-viewer-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 24px;

	&.gigauser-mobile {
		background-color: var(--gigauser-brand-color);
		padding: 20px;
	}

	&.gigauser-laptop {
		margin-top: 20px;
	}

	.Viewer-created-at {
	}
}

.gigauser-guideviewer-text-container {
	&.gigauser-text-laptop {
		padding-right: 8em;
	}

	.gigauser-viewer-branding {
		text-align: left;
		// padding-left: 24px;
		margin: 2rem 0px;
		display: flex;
		align-items: center;
		gap: 0.7em;
		text-decoration: none;
	}

	.gigauser-viewer-logo {
		height: 1.4em;
	}
}

.gigauser-viewer-body {
	padding: 0px 24px;

	&.gigauser-content-only {
		padding: 0px;
	}

	&.gigauser-mobile {
		box-sizing: border-box;
		overflow-y: auto;
	}
}

.gigauser-viewer-back {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px 8px;
	transition: 0.3s;
	border-radius: 99px;
	font-weight: 600;

	&.gigauser-laptop {
		color: var(--gigauser-brand-color);
		background-color: $clipboardGrey;

		&:hover {
			background-color: var(--gigauser-brand-color);
			color: white;
			.gigauser-viewer-back-button {
				// translate: 5px;
				filter: $filter_white;
			}
		}
	}

	.gigauser-viewer-back-button {
		height: 20px;
		width: 20px;
		transform: rotate(90deg);
		transition: 0.3s;
		border-radius: 99px;

		&.gigauser-mobile {
			&:hover {
				background-color: rgba(255, 255, 255, 0.173);
			}
		}
	}

	.gigauser-header-title {
		margin-left: 10px;
		color: white;
		font-size: 1.2em;
		max-width: 230px;

		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.gigauser-back-text {
		margin-left: 10px;
	}
}

.gigauser-viewer-close-helpcenter {
	height: 16px;
	width: 16px;
	margin-right: 8px;
	border-radius: 999px;
	cursor: pointer;
	transition: 0.3s;
	opacity: 80%;

	&:hover {
		opacity: 60%;
	}
}

// .gigauser-top-sticky{
//     position: sticky;
//     top: 0;
//     z-index: 3;
// }

.gigauser-viewer-title {
	text-align: left;
	color: black;
	margin-bottom: 2.3em;

	.Viewer-creator {
		font-weight: 500;
		color: $darkGrey;
		font-size: 0.9rem;

		.Viewer-creator-email {
			font-weight: bold;
			transition: 0.3s;
			margin-left: 0.3rem;
			border-radius: 3px;

			background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
			padding: 3px 5px;
			border-radius: 4px;
			font-weight: 500;
			color: var(--gigauser-brand-color);
			user-select: text;

			&:hover {
			}
		}
	}

	.gigauser-viewer-title-text {
		text-align: left;
		margin: 10px 0px;

		&.gigauser-name {
			margin-top: 30px;
			margin-bottom: 20px;
			font-size: 1.5em;
			font-weight: 600;

			&.l {
				font-size: 1.6em;
			}
		}

		&.gigauser-description {
			color: var(--gigauser-brand-color);
			font-weight: 500;
			margin-bottom: 1.5rem;
			font-size: 1.3em;

			&.l {
				line-height: 1.6em;
			}

			&.gigauser-dark {
				&.color-flip {
					color: invert(hsl(0, 6%, 21%));
				}
			}

			&.color-flip {
				color: hsl(0, 6%, 21%);
			}
		}
	}

	&.gigauser-loading-title {
		.gigauser-viewer-title-text {
			background-color: $lightGrey;
			color: transparent;
			border-radius: 5px;
			padding: 4px;
		}

		.gigauser-description {
			width: 70%;
			color: transparent;
		}
	}
}

.gigauser-viewer-tabs {
	margin: 14px 0px;
	margin-top: 20px;
}

.gigauser-guide-container {
	text-align: left;
	padding: 0px;

	.Viewer-l-video {
		margin-bottom: 2em;
	}
}

.gigauser-loading-p {
	background-color: $lightGrey;
	border-radius: 5px;
	color: transparent;
	padding: 5px 0px;
	margin: 5px 0px;
}

.gigauser-loading-img {
	background-color: $lightGrey;
	border-radius: 5px;
	color: transparent;
	margin: 15px 0px;
	height: 200px;
}

.gigauser-loading-title,
.gigauser-loading-guide {
	overflow-x: hidden;
	overflow-y: hidden;
	&:after {
		background: linear-gradient(
			90deg,
			transparent,
			rgba(255, 255, 255, 0.2),
			transparent
		);
		content: "";
		display: block;
		position: absolute;
		top: 40px;
		width: 100%;
		height: 100%;
		transform: translateX(-100px);
		animation: gigauserloadingflashes 0.8s infinite;
	}
}

.gigauser-guide-feedback-container {
	&.gigauser-text-laptop {
		padding-right: 8em;
	}
}

.gigauser-guide-feedback {
	margin-top: 30px;
	background-color: $lightGrey;
	border-radius: 10px;
	padding: 20px 40px;
	text-align: center;

	font-weight: 600;
	color: $darkerGrey;

	.gigauser-feedback-1 {
		display: flex;
		align-items: center;
		gap: 32px;
	}

	.gigauser-feedback-2 {
		margin-top: 20px;
		text-align: left;
	}

	.gigauser-feedback-textarea {
		margin-top: 20px;
		width: 100%;
		resize: vertical;
		border-radius: 10px;
		border: 1px solid var(--gigauser-light-brand-color);

		font-size: 0.9em;
		color: $darkestGrey;
		font-family: sans-serif;
		padding: 0.7em;
		outline: none;
		transition: 0.3s;

		&:focus {
			outline: none;
			border: 1px solid var(--gigauser-brand-color);
		}
	}

	.gigauser-feedback-submit {
		margin-top: 20px;
		padding: 12px;
		background-color: var(--gigauser-brand-color);
		width: fit-content;
		color: white;
		border-radius: 10px;
		cursor: pointer;
		transition: 0.3s;

		&:hover {
			box-shadow: $boxShadow;
		}
	}
}

.gigauser-emojis-container {
	.gigauser-feedback-emoji {
		font-size: 32px;
		box-sizing: border-box;
		margin: 0px 6px;
		height: 48px;
		width: 48px;
		border-radius: 999px;
		transition: 0.3s;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		span {
			margin-top: 2px;
		}

		&.gigauser-active-emoji {
			background-color: var(--gigauser-brand-color);
		}

		&.gigauser-inactive-emoji {
			cursor: default;
			filter: grayscale(1);
		}
	}
}

.gigauser-light {
	.gigauser-regular-emoji {
		&:hover {
			background-color: var(--gigauser-light-brand-color);
		}
	}
}

.gigauser-dark {
	.gigauser-guide-feedback {
		background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
		color: $lightGrey;
	}

	.gigauser-regular-emoji {
		&:hover {
			background-color: rgb(var(--gigauser-brand-color-rgb), 0.5);
		}
	}

	.gigauser-feedback-textarea {
		background-color: transparent;
		color: $lightGrey;

		border: 1px solid transparentize($color: white, $amount: 0.8);

		&::placeholder {
			color: var(--gigauser-darkMode-brand-color);
		}
	}
}

@keyframes gigauserloadingflashes {
	100% {
		transform: translateX(100%);
	}
}

.light {
	.gigauser-viewer-title-text {
		color: black;
	}
}

.gigauser-dark {
	.gigauser-viewer-title-text {
		color: var(--gigauser-darkMode-brand-color);

		&.gigauser-description {
			color: $darkModeTextColor;
		}
	}

	.gigauser-loading-title,
	.gigauser-loading-guide {
		&:after {
			display: none;
			background: linear-gradient(
				90deg,
				transparent,
				$darkModeBg,
				transparent
			);
		}
	}

	.gigauser-loading-title {
		.gigauser-viewer-title-text {
			background-color: $darkestGrey;
		}
	}

	.gigauser-loading-img,
	.gigauser-loading-p {
		background-color: $darkestGrey;
	}
}
