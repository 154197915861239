.HelpCenterBrowser {
	display: flex;
	justify-content: center;
}
.HelpCenterBrowser .HelpCenterBrowser-row2 {
	display: flex;
	overflow: hidden;
	width: 100%;
	max-width: 92em;
}

.gigauser-dark .HelpCenterBrowser {
	background-color: rgb(15, 17, 23);
} /*# sourceMappingURL=HelpCenterBrowser.css.map */
