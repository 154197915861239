.gigauser-texteditor-toolbar-button {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.25rem;
	height: 1.25rem;
	cursor: pointer;
}

.ToolbarButton-dropdown-text-item-container {
	display: flex;
	align-items: center;
	width: auto;
	height: auto;
	margin: 0.5rem;
}

.ToolbarButton-dropdown-text-item {
	color: #ffffff;
	font-family: "Manrope";
	font-weight: 500;
	text-align: right;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	line-height: 1.25rem;
	font-size: 0.875rem;
}

.ToolbarButton {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0.125rem;
	padding: 0.5rem;
	border-radius: 0.25rem;
	cursor: pointer;
	transition: all 0.3s;
}
.ToolbarButton.Toolbar-activeButton {
	background:
		linear-gradient(180deg, #b7b3f4 0%, #f1acd0 100%),
		linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-blend-mode: overlay;
}
.ToolbarButton.Toolbar-inactiveButton:hover {
	background-color: rgba(255, 255, 255, 0.08);
	box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2);
}
.ToolbarButton.Toolbar-inactiveButton:hover .ToolbarButton-down-arrow {
	margin-top: 5px;
	color: #ffffff;
}
.ToolbarButton .ToolbarButton-down-arrow {
	width: 0.8rem;
	height: 0.8rem;
	transition: 0.3s;
	color: rgba(255, 255, 255, 0.4);
	margin-left: 0.75rem;
}
.ToolbarButton .ToolbarButton-dropdown {
	margin-top: 0.25rem;
	position: absolute;
	display: none;
	top: 100%;
	left: 0;
	background-color: #13161e;
	border-radius: 0.5rem;
	box-shadow: 0px 0.25rem 0.5rem 0px rgba(0, 0, 0, 0.12);
	z-index: 2147483647;
	animation-name: popupToolbarDropdown;
	animation-duration: 0.1s;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
}
.ToolbarButton .ToolbarButton-dropdown .ToolbarButton-dropdown-list {
	display: flex;
	align-items: center;
	margin: 0.125rem;
}
.ToolbarButton.ToolbarButton-dropdown-item-container {
	display: flex;
	padding: 0;
	margin: 0;
	justify-content: flex-start;
	align-items: center;
	border-radius: 0.375rem;
}
.ToolbarButton .ToolbarButton-dropdown-verticle {
	margin-top: 0.25rem;
	position: absolute;
	top: 100%;
	left: 0;
	background-color: #13161e;
	border-radius: 0.5rem;
	box-shadow: 0px 0.25rem 0.5rem 0px rgba(0, 0, 0, 0.12);
	padding: 0.25rem;
	z-index: 2147483647;
	min-width: 9rem;
	width: -moz-fit-content;
	width: fit-content;
	display: none;
	animation-name: popupToolbarDropdown;
	animation-duration: 0.1s;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
}
.ToolbarButton .ToolbarButton-dropdown-list-verticle {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 13rem;
	gap: 0.25rem;
}

@keyframes popupToolbarDropdown {
	from {
		opacity: 0;
		transform: translate(0%, 10px);
	}
	to {
		opacity: 1;
		transform: translate(0%, 0);
	}
} /*# sourceMappingURL=ToolbarButton.css.map */
