@import "../../../../../../../styles/colors";
@import "../../../../../../../styles/shadows";

$selectionBorder: 0px;

.SizeController {
	background-color: rgb(66, 170, 245);
	border: 2px solid white;
	// box-shadow: $boxShadow;

	// height: 12px;
	// width: 12px;
	border-radius: 99px;

	position: absolute;
	transform: translate(-50%, -50%);

	&.position-t {
		left: 50%;
		top: calc(0% - $selectionBorder/2);
		cursor: n-resize;
	}

	&.position-tl {
		left: calc(0% - $selectionBorder/2);
		top: calc(0% - $selectionBorder/2);
		cursor: nw-resize;
	}

	&.position-tr {
		left: calc(100% + $selectionBorder/2);
		top: calc(0% - $selectionBorder/2);
		cursor: ne-resize;
	}

	&.position-r {
		left: calc(100% + $selectionBorder/2);
		top: 50%;
		cursor: e-resize;
	}

	&.position-br {
		left: calc(100% + $selectionBorder/2);
		top: calc(100% + $selectionBorder/2);
		cursor: se-resize;
	}

	&.position-bl {
		left: calc(0% - $selectionBorder/2);
		top: calc(100% + $selectionBorder/2);
		cursor: sw-resize;
	}

	&.position-b {
		left: 50%;
		top: calc(100% + $selectionBorder/2);
		cursor: s-resize;
	}

	&.position-l {
		left: calc(0% - $selectionBorder/2);
		top: 50%;
		cursor: w-resize;
	}

	&.position-a {
		left: 50%;
		top: 50%;
		background-color: red;
		cursor: all-scroll;

		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.DragController {
	width: 100%;
	height: 100%;
	user-select: none;
	color: transparent;
	position: absolute;
	padding: 10px;

	&.text {
		border: 2px solid $pink;
	}
}
