// @import '../../../styles/';
@import "../../../styles/colors";
@import "../../../styles/shadows";

$iconSize: 1em;
$iconMargin: 5px;
$margin: 1em;
$fontSize: 1rem;

.TitledInput {
	position: relative;

	.error-message {
		margin-top: 5px;
		font-size: 0.66em;
		color: $lightModeRed;
		text-align: left;
	}

	&.titled-dropdown {
		display: inline-flex;
		cursor: pointer;
		user-select: none;
	}

	.Clickout {
		width: 100%;
	}
}

.titledinput-box {
	position: relative;
	border: 1px solid $neutral800;
	border-radius: 5px;
	width: 100%;
	transition: 0.3s;
	text-align: left;

	&.active {
		border: 1px solid $pink;
		box-shadow: 0 0 2px $pink;
	}

	&.sharp {
		border-radius: 3px;
	}

	&.titled-dropdown {
		display: inline-flex;
		justify-content: space-between;
	}
}

.input-icon {
	height: $iconSize;
	width: $iconSize;
	vertical-align: middle;
	margin: 0 5px;
	margin-left: 15px;
	opacity: 0.5;
}

.input-label {
	position: absolute;
	margin: 10px;
	padding: 0 0.5rem;
	background-color: white;
	font-weight: 600;
	color: $darkestGrey;
	user-select: none;

	top: -1.5rem;

	font-size: $fontSize;
}

// .active {

// }
.titledInput {
	font-size: $fontSize;
	padding: 1rem 0;
	padding-left: 10px;
	font-family: "Poppins";
	width: 90%;
	border-radius: 10px;
	background: none;

	&.s {
		padding: 0.5rem 0.5rem;
		font-size: 0.9rem;
	}

	&.xs {
		font-size: 0.8rem;
		padding: 0.5rem;
	}

	&.s {
		padding: 0.5rem 0.5rem;
		font-size: 0.9rem;
	}
	&.l {
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: 1rem;
	}

	&.xl {
		padding-top: 15px;
		padding-bottom: 15px;
		font-size: 1rem;
	}

	// border: 1px solid pink;

	&.no-icons {
		width: 90%;
	}

	&.one-icon {
		width: calc(90% - $iconSize - 2 * $iconMargin);
	}

	&.two-icons {
		width: calc(90% - 2 * $iconSize - 4 * $iconMargin);
	}

	&:focus {
		outline: none;
	}

	&.dropdown-input {
		user-select: none;
	}
}

.dropdown-icon {
	// float: right;
	// margin-top: 0.67em;
	// border: 1px solid green;
	align-self: center;
	margin-right: 1rem;
}

.titledinput-options-container {
	box-shadow: $boxShadow;
	position: absolute;
	// top: 40px;
	margin-top: 0.25rem;
	width: fit-content;
	max-height: 150px;
	border-radius: 0.5rem;
	background-color: white;
	z-index: 100;
	text-align: left;
	overflow: hidden;

	.options-scroll {
		padding: 0.5rem;
		max-height: 150px;
		overflow-y: auto;
	}
}

.titledinput-option {
	padding: 5px 10px;
	font-weight: 500;
	border-radius: 5px;
	user-select: none;
	transition: 0.3s;

	&.s {
		padding: 4px 4px;
		font-size: 0.85rem;
	}

	&:hover {
		background-color: #f2f2f7;
	}

	.icon {
		margin-right: 10px;
		height: 1rem;
		width: 1rem;
	}

	.text {
		margin-right: 20px;
	}
}

.error-input {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;

	.input-label {
		color: $lightModeRed;
	}

	.input-main-container {
		border: 1px solid $lightModeRed;

		&.active {
			box-shadow: 0 0 2px $lightModeRed;
		}
	}

	.input-icon {
		filter: $filter_lightModeRed;
	}

	input {
		&::placeholder {
			color: $lightModeRed;
		}

		color: $lightModeRed;
	}
}

.titledInput:-webkit-autofill,
.titledInput:-webkit-autofill:hover,
.titledInput:-webkit-autofill:focus,
.titledInput:-webkit-autofill:active {
	background-color: transparent;
	border: 3px solid green;
	-webkit-box-shadow: 0 0 0 30px #f2f2f7 inset !important;
}

.gigauser-dark {
	.titledinput-box {
		background-color: $neutral900;
		display: flex;
		align-items: center;

		&.not-disabled {
			border: 1px solid $neutral600;
			background-color: $neutral800;

			&:hover {
				border: 1px solid $neutral300;
				box-shadow: 0px 0px 0px 4px
					transparentize($color: $neutral400, $amount: 0.9);
			}

			&.active {
				border: 1px solid $primary400;
				box-shadow: 0px 0px 0px 4px rgba(233, 155, 195, 0.08);
			}

			.titledInput {
				color: white;

				&::placeholder {
					color: $darkGrey;
				}
			}
		}

		&.disabled {
			border: 1px solid $neutral700;

			.titledInput {
				user-select: none;
				color: $neutral400;
			}
		}

		.dropdown-icon {
			color: white;
		}
	}

	.titledinput-options-container {
		background-color: $neutral800;
	}

	.titledinput-option {
		color: $neutral100;

		&:hover {
			background-color: $neutral700;
		}
	}

	.input-label {
		color: $neutral100;
		// background-color: #0f1117;
		background: linear-gradient(
			to bottom,
			$neutral900 50%,
			$neutral800 50%
		);
	}
}
