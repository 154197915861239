@import "../../../../../../../styles/colors";
@import "../../../../../../../styles/shadows";

.ShapeCustomizer {
	display: flex;
	align-items: center;
	gap: 0.6em;

	.customizer-popup-icon-container {
		position: relative;
	}

	.customizer-icon {
		height: 14px;
		width: 14px;
		box-sizing: content-box;
		padding: 0px 4px;
		padding-bottom: 6px;
		border-bottom: 4px solid;

		&.borderless {
			border-bottom: none;
		}
	}

	.customizer-popup {
		position: absolute;
		right: 0px;
		margin-top: 10px;
		padding: 10px;
		box-shadow: $boxShadow;
		background-color: $sectionBackground;
		border-radius: 5px;
		z-index: 99;
	}

	.StrokeWidthPicker {
		width: 48px;
		user-select: none;

		.StrokeWidthOption {
			margin: 3px 0px;
			transition: 0.3s;
			padding: 3px 6px;
			border-radius: 5px;

			&:hover {
				&.inactive-sw {
					cursor: pointer;
					background-color: $blushGrey;
				}
			}

			&.active-sw {
				background-color: $semiDarkPink;
			}
		}
	}
}
