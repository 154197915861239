@import "../../../../../styles/colors";

.inline-ai-positioner {
	position: relative;
	background: $neutral850;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24);
	display: flex;
	align-items: start;
	border-radius: 0.533rem;
	overflow: hidden;
	flex-direction: column;
	transform-origin: left;
	opacity: 0.92;
	border: 1px solid $neutral500;
	margin-bottom: 2rem;
	cursor: pointer;

	&.expanded {
		opacity: 1;
		cursor: default;
	}

	&:hover {
		opacity: 1;
	}

	.inline-ai-top-row {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;

		.inline-ai-heading {
			color: white;
			font-family: "Manrope";
			font-weight: 600;
			line-height: 1.6rem;
		}

		.inline-ai-icon {
			width: 1.6rem;
			height: 1.6rem;
		}

		.close-icon {
			height: 1.733rem;
			width: 1.733rem;
			cursor: pointer;
			padding: 0.266rem;

			&:hover {
				background-color: $neutral700;
				border-radius: 4px;
			}
		}
	}

	.inline-ai-container {
		width: 33.333rem;

		.scroll-container {
			overflow-y: auto;
			display: flex;
			max-height: 26.6467rem;
			flex-direction: column;

			&::-webkit-scrollbar-track {
				background-color: $grey05;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $grey07;
			}
		}

		.inline-ai-line {
			width: 100%;
			height: 1px;
			background: #262626;
		}

		.premade-prompt-grid {
			padding-top: 0.8rem;
			padding-bottom: 0.8rem;
			display: flex;
			flex-direction: column;
			gap: 0.5333rem;
			overflow: visible;

			.premade-prompt-row {
				display: flex;
				flex-direction: row;
				gap: 0.5333rem;

				.premade-prompt {
					padding: 0.133rem 0.533rem;
					border-radius: 4px;
					border: 1px solid rgba(33, 36, 51, 0.8);
					background: $neutral700;
					box-shadow: 0px 3.4px 3.4px 0px rgba(0, 0, 0, 0.25);
					display: flex;
					flex-direction: row;
					width: fit-content;
					gap: 0.533rem;
					transition: 0.3s;
					cursor: pointer;

					&:hover {
						background: $neutral600;
					}

					.prompt-icon {
						width: 1.066rem;
						height: 1.066rem;
						color: $primary500;
					}

					.premade-prompt-text {
						font-size: 0.8rem;
						color: white;
						margin: 0px;
						font-weight: 600;
						font-family: "Manrope";
					}
				}
			}
		}

		.inline-ai-text-skeleton {
			display: flex;
			flex-direction: column;
			gap: 0.8rem;
			margin-top: 0.8rem;
			margin-bottom: 0.8rem;
		}

		.ai-result-container {
			margin-top: 0.2667rem;

			.ai-result {
				color: white;
				font-family: Manrope;
				font-size: 1rem;
				font-style: normal;
				font-weight: 400;
				margin-bottom: 0.8rem;
				user-select: text;
			}
		}

		.option-select-heading-container {
			display: flex;
			flex-direction: row;
			gap: 0.533rem;
			align-items: center;
			padding-top: 0.8rem;

			.magic-wand-icon {
				width: 1.066rem;
				height: 1.066rem;
				color: $neutral200;
			}

			.loading-gif {
				width: 1.2rem;
				height: 1.2rem;
				flex-shrink: 0;
			}

			.ai-result-selector {
				display: flex;
				flex-direction: row;
				gap: 0.533rem;
				color: $neutral200;
				font-family: Manrope;
				font-size: 0.933rem;
				font-weight: 500;

				.ai-result-selector-icon-left {
					rotate: 90deg;
					padding: 0.266rem;

					&:hover {
						&:not(.disabled) {
							background-color: $neutral700;
						}
					}
				}

				.ai-result-selector-icon-right {
					rotate: -90deg;
					padding: 0.266rem;

					&:hover {
						&:not(.disabled) {
							background-color: $neutral700;
						}
					}
				}
			}

			.rewrite-with-ai-heading {
				background: $neutral200;
				color: transparent;
				background-clip: text;
				font-family: "Manrope";
				font-size: 1rem;
				font-weight: 600;
				transition: 0.3s;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow-x: hidden;

				&.loading {
					background: linear-gradient(
						0deg,
						#4f46e5 -114.01%,
						#d43f8c 149.87%
					);
					background-clip: text;
					color: transparent;
				}
			}
		}

		.bottom-row {
			padding-top: 1.06rem;
			width: 100%;
			display: flex;
			flex-direction: row;
			gap: 0.533rem;

			.prompt-input-border {
				background: linear-gradient(180deg, #d43f8c 0%, #4038b6 100%);
				padding: 1px;
				border-radius: 6px;
				width: 100%;
				display: inline-block;
				height: 2.667rem;
				opacity: 0.6;
				transition: opacity 0.3s ease;

				&:hover {
					&:not(.focused) {
						opacity: 0.8;
					}
				}

				&.focused {
					opacity: 1;
				}

				.prompt-input {
					width: 100%;
					height: 100%;
					font-size: 0.933rem;
					padding-left: 1.06rem;
					padding-top: 0.53rem;
					padding-bottom: 0.53rem;
					font-family: "Manrope";
					border: none;
					border-radius: 6px;
					background: #070709;
					color: white;
					position: relative;
					z-index: 1;

					&:hover {
						background: black;
					}

					&:focus {
						background: black;
					}
				}
			}

			.inline-ai-submit-button {
				height: 2.667rem;
				background: linear-gradient(180deg, #d43f8c 0%, #4038b6 100%);
				display: flex;
				justify-content: center;
				gap: 0.53rem;
				display: flex;
				flex-direction: row;
				align-items: center;
				border-radius: 6px;
				color: white;
				font-weight: 600;
				font-family: "Manrope";
				font-size: 0.933rem;
				transition: 0.3s;
				opacity: 1;
				padding-right: 1rem;
				padding-left: 1rem;

				.submit-btn-icon {
					height: 1.06rem;
					width: 1.06rem;
					color: white;
				}

				&.disabled {
					opacity: 0.4;
					transition: 0.3s;
				}

				&:hover {
					transition: 0.3s;

					&:not(.disabled) {
						opacity: 0.8;
						//background: linear-gradient(0deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.16) 100%), linear-gradient(180deg, #D43F8C 0%, #4038B6 100%);
					}
				}
			}

			.btn-group-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
				justify-content: space-between;

				.btn-group {
					display: flex;
					flex-direction: row;
					gap: 1rem;

					.copy-ai-btn {
						height: 2.667rem;
						width: 2.667rem;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 6px;
						border: 1px solid $neutral700;
						background: linear-gradient(
							180deg,
							#161820 -21.25%,
							$neutral700 186.25%
						);
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
						transition: 0.3s;
						color: white;

						.copy-icon {
							width: 1.33rem;
							height: 1.33rem;
							display: flex;
						}

						&:hover {
							background: $neutral700;
						}

						&.disabled {
							background: $neutral700;
							cursor: not-allowed;
							border: 1px solid $neutral700;
							background: #161820;
						}
					}

					.replace-btn {
						display: flex;
						flex-direction: row;
						align-items: center;
						padding-left: 1.06rem;
						padding-right: 1.06rem;
						height: 2.667rem;
						border-radius: 6px;
						background: $primary500;
						gap: 0.53rem;
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
						color: white;
						transition: 0.3s;
						font-size: 1rem;
						font-weight: 500;
						transition: 0.3s;
						opacity: 1;

						&.disabled {
							opacity: 0.2;
						}

						.replace-icon {
							width: 1.33rem;
							height: 1.33rem;
							display: flex;
							color: white;
						}

						&:hover {
							background: $primary600;
						}
					}

					.try-again-btn {
						display: flex;
						min-width: 8rem;
						padding: 0.6rem 1.06rem;
						justify-content: center;
						align-items: center;

						border-radius: 6px;
						border: 1px solid $neutral700;
						background: linear-gradient(
							180deg,
							#161820 -21.25%,
							$neutral700 186.25%
						);
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

						color: white;
						text-align: center;
						font-family: Manrope;
						font-size: 1rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						transition: 0.3s;

						&:hover {
							background: $neutral700;
						}

						&.disabled {
							background: $neutral700;
							color: rgba(250, 251, 255, 0.2);
							cursor: not-allowed;
							border: 1px solid $neutral700;
							background: #161820;
						}
					}

					.customize-btn {
						display: flex;
						height: 2.667rem;
						padding: 0.6rem 1.06rem;
						justify-content: center;
						align-items: center;

						border-radius: 6px;
						border: 1px solid $neutral700;
						background: linear-gradient(
							180deg,
							#161820 -21.25%,
							$neutral700 186.25%
						);
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);

						color: white;
						text-align: center;
						font-family: Manrope;
						font-size: 1rem;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						transition: 0.3s;

						&:hover {
							background: $neutral700;
						}

						&.disabled {
							background: $neutral700;
							color: rgba(250, 251, 255, 0.2);
							cursor: not-allowed;
							border: 1px solid $neutral700;
							background: #161820;
						}
					}
				}
			}
		}
	}
}
