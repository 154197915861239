#root {
	background-color: var(--gigauser-background-color);
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.ExtHelpCenter-wrapper {
	height: 100vh;
	padding-bottom: 40px;
	box-sizing: border-box;
	overflow-y: scroll;
}
.ExtHelpCenter-wrapper.gigauser-dark {
	background-color: rgb(15, 17, 23);
}

.AppInitializer-auth {
	text-align: center;
	width: 100vw;
	height: 100vh;
	position: relative;
}
.AppInitializer-auth .PlatformSignIn-container {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	width: -moz-fit-content;
	width: fit-content;
	padding: 40px;
	border-radius: 20px;
	background-color: #f2f2f7;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}
.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
} /*# sourceMappingURL=App.css.map */
