@import "../../../../../../styles/colors";
@import "../../../../../../styles/shadows";

.gigauser-helpcenter-text-link {
	white-space: nowrap;
	padding: 5px;
	color: var(--gigauser-brand-color);
	background-color: $offWhite;
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		background-color: var(--gigauser-light-brand-color);
	}
}
.gigauser-dark .gigauser-helpcenter-text-link {
	white-space: nowrap;
	padding: 5px;
	color: var(--gigauser-darkMode-brand-color);
	background-color: transparentize($darkerGrey, 0.6);
	border-radius: 5px;
	&:hover {
		background-color: transparentize($darkerGrey, 0.1);
	}
}

.gigauser-dark .create-linkpopup-buttons {
	display: flex;
	align-items: center;
	gap: 1em;
}

.gigauser-dark {
	//For popup
	.create-new-collection {
		margin-top: 1.5rem;
	}
}
