.gigauser-guide-viewer-container {
	position: relative;
	display: flex;
	flex-flow: column;
}
.gigauser-guide-viewer-container.gigauser-mobile {
	height: calc(100vh - 43px);
}

.gigauser-viewer-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 24px;
}
.gigauser-viewer-header.gigauser-mobile {
	background-color: var(--gigauser-brand-color);
	padding: 20px;
}
.gigauser-viewer-header.gigauser-laptop {
	margin-top: 20px;
}
.gigauser-guideviewer-text-container.gigauser-text-laptop {
	padding-right: 8em;
}
.gigauser-guideviewer-text-container .gigauser-viewer-branding {
	text-align: left;
	margin: 2rem 0px;
	display: flex;
	align-items: center;
	gap: 0.7em;
	text-decoration: none;
}
.gigauser-guideviewer-text-container .gigauser-viewer-logo {
	height: 1.4em;
}

.gigauser-viewer-body {
	padding: 0px 24px;
}
.gigauser-viewer-body.gigauser-content-only {
	padding: 0px;
}
.gigauser-viewer-body.gigauser-mobile {
	box-sizing: border-box;
	overflow-y: auto;
}

.gigauser-viewer-back {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px 8px;
	transition: 0.3s;
	border-radius: 99px;
	font-weight: 600;
}
.gigauser-viewer-back.gigauser-laptop {
	color: var(--gigauser-brand-color);
	background-color: #f2f2f7;
}
.gigauser-viewer-back.gigauser-laptop:hover {
	background-color: var(--gigauser-brand-color);
	color: white;
}
.gigauser-viewer-back.gigauser-laptop:hover .gigauser-viewer-back-button {
	filter: invert(100%) sepia(92%) saturate(27%) hue-rotate(61deg)
		brightness(105%) contrast(100%);
}
.gigauser-viewer-back .gigauser-viewer-back-button {
	height: 20px;
	width: 20px;
	transform: rotate(90deg);
	transition: 0.3s;
	border-radius: 99px;
}
.gigauser-viewer-back .gigauser-viewer-back-button.gigauser-mobile:hover {
	background-color: rgba(255, 255, 255, 0.173);
}
.gigauser-viewer-back .gigauser-header-title {
	margin-left: 10px;
	color: white;
	font-size: 1.2em;
	max-width: 230px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	line-clamp: 1;
	-webkit-box-orient: vertical;
}
.gigauser-viewer-back .gigauser-back-text {
	margin-left: 10px;
}

.gigauser-viewer-close-helpcenter {
	height: 16px;
	width: 16px;
	margin-right: 8px;
	border-radius: 999px;
	cursor: pointer;
	transition: 0.3s;
	opacity: 80%;
}
.gigauser-viewer-close-helpcenter:hover {
	opacity: 60%;
}

.gigauser-viewer-title {
	text-align: left;
	color: black;
	margin-bottom: 2.3em;
}
.gigauser-viewer-title .Viewer-creator {
	font-weight: 500;
	color: #727272;
	font-size: 0.9rem;
}
.gigauser-viewer-title .Viewer-creator .Viewer-creator-email {
	font-weight: bold;
	transition: 0.3s;
	margin-left: 0.3rem;
	border-radius: 3px;
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
	padding: 3px 5px;
	border-radius: 4px;
	font-weight: 500;
	color: var(--gigauser-brand-color);
	-webkit-user-select: text;
	-moz-user-select: text;
	user-select: text;
}
.gigauser-viewer-title .gigauser-viewer-title-text {
	text-align: left;
	margin: 10px 0px;
}
.gigauser-viewer-title .gigauser-viewer-title-text.gigauser-name {
	margin-top: 30px;
	margin-bottom: 20px;
	font-size: 1.5em;
	font-weight: 600;
}
.gigauser-viewer-title .gigauser-viewer-title-text.gigauser-name.l {
	font-size: 1.6em;
}
.gigauser-viewer-title .gigauser-viewer-title-text.gigauser-description {
	color: var(--gigauser-brand-color);
	font-weight: 500;
	margin-bottom: 1.5rem;
	font-size: 1.3em;
}
.gigauser-viewer-title .gigauser-viewer-title-text.gigauser-description.l {
	line-height: 1.6em;
}
.gigauser-viewer-title
	.gigauser-viewer-title-text.gigauser-description.gigauser-dark.color-flip {
	color: #c6cdcd;
}
.gigauser-viewer-title
	.gigauser-viewer-title-text.gigauser-description.color-flip {
	color: hsl(0, 6%, 21%);
}
.gigauser-viewer-title.gigauser-loading-title .gigauser-viewer-title-text {
	background-color: #f0f3ff;
	color: transparent;
	border-radius: 5px;
	padding: 4px;
}
.gigauser-viewer-title.gigauser-loading-title .gigauser-description {
	width: 70%;
	color: transparent;
}

.gigauser-viewer-tabs {
	margin: 14px 0px;
	margin-top: 20px;
}

.gigauser-guide-container {
	text-align: left;
	padding: 0px;
}
.gigauser-guide-container .Viewer-l-video {
	margin-bottom: 2em;
}

.gigauser-loading-p {
	background-color: #f0f3ff;
	border-radius: 5px;
	color: transparent;
	padding: 5px 0px;
	margin: 5px 0px;
}

.gigauser-loading-img {
	background-color: #f0f3ff;
	border-radius: 5px;
	color: transparent;
	margin: 15px 0px;
	height: 200px;
}

.gigauser-loading-title,
.gigauser-loading-guide {
	overflow-x: hidden;
	overflow-y: hidden;
}
.gigauser-loading-title:after,
.gigauser-loading-guide:after {
	background: linear-gradient(
		90deg,
		transparent,
		rgba(255, 255, 255, 0.2),
		transparent
	);
	content: "";
	display: block;
	position: absolute;
	top: 40px;
	width: 100%;
	height: 100%;
	transform: translateX(-100px);
	animation: gigauserloadingflashes 0.8s infinite;
}

.gigauser-guide-feedback-container.gigauser-text-laptop {
	padding-right: 8em;
}

.gigauser-guide-feedback {
	margin-top: 30px;
	background-color: #f0f3ff;
	border-radius: 10px;
	padding: 20px 40px;
	text-align: center;
	font-weight: 600;
	color: #4b4b4b;
}
.gigauser-guide-feedback .gigauser-feedback-1 {
	display: flex;
	align-items: center;
	gap: 32px;
}
.gigauser-guide-feedback .gigauser-feedback-2 {
	margin-top: 20px;
	text-align: left;
}
.gigauser-guide-feedback .gigauser-feedback-textarea {
	margin-top: 20px;
	width: 100%;
	resize: vertical;
	border-radius: 10px;
	border: 1px solid var(--gigauser-light-brand-color);
	font-size: 0.9em;
	color: #303030;
	font-family: sans-serif;
	padding: 0.7em;
	outline: none;
	transition: 0.3s;
}
.gigauser-guide-feedback .gigauser-feedback-textarea:focus {
	outline: none;
	border: 1px solid var(--gigauser-brand-color);
}
.gigauser-guide-feedback .gigauser-feedback-submit {
	margin-top: 20px;
	padding: 12px;
	background-color: var(--gigauser-brand-color);
	width: -moz-fit-content;
	width: fit-content;
	color: white;
	border-radius: 10px;
	cursor: pointer;
	transition: 0.3s;
}
.gigauser-guide-feedback .gigauser-feedback-submit:hover {
	box-shadow:
		0 4px 6px 0 rgba(0, 0, 0, 0.2),
		0 3px 10px 0 rgba(0, 0, 0, 0.195);
}

.gigauser-emojis-container .gigauser-feedback-emoji {
	font-size: 32px;
	box-sizing: border-box;
	margin: 0px 6px;
	height: 48px;
	width: 48px;
	border-radius: 999px;
	transition: 0.3s;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.gigauser-emojis-container .gigauser-feedback-emoji span {
	margin-top: 2px;
}
.gigauser-emojis-container .gigauser-feedback-emoji.gigauser-active-emoji {
	background-color: var(--gigauser-brand-color);
}
.gigauser-emojis-container .gigauser-feedback-emoji.gigauser-inactive-emoji {
	cursor: default;
	filter: grayscale(1);
}

.gigauser-light .gigauser-regular-emoji:hover {
	background-color: var(--gigauser-light-brand-color);
}

.gigauser-dark .gigauser-guide-feedback {
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
	color: #f0f3ff;
}
.gigauser-dark .gigauser-regular-emoji:hover {
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.5);
}
.gigauser-dark .gigauser-feedback-textarea {
	background-color: transparent;
	color: #f0f3ff;
	border: 1px solid rgba(255, 255, 255, 0.2);
}
.gigauser-dark .gigauser-feedback-textarea::-moz-placeholder {
	color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .gigauser-feedback-textarea::placeholder {
	color: var(--gigauser-darkMode-brand-color);
}

@keyframes gigauserloadingflashes {
	100% {
		transform: translateX(100%);
	}
}
.light .gigauser-viewer-title-text {
	color: black;
}

.gigauser-dark .gigauser-viewer-title-text {
	color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .gigauser-viewer-title-text.gigauser-description {
	color: #d6dce6;
}
.gigauser-dark .gigauser-loading-title:after,
.gigauser-dark .gigauser-loading-guide:after {
	display: none;
	background: linear-gradient(
		90deg,
		transparent,
		rgb(15, 17, 23),
		transparent
	);
}
.gigauser-dark .gigauser-loading-title .gigauser-viewer-title-text {
	background-color: #303030;
}
.gigauser-dark .gigauser-loading-img,
.gigauser-dark .gigauser-loading-p {
	background-color: #303030;
} /*# sourceMappingURL=Viewer.css.map */
