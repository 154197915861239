.gigauser-texteditor-toolbar-blockquote-dropdown {
	position: relative;
	display: flex;
}

.gigauser-texteditor-toolbar-blockquote-dropdown-content {
	display: none;
	position: absolute;
	background-color: #1b1d23;
	width: 54px;
	margin-top: 25px;
	z-index: 1;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.gigauser-texteditor-toolbar-blockquote-dropdown-content-item:hover svg {
	color: white;
	cursor: pointer;
}

.gigauser-texteditor-toolbar-blockquote-dropdown:hover
	.gigauser-texteditor-toolbar-blockquote-dropdown-content {
	display: block;
}

.remirror-editor.ProseMirror blockquote {
	position: relative;
	padding: 15px 30px 15px 30px;
	margin: 10px 0;
	border-radius: 5px;
}
.remirror-editor.ProseMirror blockquote p {
	margin-bottom: 0;
	font-style: normal;
}

.remirror-editor.ProseMirror blockquote.green {
	border-left: 4px solid green;
	background-color: rgb(217, 248, 217);
}
.remirror-editor.ProseMirror blockquote.green p {
	color: darkgreen;
}

.remirror-editor.ProseMirror blockquote.gray {
	border-left: 4px solid #c7c7c7;
	background-color: #f2f2f7;
}
.remirror-editor.ProseMirror blockquote.gray p {
	color: #555;
}

.remirror-editor.ProseMirror blockquote.red {
	border-left: 4px solid rgb(217, 83, 83);
	background-color: rgb(255, 220, 220);
}
.remirror-editor.ProseMirror blockquote.red p {
	color: maroon;
}

.remirror-editor.ProseMirror blockquote.yellow {
	border-left: 4px solid rgb(200, 200, 30);
	background-color: rgb(250, 240, 200);
}
.remirror-editor.ProseMirror blockquote.yellow p {
	color: rgb(131, 96, 8);
}

.remirror-editor.ProseMirror blockquote.blue {
	border-left: 4px solid #0182a9;
	background-color: #ddf7ff;
}
.remirror-editor.ProseMirror blockquote.blue p {
	color: #006a8a;
}

.remirror-editor.ProseMirror blockquote cite {
	display: block;
	text-align: right;
	color: #333;
	font-size: 0.95em;
}

.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.green {
	border-left: 4px solid rgb(69, 159, 69);
	background-color: rgba(0, 100, 0, 0.383);
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.green
	p {
	color: rgb(192, 221, 192);
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.gray {
	border-left: 4px solid #8e8e8e;
	background-color: rgba(85, 85, 85, 0.4274509804);
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.gray
	p {
	color: #f2f2f7;
}
.TextEditorContainer.gigauser-dark .remirror-editor.ProseMirror blockquote.red {
	border-left: 4px solid rgb(155, 57, 57);
	background-color: rgba(172, 5, 5, 0.25);
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.red
	p {
	color: rgb(255, 220, 220);
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.yellow {
	border-left: 4px solid rgb(190, 169, 82);
	background-color: rgba(185, 135, 11, 0.428);
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.yellow
	p {
	color: rgb(250, 240, 200);
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.blue {
	border-left: 4px solid #55b1ce;
	background-color: rgba(1, 132, 171, 0.2745098039);
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote.blue
	p {
	color: #ddf7ff;
}
.TextEditorContainer.gigauser-dark
	.remirror-editor.ProseMirror
	blockquote
	cite {
	display: block;
	text-align: right;
	color: #333;
	font-size: 0.95em;
} /*# sourceMappingURL=BlockquoteButton.css.map */
