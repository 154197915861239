@import "../../../../../../styles/colors";

.dark-app-texteditor-toolbar-dropdown {
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 0.25rem;
	padding: 0.5rem;
	margin: 0.125rem;
	cursor: pointer;

	&:hover {
		background-color: $translucentWhite;
		box-shadow: 0 0.25rem 0.25rem 0 $translucentBlack2;
	}

	.dark-app-texteditor-toolbar-dropdown-content {
		display: none;
		position: absolute;
		background-color: $sectionBackground; /* Dark background color */
		box-shadow: 0 0.5rem 1rem $translucentBlack2;
		z-index: 1000;
		width: fit-content;
		background-color: $grad950;
		border-radius: 0.3rem;
		padding: 0.2rem;

		.dark-app-texteditor-toolbar-dropdown-content-item {
			cursor: pointer;
			text-wrap: nowrap;
			color: $white;
			font-family: "Manrope";
			font-weight: 500;
			border-radius: 0.3rem;
			margin: 0.4rem;
			padding: 0.3rem;

			&:hover {
				background: linear-gradient(
						180deg,
						$lightIndigo 0%,
						$salmonPink 100%
					),
					linear-gradient(
						0deg,
						$translucentBlack2,
						$translucentBlack2
					);
				background-blend-mode: overlay;
			}
		}
	}

	&:hover .dark-app-texteditor-toolbar-dropdown-content {
		display: block;
	}
}
