.HoverTip-container {
	position: relative;
}
.HoverTip-container:hover .hoverTip-label {
	opacity: 1;
	pointer-events: auto;
}

.hoverTip-label {
	opacity: 0;
	pointer-events: none;
	z-index: 100000;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	transition: 0.3s;
	padding: 5px 1rem;
	margin-top: 10px;
	width: -moz-max-content;
	width: max-content;
	max-width: 200px;
	border-radius: 5px;
	box-shadow:
		0 6px 12px 0 rgba(0, 0, 0, 0.2),
		0 8px 30px 0 rgba(0, 0, 0, 0.195);
	font-family: "Manrope", sans-serif;
	background-color: #ffffff;
	color: hsl(225, 21%, 7%);
	display: flex;
	flex-flow: column;
	gap: 0.5rem;
}
.hoverTip-label.l {
	max-width: 300px;
	padding: 1rem 2rem;
}
.hoverTip-label.s {
	max-width: 200px;
	padding: 5px 1rem;
}
.hoverTip-label .hoverTip-title {
	font-size: 0.8rem;
	font-weight: 700;
	width: 100%;
	text-align: center;
}
.hoverTip-label .hoverTip-title.l {
	font-size: 0.9rem;
}
.hoverTip-label .hoverTip-para {
	font-weight: 500;
	font-size: 0.8rem;
	text-align: left;
	margin-bottom: 0.5rem;
	line-height: 1.2rem;
}
.hoverTip-label .hoverTip-para-li {
	font-weight: 500;
	font-size: 0.8rem;
	text-align: left;
	margin-bottom: 0.4rem;
	line-height: 1.2rem;
}

@keyframes opacityappear {
	0% {
		transform: scale(0.1, 0.1);
		padding: 5x;
	}
	100% {
		padding: 5x;
		transform: scale(1, 1);
	}
} /*# sourceMappingURL=HoverTip.css.map */
