.gigauser-texteditor-toolbar-blocktype-dropdown {
	position: relative;
	display: inline-flex;
	box-sizing: content-box;
	font-family: "Manrope";
	margin: 0.125rem;
	border-radius: 0.25rem;
}
.gigauser-texteditor-toolbar-blocktype-dropdown:hover {
	background-color: rgba(255, 255, 255, 0.08);
	box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2);
}
.gigauser-texteditor-toolbar-blocktype-dropdown
	.gigauser-texteditor-toolbar-blocktype-dropdown-heading {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-family: "Manrope";
	font-weight: 500;
	color: #ffffff;
	font-size: 0.875rem;
	padding: 0.5rem;
	line-height: 1.25rem;
}
.gigauser-texteditor-toolbar-blocktype-dropdown
	.gigauser-texteditor-toolbar-blocktype-dropdown-heading.textstyle-disabled {
	opacity: 0.5;
	cursor: auto;
}
.gigauser-texteditor-toolbar-blocktype-dropdown
	.gigauser-texteditor-toolbar-blocktype-dropdown-content-item {
	padding: 6px 16px;
	margin: 0;
	font-family: "Manrope";
	font-weight: 500;
}
.gigauser-texteditor-toolbar-blocktype-dropdown
	.gigauser-texteditor-toolbar-blocktype-dropdown-content-item:hover {
	background-color: #d43f8c;
	color: white;
	cursor: pointer;
}

.toolbar-blocktype-dropdown-heading-code {
	font-family: "Manrope";
	font-weight: 500;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
}

.heading-code-highlight {
	padding: 0 0.5rem;
	background:
		linear-gradient(180deg, #b7b3f4 0%, #f1acd0 100%),
		linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-blend-mode: overlay;
}

.gigauser-texteditor-toolbar-blocktype-dropdown-content {
	display: none;
	width: -moz-max-content;
	width: max-content;
	position: absolute;
	top: 2rem;
	border-radius: 0.3rem;
	z-index: 1000;
	box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
	padding: 0.625rem 0;
	overflow: hidden;
	font-family: "Manrope";
	font-weight: 500;
}

.gigauser-texteditor-toolbar-blocktype-dropdown:hover
	.gigauser-texteditor-toolbar-blocktype-dropdown-content {
	display: block;
}

.gigauser-dark .gigauser-texteditor-toolbar-blocktype-dropdown-heading {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	font-family: "Manrope";
	font-weight: 500;
	color: #ffffff;
	min-width: 1.25rem;
	border-radius: 0.25rem;
}
.gigauser-dark .gigauser-texteditor-toolbar-blocktype-dropdown-content {
	background-color: #13161e;
	color: #ffffff;
	font-family: "Manrope";
	font-weight: 500;
	border-radius: 0.3rem;
}
.gigauser-dark
	.gigauser-texteditor-toolbar-blocktype-dropdown-content
	.gigauser-texteditor-toolbar-blocktype-dropdown-content-item {
	color: #ffffff;
	font-family: "Manrope";
	font-weight: 500;
	border-radius: 0.3rem;
	margin: 0.4rem;
	padding: 0.3rem;
}
.gigauser-dark
	.gigauser-texteditor-toolbar-blocktype-dropdown-content
	.gigauser-texteditor-toolbar-blocktype-dropdown-content-item:hover {
	background:
		linear-gradient(180deg, #b7b3f4 0%, #f1acd0 100%),
		linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	background-blend-mode: overlay;
}
.gigauser-dark
	.gigauser-texteditor-toolbar-blocktype-dropdown-content
	.dropdown-content-item-h1 {
	font-size: 1.4rem;
	padding: 0.5rem;
}
.gigauser-dark
	.gigauser-texteditor-toolbar-blocktype-dropdown-content
	.dropdown-content-item-h2 {
	font-size: 1.3rem;
	padding: 0.5rem;
}
.gigauser-dark
	.gigauser-texteditor-toolbar-blocktype-dropdown-content
	.dropdown-content-item-h3 {
	font-size: 1.2rem;
	padding: 0.5rem;
}
.gigauser-dark
	.gigauser-texteditor-toolbar-blocktype-dropdown-content
	.dropdown-content-item-h4 {
	font-size: 1.1rem;
	padding: 0.5rem;
}
.gigauser-dark
	.gigauser-texteditor-toolbar-blocktype-dropdown-content
	.dropdown-content-item-h5 {
	font-size: 1rem;
	padding: 0.5rem;
} /*# sourceMappingURL=BlockTypeDropdown.css.map */
