.gigauser-password-prompt-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #f0f0f0;
	flex-flow: column;
}

.gigauser-password-prompt-h1 {
	font-size: 1.3rem;
	margin-bottom: 3rem;
}

.gigauser-password-prompt-form {
	display: flex;
	align-items: center;
	// flex-direction: column;
	// min-width: 500px;
	gap: 10px;
}

.gigauser-password-prompt-input {
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

.gigauser-password-prompt-button {
	padding: 10px;
	// width: 50%;
	border: none;
	border-radius: 4px;
	background-color: #4caf50;
	color: white;
	cursor: pointer;
	transition: 0.3s;
}

.gigauser-password-prompt-button:hover {
	background-color: #45a049;
}

.gigauser-password-prompt-error {
	color: red;
	margin-top: 2rem;
}
