.Breadcrumbs {
	display: flex;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.gigauser-Breadcrumbs {
	display: flex;
	align-items: center;
	font-size: 0.8em;
}

.Breadcrumbs-arrow {
	margin: 0px 5px;
}

.gigauser-PageTitle-pathSegment {
	display: inline-flex;
	align-items: center;
	border-radius: 999px;
	transition: 0.3s;
	padding: 4px 8px;
	color: #b1b5c9;
	font-weight: 600;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.gigauser-PageTitle-pathSegment .gigauser-breadcrumbs-statuslight {
	height: 0.5rem;
	width: 0.5rem;
	color: transparent;
	border-radius: 9999px;
	margin-right: 0.5rem;
}
.gigauser-PageTitle-pathSegment .gigauser-breadcrumbs-statuslight.public {
	background-color: #59ffa0;
}
.gigauser-PageTitle-pathSegment .gigauser-breadcrumbs-statuslight.private {
	background-color: #d89f03;
}
.gigauser-PageTitle-pathSegment.gigauser-home-segment {
	margin-left: -8px;
}
.gigauser-PageTitle-pathSegment.navigable:hover {
	cursor: pointer;
	color: var(--gigauser-brand-color);
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
}
.gigauser-PageTitle-pathSegment.navigable.gigauser-activeSegment {
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
	color: var(--gigauser-brand-color);
	font-weight: bold;
}
.gigauser-PageTitle-pathSegment.platform-segment:hover {
	color: #da589b;
}
.gigauser-PageTitle-pathSegment.platform-segment.gigauser-activeSegment {
	color: #da589b;
	background-color: rgba(240, 188, 215, 0.1);
	font-weight: bold;
}

.gigauser-breadcrumbs-home {
	width: 20px;
	height: 20px;
	display: inline-block;
}

.gigauser-Breadcrumb-arrow {
	transform: rotate(270deg);
	width: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0px 5px;
	transition: 0.3s;
}

.droppable-pathSegment {
	cursor: pointer;
}
.droppable-pathSegment:hover {
	color: #665aef;
}
.droppable-pathSegment.onDrop {
	color: #303030;
	background-color: #fbecf3;
	padding: 2px 12px;
	border-radius: 3px;
}

.segment-status {
	color: transparent;
	height: 6px;
	width: 6px;
	border-radius: 99px;
	display: inline-flex;
	margin-left: 6px;
}
.segment-status.segment-private {
	background-color: #c7c7c7;
}
.segment-status.segment-public {
	background-color: #3a9434;
}

.gigauser-dark .gigauser-PageTitle-pathSegment {
	color: #d6dce6;
}
.gigauser-dark .gigauser-PageTitle-pathSegment.navigable:hover {
	background-color: #4b4b4b;
	color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark
	.gigauser-PageTitle-pathSegment.navigable.gigauser-activeSegment {
	background-color: #303030;
	color: var(--gigauser-darkMode-brand-color);
} /*# sourceMappingURL=Breadcrumbs.css.map */
