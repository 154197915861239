@import "../../../../../../styles/colors";

.gigauser-texteditor-toolbar-blocktype-dropdown {
	position: relative;
	display: inline-flex;
	box-sizing: content-box;
	font-family: "Manrope";
	margin: 0.125rem;
	border-radius: 0.25rem;

	&:hover {
		background-color: $translucentWhite;
		box-shadow: 0 0.25rem 0.25rem 0 $translucentBlack2;
	}

	.gigauser-texteditor-toolbar-blocktype-dropdown-heading {
		user-select: none;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-family: "Manrope";
		font-weight: 500;
		color: $white;
		font-size: 0.875rem;
		padding: 0.5rem;
		line-height: 1.25rem;

		&.textstyle-disabled {
			opacity: 0.5;
			cursor: auto;
		}
	}

	.gigauser-texteditor-toolbar-blocktype-dropdown-content-item {
		padding: 6px 16px;
		margin: 0;
		font-family: "Manrope";
		font-weight: 500;

		&:hover {
			background-color: $pink;
			color: white;
			cursor: pointer;
		}
	}
}

.toolbar-blocktype-dropdown-heading-code {
	font-family: "Manrope";
	font-weight: 500;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 0.25rem;
}

.heading-code-highlight {
	padding: 0 0.5rem;
	background: linear-gradient(180deg, $lightIndigo 0%, $salmonPink 100%),
		linear-gradient(0deg, $translucentBlack2, $translucentBlack2);
	background-blend-mode: overlay;
}

.gigauser-texteditor-toolbar-blocktype-dropdown-content {
	display: none;
	width: max-content;
	position: absolute;
	top: 2rem;
	border-radius: 0.3rem;
	z-index: 1000;
	box-shadow: 0 0.5rem 1rem 0 $translucentBlack2;
	padding: 0.625rem 0;
	overflow: hidden;
	font-family: "Manrope";
	font-weight: 500;
}

.gigauser-texteditor-toolbar-blocktype-dropdown:hover
	.gigauser-texteditor-toolbar-blocktype-dropdown-content {
	display: block;
}

.gigauser-dark {
	.gigauser-texteditor-toolbar-blocktype-dropdown-heading {
		user-select: none;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		font-family: "Manrope";
		font-weight: 500;
		color: $white;
		min-width: 1.25rem;
		border-radius: 0.25rem;
	}

	.gigauser-texteditor-toolbar-blocktype-dropdown-content {
		background-color: $grad950;
		color: $white;
		font-family: "Manrope";
		font-weight: 500;
		border-radius: 0.3rem;

		.gigauser-texteditor-toolbar-blocktype-dropdown-content-item {
			color: $white;
			font-family: "Manrope";
			font-weight: 500;
			border-radius: 0.3rem;
			margin: 0.4rem;
			padding: 0.3rem;

			&:hover {
				background: linear-gradient(
						180deg,
						$lightIndigo 0%,
						$salmonPink 100%
					),
					linear-gradient(
						0deg,
						$translucentBlack2,
						$translucentBlack2
					);
				background-blend-mode: overlay;
			}
		}

		.dropdown-content-item-h1 {
			font-size: 1.4rem;
			padding: 0.5rem;
		}
		.dropdown-content-item-h2 {
			font-size: 1.3rem;
			padding: 0.5rem;
		}
		.dropdown-content-item-h3 {
			font-size: 1.2rem;
			padding: 0.5rem;
		}
		.dropdown-content-item-h4 {
			font-size: 1.1rem;
			padding: 0.5rem;
		}
		.dropdown-content-item-h5 {
			font-size: 1rem;
			padding: 0.5rem;
		}
	}
}
