.TitledInput {
	position: relative;
}
.TitledInput .error-message {
	margin-top: 5px;
	font-size: 0.66em;
	color: #db5461;
	text-align: left;
}
.TitledInput.titled-dropdown {
	display: inline-flex;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.TitledInput .Clickout {
	width: 100%;
}

.titledinput-box {
	position: relative;
	border: 1px solid hsl(228, 19%, 11%);
	border-radius: 5px;
	width: 100%;
	transition: 0.3s;
	text-align: left;
}
.titledinput-box.active {
	border: 1px solid #d43f8c;
	box-shadow: 0 0 2px #d43f8c;
}
.titledinput-box.sharp {
	border-radius: 3px;
}
.titledinput-box.titled-dropdown {
	display: inline-flex;
	justify-content: space-between;
}

.input-icon {
	height: 1em;
	width: 1em;
	vertical-align: middle;
	margin: 0 5px;
	margin-left: 15px;
	opacity: 0.5;
}

.input-label {
	position: absolute;
	margin: 10px;
	padding: 0 0.5rem;
	background-color: white;
	font-weight: 600;
	color: #303030;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	top: -1.5rem;
	font-size: 1rem;
}

.titledInput {
	font-size: 1rem;
	padding: 1rem 0;
	padding-left: 10px;
	font-family: "Poppins";
	width: 90%;
	border-radius: 10px;
	background: none;
}
.titledInput.s {
	padding: 0.5rem 0.5rem;
	font-size: 0.9rem;
}
.titledInput.xs {
	font-size: 0.8rem;
	padding: 0.5rem;
}
.titledInput.s {
	padding: 0.5rem 0.5rem;
	font-size: 0.9rem;
}
.titledInput.l {
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-size: 1rem;
}
.titledInput.xl {
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 1rem;
}
.titledInput.no-icons {
	width: 90%;
}
.titledInput.one-icon {
	width: calc(90% - 1em - 10px);
}
.titledInput.two-icons {
	width: calc(90% - 2em - 20px);
}
.titledInput:focus {
	outline: none;
}
.titledInput.dropdown-input {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.dropdown-icon {
	align-self: center;
	margin-right: 1rem;
}

.titledinput-options-container {
	box-shadow:
		0 4px 6px 0 rgba(0, 0, 0, 0.2),
		0 3px 10px 0 rgba(0, 0, 0, 0.195);
	position: absolute;
	margin-top: 0.25rem;
	width: -moz-fit-content;
	width: fit-content;
	max-height: 150px;
	border-radius: 0.5rem;
	background-color: white;
	z-index: 100;
	text-align: left;
	overflow: hidden;
}
.titledinput-options-container .options-scroll {
	padding: 0.5rem;
	max-height: 150px;
	overflow-y: auto;
}

.titledinput-option {
	padding: 5px 10px;
	font-weight: 500;
	border-radius: 5px;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	transition: 0.3s;
}
.titledinput-option.s {
	padding: 4px 4px;
	font-size: 0.85rem;
}
.titledinput-option:hover {
	background-color: #f2f2f7;
}
.titledinput-option .icon {
	margin-right: 10px;
	height: 1rem;
	width: 1rem;
}
.titledinput-option .text {
	margin-right: 20px;
}

.error-input {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000px;
}
.error-input .input-label {
	color: #db5461;
}
.error-input .input-main-container {
	border: 1px solid #db5461;
}
.error-input .input-main-container.active {
	box-shadow: 0 0 2px #db5461;
}
.error-input .input-icon {
	filter: invert(42%) sepia(30%) saturate(4883%) hue-rotate(328deg)
		brightness(97%) contrast(76%);
}
.error-input input {
	color: #db5461;
}
.error-input input::-moz-placeholder {
	color: #db5461;
}
.error-input input::placeholder {
	color: #db5461;
}

.titledInput:-webkit-autofill,
.titledInput:-webkit-autofill:hover,
.titledInput:-webkit-autofill:focus,
.titledInput:-webkit-autofill:active {
	background-color: transparent;
	border: 3px solid green;
	-webkit-box-shadow: 0 0 0 30px #f2f2f7 inset !important;
}

.gigauser-dark .titledinput-box {
	background-color: #0e1015;
	display: flex;
	align-items: center;
}
.gigauser-dark .titledinput-box.not-disabled {
	border: 1px solid #333545;
	background-color: hsl(228, 19%, 11%);
}
.gigauser-dark .titledinput-box.not-disabled:hover {
	border: 1px solid #888b9c;
	box-shadow: 0px 0px 0px 4px rgba(106, 108, 123, 0.1);
}
.gigauser-dark .titledinput-box.not-disabled.active {
	border: 1px solid #e179af;
	box-shadow: 0px 0px 0px 4px rgba(233, 155, 195, 0.08);
}
.gigauser-dark .titledinput-box.not-disabled .titledInput {
	color: white;
}
.gigauser-dark .titledinput-box.not-disabled .titledInput::-moz-placeholder {
	color: #727272;
}
.gigauser-dark .titledinput-box.not-disabled .titledInput::placeholder {
	color: #727272;
}
.gigauser-dark .titledinput-box.disabled {
	border: 1px solid #212433;
}
.gigauser-dark .titledinput-box.disabled .titledInput {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	color: #6a6c7b;
}
.gigauser-dark .titledinput-box .dropdown-icon {
	color: white;
}
.gigauser-dark .titledinput-options-container {
	background-color: hsl(228, 19%, 11%);
}
.gigauser-dark .titledinput-option {
	color: #fafbff;
}
.gigauser-dark .titledinput-option:hover {
	background-color: #212433;
}
.gigauser-dark .input-label {
	color: #fafbff;
	background: linear-gradient(to bottom, #0e1015 50%, hsl(228, 19%, 11%) 50%);
} /*# sourceMappingURL=TitledInput.css.map */
