@import "../../../../../styles/colors";
@import "../../../../../styles/shadows";
@import "../../../../../../../platform/src/styles/layout";

$darkGold: #d89f03;
$lightGold: #f1e2b8;

.gigauser-RichTextToolbar {
	display: flex;
	align-items: center;
	// justify-content: center;

	&.gigauser-fix-toolbar {
	}

	&.gigauser-dark {
		margin-top: 1rem;
		background-color: $grey02;
		border: none;
		border-radius: 0;
	}

	&.fullWidthToolbar {
		padding: 0.5rem 1rem;
		border-radius: 0.7rem;
		background: $translucentBlack;
		box-shadow: 0 0 0.625rem 0 $translucentWhite;
	}

	&.transcriptToolbar {
		// top: 0;
		// position: absolute;
		// padding-top: 0.5333rem;
		// padding-bottom: 0.5333rem;
		// width: calc(100% - 0.5333rem);
		// padding: 0.6667rem 0.4rem;
		// border: none;
		border-radius: 0;
		// background-color: $grey01;
		// display: block;
	}

	.Transcript-toolbar-container {
		width: 100%;
		background-color: $grey05;
		border-radius: 0.3333rem;
		border: 0.0667rem solid $grey06;
		padding: 0.2rem;
	}

	// box-shadow: 0 0.1333rem 0.2667rem rgba(0, 0, 0, 0.1);

	&.gigauser-isTop {
		position: fixed;
		top: 4rem;
		z-index: 999;
		animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	}

	// box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

	&.gigauser-isTop {
		// position: fixed;
		// top: 60px;
		z-index: 999;
		animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
	}

	/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
	&.gigauser-isTop + .remirror-editor-wrapper {
		// padding-top: 40px;
	}
}

.gigauser-special-button {
	display: flex;
	align-items: center;
	//padding: 0.3333rem;
	// gap: 0.6667rem;
	border-radius: 0.3333rem;
	width: fit-content;
	cursor: pointer;
	border: 0.0667rem solid transparent;
	transition: border 0.3s;
	font-weight: bold;

	&.gigauser-enhance-button {
		color: $darkGold;
		background-color: #fffbec;

		&:hover {
			border: 0.0667rem solid $darkGold;
		}
	}

	&.gigauser-generate-button {
		color: #b1acff;
		background-color: #4e46e577;
		user-select: none;

		&.generate-disabled {
			opacity: 0.5;
			cursor: auto;
		}

		&.generate-enabled {
			&:hover {
				border: 0.0667rem solid #4f46e5;

				.gigauser-special-button-icon {
					animation: gigauserRotate 2s infinite linear;
				}
			}
		}
	}

	.gigauser-special-button-icon {
		height: 1.3333rem;
		width: 1.3333rem;
		margin-left: 0.3333rem;
	}

	.gigauser-special-button-text {
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-left: 0.6667rem;

		&.gigauser-special-button-generate {
			-webkit-line-clamp: 1;
			line-clamp: 1;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.gigauser-toolbar-special-buttons {
	display: flex;
	align-items: center;

	.down-arrow {
		transition: 0.3s;
		height: 2.6667rem;
		padding-right: 0.2rem;
		padding-left: 0.2rem;
		display: flex;

		border-left: 1px solid transparentize($lightGold, 0.5);
		transition: 0.3s;
		padding-left: 0.5rem;

		&:hover {
			color: white;
		}
	}
}

.toolbar-special-buttons-divider {
	border-left: 1px solid $translucentWhite;
	height: 2rem;
	margin: 0 0.5rem;
}

.gigauser-toolbar-regular-buttons {
	display: flex;
	align-items: center;

	.gigauser-RichTextToolbar-reg-icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0.125rem;
		padding: 0.5rem;
		border-radius: 0.25rem;
		position: relative;
		cursor: pointer;

		.reg-icon-container-hovertip {
			display: none;
		}

		.gigauser-RichTextToolbar-reg-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 1.25rem;
			height: 1.25rem;
		}

		&:hover {
			background-color: $translucentWhite;
			box-shadow: 0 0.25rem 0.25rem 0 $translucentBlack2;

			.reg-icon-container-hovertip {
				display: block;
			}
		}
	}
}

.gigauser-dark {
	.gigauser-RichTextToolbar-reg-icon-container {
		&:hover {
			background-color: $translucentWhite;
			box-shadow: 0 0.25rem 0.25rem 0 $translucentBlack2;
		}
	}
}

.gigauser-enhanceLoadingRing {
	// display: flex;
	// justify-content: center;
	// align-items: center;
}

.boxIcon-container {
	display: flex;
	align-items: center;
	font-size: 1em;
	color: $darkModePink;
	padding: 0.2rem 0.5333rem;
	border-radius: 0.2667rem;
	gap: 0.6667rem;
	cursor: pointer;

	.gigauser-voice-name {
		max-width: 5.3333rem;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.gigauser-RichTextToolbar-voice-icon {
		width: 0.9333rem;
		height: 0.9333rem;
	}

	transition: background-color 0.3s;

	&:hover {
		background-color: transparentize($semiDarkPink, 0.3);
	}

	&.wandIcon {
		color: #f1e2b8;
		border: 0.0667rem solid rgba(255, 217, 0, 0.326);
		background-color: transparentize($lightGold, 0.9);

		&:hover {
			background-color: transparentize($lightGold, 0.7);
		}
	}
}

.gigauser-dark {
	.gigauser-enhance-button {
		color: $lightGold;
		background-color: #7d6f3f;

		&:hover {
			border: 0.0667rem solid $lightGold;
		}
	}
}

.transcriptToolbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-family: Manrope, sans-serif;

	.ToolbarRow1 {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.5rem; /* 171.429% */
		padding-left: 1.2rem;
		padding-right: 1.2rem;

		.ToolbarRow1-left {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			gap: 0.35rem;
			margin-right: 0.35rem;
		}

		.d-none {
			display: none;

			@media (min-width: 1440px) {
				display: inline;
			}
		}

		.genspeech-container {
			width: 56%;
		}

		.aiRewrite-container {
			width: 44%;
		}

		.addSyncMarker-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 9999px;
			background-color: $translucentGray;
			border: 1px solid $translucentGray;
			width: 2.5rem;
			height: 2.5rem;

			&:hover {
				border: none;
				background-color: $translucentWhite;
				box-shadow: 0 0.25rem 0.25rem 0 $translucentBlack2;
			}
		}

		.AI-rewrite-button {
			display: flex;
			align-items: center;
			justify-content: space-between;

			// border-bottom: 0.0667rem solid $grey06;
			// padding: 0.3rem 0.6rem;
			padding: 0.5rem 0.75rem;
			gap: 0.5rem;
			height: 100%;
			border-radius: 2rem;
			// border: 0.0667rem solid $grey06;
			color: $neutral100;
			background-color: $translucentGray;
			transition: 0.3s;
			cursor: pointer;

			.ai-rewrite-button-inner {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				gap: 0.5rem;
			}

			.transbuttonloading {
				margin-right: 0.5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 1rem;
				width: 1rem;
			}

			&.disabled {
				opacity: 0.5;
				cursor: pointer;
				pointer-events: none;
			}

			&.enabled {
				&:hover {
					// background-color: $grey02;
					// border-color: $grey08;
					// .trans-down-icon {
					// 	transform: translateY(0.2rem);
					// }
					background: $translucentWhite;
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
				}
			}

			.ai-rewrite-icon {
				height: 1.5rem;
				width: 1.5rem;
			}

			.video-ai-line-separator {
				border-left: 0.0667rem solid $neutral400;
				height: 1.2rem;
				margin-left: 0.5rem;
			}

			.down-icon-container {
				color: $neutral200;
				display: flex;
				align-items: center;

				padding: 0.3rem;

				background-color: $translucentWhite;
				border-radius: 9999px;

				&:hover {
					background-color: $grey02;
					box-shadow: 0 0.25rem 0.25rem 0 $translucentGray;
					transition: all ease 0.3s;

					.down-icon {
						transform: translateY(0.05rem);
						transition: transform 0.3s ease;
					}
				}

				.down-icon {
					height: 0.8rem;
					width: 0.8rem;
					transition: transform 0.3s ease;
				}
			}
			.d-none {
				display: none;

				@media (min-width: 1440px) {
					display: inline;
				}
			}
		}
		.d-small-none {
			display: none;

			@media (min-width: 1080px) {
				display: inline;
			}
		}
	}
}

.ToolbarRow2 {
	position: absolute;
	bottom: 0.6667rem;
	border-radius: 0.3333rem;
	padding: 0.3rem;

	display: flex;
	align-items: center;
	margin-top: 0.3333rem;
	margin-bottom: 0.3333rem;
	z-index: 5;
	box-shadow: $lighterBoxShadow;

	// justify-content: center;
}

.TranscriptToolbarButton {
	display: flex;
	padding: 0.5rem;
	// height: 100%;
	// gap: 1rem;
	border-radius: 999px;
	background-color: $grey05;
	align-items: center;
	justify-content: center;
	user-select: none;
	cursor: pointer;

	&.disabled {
		opacity: 0.5;
		cursor: pointer;
		pointer-events: none;
	}

	&.minor {
		width: 2.2rem;
		height: 2.2rem;
		background-color: $translucentGray;
		border: 1px solid $translucentWhite;
		margin: 0.2rem;
		backdrop-filter: blur(0.2rem);
		-webkit-backdrop-filter: blur(0.2rem);

		&:hover {
			background-color: $semiDarkPink;
		}
	}

	&.voiceButton {
		.TranscriptToolbarButtonText {
			max-width: 3.5rem;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.transbuttonloading {
		// margin-right: 0.5rem;
		// display: flex;
		// align-items: center;
		// justify-content: center;
	}

	.transcriptToolbarIcon {
		height: 0.9rem;
		width: 0.9rem;

		&.yestext {
			margin-right: 0.5rem;
		}
	}

	.TranscriptToolbarButtonText {
		font: 500;
		color: white;
	}

	.trans-down-icon {
		height: 1rem;
		width: 1rem;
		transition: 0.3s;
		margin-left: 0.3rem;
	}

	transition: 0.3s;

	&.enabled {
		&:hover {
			background-color: $grey02;
			border-color: $grey08;
			.trans-down-icon {
				transform: translateY(0.2rem);
			}
		}
	}
}

.GenerateCircle {
	position: absolute;
	background-color: #3549cc;
	transition: 0.3s;
	border: 0.1333rem solid transparent;
	border-radius: 66.6rem;
	padding: 1rem;
	box-shadow: $boxShadow;
	cursor: pointer;
	bottom: 1.3333rem;
	right: 4rem;
	z-index: 999;

	&:hover {
		background-color: #213196;
		border-color: #3549cc;
	}

	.GenerateIcon {
		height: 2rem;
		width: 2rem;
	}
}

.GenerateSpeechButton {
	user-select: none;
	cursor: pointer;
	padding: 0.5rem;

	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 6.25rem;
	border: 1px solid $primary400;
	color: $primary400;
	background: linear-gradient(
		180deg,
		rgba(225, 121, 175, 0.08) 0%,
		rgba(218, 88, 155, 0.08) 50%,
		rgba(225, 121, 175, 0.08) 100%
	);

	gap: 0.6rem;
	transition: 0.3s;
	z-index: 9;

	&.disabled {
		opacity: 0.5;
		cursor: pointer;
	}

	&.enabled {
		&:hover {
			background: $primary900;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
		}
	}

	.generateSpeechIcon {
		height: 1.5rem;
		width: 1.5rem;
	}
}

@keyframes gigauserRotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
