.gigauser-video-container {
	position: relative;
	margin: 0 auto;
	margin-bottom: 2.3em;
	width: -moz-fit-content;
	width: fit-content;
}
.gigauser-video-container.gigauser-embed-video {
	margin-bottom: 0;
}
.gigauser-video-container.gigauser-nonembed-video video {
	max-height: 500px;
}
.gigauser-video-container media-player {
	border: none;
	background-color: transparent;
}
.gigauser-video-container media-player div[part="scrim"] {
	height: 99%;
}
.gigauser-video-container .gigauser-laptop-mediaplayer {
	display: flex;
}
.gigauser-video-container .gigauser-laptop-mediaplayer .thumbnail-play {
	position: absolute;
	display: none;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer
	.thumbnail-play
	media-play-button {
	background-color: var(--gigauser-brand-color);
	opacity: 0.8;
	padding: 1.2em;
	border-radius: 999px;
	transition: 0.3s;
	border: 4px solid rgba(255, 255, 255, 0.279);
	scale: 1.4;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer
	.thumbnail-play
	media-play-button
	svg {
	height: 5em;
	width: 5em;
}
.gigauser-video-container .gigauser-laptop-mediaplayer .flash-play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	height: 4.5em;
	width: 4.5em;
	opacity: 0.8;
	background-color: var(--gigauser-brand-color);
	justify-content: center;
	align-items: center;
	border-radius: 999px;
	opacity: 0;
	transition: 0.2s;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer
	.flash-play
	.flash-icon-container {
	height: 2em;
	width: 2em;
	display: flex;
	align-items: center;
}
.gigauser-video-container .gigauser-laptop-mediaplayer .flash-play.flash-show {
	opacity: 0.6;
	scale: 1;
	display: flex;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer.thumbnail-preview
	.thumbnail-play {
	display: flex;
	flex-flow: column;
	align-items: center;
	gap: 1em;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer.thumbnail-preview
	div[part="scrim"],
.gigauser-video-container
	.gigauser-laptop-mediaplayer.thumbnail-preview
	div[part="controls-group"] {
	opacity: 0;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer.thumbnail-preview
	media-play-button {
	box-sizing: content-box;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer:hover
	.thumbnail-play
	media-play-button {
	opacity: 1;
	padding: 0.8em;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer:hover
	.thumbnail-play
	.thumbnail-play-bg {
	opacity: 0.9;
	padding: 48px;
	border: 10px solid rgba(255, 255, 255, 0.279);
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer:hover
	.thumbnail-play
	.thumbnail-play-icon {
	opacity: 0.9;
	height: 36px;
	width: 36px;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer:not([data-hover="true"])
	div[part="scrim"],
.gigauser-video-container
	.gigauser-laptop-mediaplayer:not([data-hover="true"])
	div[part="controls-group"] {
	opacity: 0;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer:not([data-hover="true"])
	media-captions {
	bottom: 0;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer:not([data-hover="true"])
	.thumbnail-play.video-paused {
	display: flex;
}
.gigauser-video-container
	.gigauser-laptop-mediaplayer[data-fullscreen]
	:where(media-outlet video) {
	margin-top: 50vh;
	transform: translateY(-50%);
	width: 100vw;
	max-height: 100vh;
}
.gigauser-video-container media-outlet {
	background-color: transparent;
	box-shadow: none;
}

.gigauser-play-button {
	position: absolute;
	z-index: 999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80px;
	height: 80px;
	background-color: var(--gigauser-brand-color);
	opacity: 0.6;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	text-align: center;
	transition: 0.3s;
}
.gigauser-play-button.gigauser-isPlaying {
	display: none;
}

.gigauser-play-button::before {
	content: "";
	margin-left: 4px;
	border-style: solid;
	border-width: 12px 0 12px 24px;
	border-color: transparent transparent transparent white;
}

.gigauser-editor-video {
	width: 100%;
	box-shadow:
		0 4px 6px 0 rgba(0, 0, 0, 0.2),
		0 3px 10px 0 rgba(0, 0, 0, 0.195);
	border: none;
	margin-top: 20px;
}

.gigauser-video-container.gigauser-noplay-video {
	pointer-events: none;
}
.gigauser-video-container:hover {
	cursor: pointer;
}
.gigauser-video-container:hover .gigauser-play-button {
	opacity: 1;
}

.gigauser-mobile-mediaplayer .thumbnail-play {
	display: none;
}
.gigauser-mobile-mediaplayer .flash-play {
	display: none;
}

.thumbnail-clueso-preview {
	background-color: rgba(0, 0, 0, 0.662745098);
	color: white;
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 0.8em;
	padding: 0.6rem 0.4rem;
	border-radius: 10px;
	box-sizing: border-box;
	font-size: 0.7rem;
	justify-content: center;
	width: -moz-fit-content;
	width: fit-content;
	text-decoration: none;
}
.thumbnail-clueso-preview .thumbnail-clueso-text {
	width: 3rem;
}
.thumbnail-clueso-preview .thumbnail-clueso-logo {
	height: 1.6em;
	width: 5.344em;
}

.thumbnail-play {
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	text-align: center;
	transition: 0.3s;
	opacity: 1;
	gap: 1em;
	transition: opacity 0.3s;
}
.thumbnail-play.gigauser-hide-thumbnail-play {
	opacity: 0;
}
.thumbnail-play .thumbnail-play-icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
}
.thumbnail-play .thumbnail-play-icon {
	position: absolute;
	z-index: 3;
	height: 32px;
	width: 32px;
	opacity: 0.8;
	transition: 0.3s;
	transform: translateX(14%);
}
.thumbnail-play .thumbnail-play-bg {
	background-color: var(--gigauser-brand-color);
	padding: 56px;
	border-radius: 99px;
	border-color: rgba(255, 255, 255, 0.279);
	border: 12px solid rgba(255, 255, 255, 0.279);
	opacity: 0.8;
	transition: 0.3s;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	box-sizing: border-box;
} /*# sourceMappingURL=Video.css.map */
