.ShapeCustomizer {
	display: flex;
	align-items: center;
	gap: 0.6em;
}
.ShapeCustomizer .customizer-popup-icon-container {
	position: relative;
}
.ShapeCustomizer .customizer-icon {
	height: 14px;
	width: 14px;
	box-sizing: content-box;
	padding: 0px 4px;
	padding-bottom: 6px;
	border-bottom: 4px solid;
}
.ShapeCustomizer .customizer-icon.borderless {
	border-bottom: none;
}
.ShapeCustomizer .customizer-popup {
	position: absolute;
	right: 0px;
	margin-top: 10px;
	padding: 10px;
	box-shadow:
		0 4px 6px 0 rgba(0, 0, 0, 0.2),
		0 3px 10px 0 rgba(0, 0, 0, 0.195);
	background-color: #1b1d23;
	border-radius: 5px;
	z-index: 99;
}
.ShapeCustomizer .StrokeWidthPicker {
	width: 48px;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.ShapeCustomizer .StrokeWidthPicker .StrokeWidthOption {
	margin: 3px 0px;
	transition: 0.3s;
	padding: 3px 6px;
	border-radius: 5px;
}
.ShapeCustomizer .StrokeWidthPicker .StrokeWidthOption:hover.inactive-sw {
	cursor: pointer;
	background-color: #3d4361;
}
.ShapeCustomizer .StrokeWidthPicker .StrokeWidthOption.active-sw {
	background-color: rgba(176, 42, 111, 0.445);
} /*# sourceMappingURL=ShapeCustomizer.css.map */
