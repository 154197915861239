@import "../../../styles/colors";
@import "../../../styles/shadows";

$helpCenterWidth: 600px;

$darkBackground: #0e1117;

.HelpCenterSearchBlur {
	position: fixed;
	color: transparent;
	left: 0;
	top: 0;
	z-index: 999;
	width: 100vw;
	height: 100vh;
	backdrop-filter: blur(4px);
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.05);
}

.HelpCenterSearchBox {
	z-index: 1000;
	position: absolute;
	top: 6em;
	left: 50%;
	transform: translateX(-50%);
	max-width: $helpCenterWidth;
	width: 80%;
	background-color: white;
	border-radius: 10px;
	box-shadow:
		2px 8px 8px 2px rgb(var(--gigauser-brand-color-rgb), 0.2),
		0 3px 10px 0 rgb(var(--gigauser-brand-color-rgb), 0.2);

	.searchinput-container {
		display: flex;
		align-items: center;
		height: 100%;
		// border: 1px solid var(--gigauser-light-brand-color);
		border-radius: 5px;
		padding: 0em 1em;

		.searchIcon {
			width: 1.4em;
			height: 1.4em;
		}

		.search-input {
			height: 100%;
			width: 100%;
			padding: 1.2em 0.7em;
			font-size: 1.1em;
			color: var(--gigauser-brand-color);

			border: none;
			background-color: white;

			&::placeholder {
				color: rgb(var(--gigauser-brand-color-rgb), 0.7);
				transition: 0.3s;
			}

			&:focus {
				outline: none;
			}
		}
	}
}

.ext-searchResult {
	display: flex;
	align-items: center;
	padding: 0.8em 1em;
	cursor: pointer;
	user-select: none;
	margin: 0.4em;
	border-radius: 5px;

	.ext-searchResult-icon {
		height: 20px;
		width: 20px;
		margin-right: 1em;
		padding: 4px;
		border: 1px solid $clipboardGrey;
		border-radius: 5px;
		background-color: white;
		box-sizing: content-box;
	}

	.ext-searchResult-text {
		text-align: left;
		width: 90%;
		transition: 0s;

		.ext-searchResult-title {
			font-size: 0.9em;
			margin-bottom: 0.2em;
			font-weight: 500;
		}
		.ext-searchResult-desc {
			font-size: 0.85em;
			width: 90%;
			color: $darkGrey;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 1; /* number of lines to show */
			line-clamp: 1;
			-webkit-box-orient: vertical;
		}
	}

	.ext-searchResult-arrow {
		height: 20px;
		width: 20px;
		transform: rotate(-90deg);
	}

	&:hover {
		background-color: var(--gigauser-brand-color);
		color: white;
		.ext-searchResult-desc {
			color: white;
		}
	}
}

.gigauser-dark {
	.HelpCenterSearchBlur {
		backdrop-filter: blur(4px);
		background-color: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1);
	}

	.HelpCenterSearchBox {
		background-color: $darkBackground;
		box-shadow:
			2px 8px 8px 2px rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2),
			0 3px 10px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2);

		.search-input {
			background-color: $darkBackground;
			color: white;

			&::placeholder {
				color: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.7);
			}
		}
	}

	.ext-searchResult-text {
		.ext-searchResult-title {
			color: white;
		}
		.ext-searchResult-desc {
			color: $darkModeTextColor;
		}
	}

	.ext-searchResult {
		.ext-searchResult-icon {
			border: 1px solid $darkerGrey;
			background-color: $darkBackground;
		}

		&:hover {
			.ext-searchResult-title {
				color: white;
			}
			.ext-searchResult-desc {
				color: white;
			}
		}
	}
}
