// @import '../../../../../../../common/src/styles/_variables.scss';
//TODO: This needs to be fixed entirely. Not to be injected into styles.

@import "../../../styles/colors";
@import "../../../styles/shadows";

$fontFamily: "Poppins";

$trackColor: $hoverGrey;
$thumbColor: $darkGrey;
$borderRadius: 0px;

.gigauser-TextEditor {
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;

	line-height: 1.5em;

	&.l {
		font-size: 1.1em;
		line-height: 1.8em;
		color: rgb(19, 19, 19);
	}

	@keyframes growIn {
		0% {
			//transform: scaleX(0);
			opacity: 0;
		}

		100% {
			//transform: scaleX(1);
			opacity: 1;
		}
	}

	.gigauser-TextEditor-innerContainer {
		display: flex;
		flex-flow: column;
		align-items: center;
		width: 100%;
		display: flex;
		flex-flow: column;
		align-items: center;
		height: 100%;
		padding-bottom: 2rem;
		border-top: 1px solid transparent;
		background-image: linear-gradient(
			to right,
			transparent 4%,
			$translucentGray 5%,
			$translucentGray 94%,
			transparent 4%
		);
		background-size: 100% 1px;
		background-position: top;
		background-repeat: no-repeat;

		@keyframes fadeIn {
			from {
				opacity: 0;
			}
			to {
				opacity: 1;
			}
		}

		&::-webkit-scrollbar {
			width: 0.28rem;
			opacity: 0;
			transition:
				width 0.3s ease,
				opacity 0.3s ease;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 0.5rem;
			background: transparent;
			transition: all 0.3s ease;
		}

		&::-webkit-scrollbar-track {
			border-radius: 0.5rem;
			background: transparent;
			transition: all 0.3s ease;
			animation: fadeIn 2s ease-in-out;
		}

		&:hover {
			&::-webkit-scrollbar {
				opacity: 1;
				width: 0.28rem;
			}

			&::-webkit-scrollbar-thumb {
				background: $grad600;
				animation: fadeIn 2s ease-in-out;
			}

			&::-webkit-scrollbar-track {
				background: transparent;
				animation: fadeIn 2s ease-in-out;
			}
		}

		.gigauser-TextEditor-innerContainer-platform {
			width: 100%;
			background-color: $translucentBlack;
			padding: 1rem 1rem 0 1rem;
			margin-top: 1rem;
			overflow: hidden;
			border-radius: 1rem 1rem 0 0;
			position: absolute;
			bottom: 0;

			.gigauser-TextEditor-visible-false {
				display: none;
			}

			.gigauser-TextEditor-loading-skeleton-false {
				display: none;
			}

			.gigauser-TextEditor-loading-skeleton-true {
				background-color: $white;
				min-height: calc(100vh - 10.8rem);
				max-height: calc(100vh - 10.8rem);
				padding: 2rem 4rem 4rem 4rem;
				border-radius: 0.7rem 0.7rem 0 0;
			}

			.remirror-editor-wrapper {
				background-color: $white;
				min-height: calc(100vh - 10.8rem);
				max-height: calc(100vh - 10.8rem);
				overflow-y: overlay;
				padding: 2rem 4rem 4rem 4rem;
				border-radius: 0.7rem 0.7rem 0 0;
				border: 0.25rem solid transparent;
				border-top: 0.625rem solid transparent;
				border-bottom: 0;
				scroll-behavior: smooth;
				width: 100%;

				&::-webkit-scrollbar-thumb {
					background: transparent;
					border-radius: 2rem;
				}

				&::-webkit-scrollbar-track {
					background: transparent;
				}

				&:hover {
					&::-webkit-scrollbar-thumb {
						background: $darkBlackGrey;
					}
				}
			}
		}

		.gigauser-TextEditor-innerContainer-rest {
			width: 100%;
		}
	}
}
.gigauser-light {
	--main-text-color: black;
}

.TextEditorContainer.gigauser-dark {
	--main-text-color: white;
	--check-color: $lightHover;
}

.remirror-floating-popover {
	z-index: 1;
}

// .remirror-editor.ProseMirror p{
//     margin-bottom: 12px;
// }

.remirror-editor.ProseMirror h1,
.remirror-editor.ProseMirror h2 {
	margin-top: 22px;
	margin-bottom: 22px;
	line-height: normal;
}

.remirror-editor.ProseMirror h3,
.remirror-editor.ProseMirror h4,
.remirror-editor.ProseMirror h5 {
	margin-top: 20px;
	margin-bottom: 20px;
	line-height: normal;
}

.ProseMirror.remirror-editor {
	position: relative;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	// padding: 20px;
	padding-top: 0px;
	padding-bottom: 10px;
	box-sizing: border-box;
	//font-family: $fontFamily;
	padding: 0px;

	ul,
	ol {
		padding: 0px 40px;
		// margin-top: 15px;
		// margin-bottom: 15px;
	}
}

/* Placeholder (at the top) */
.ProseMirror p.remirror-is-empty:first-child::before {
	content: attr(data-placeholder);
	float: left;
	color: #adb5bd;
	pointer-events: none;
	height: 0;
}

/* Placeholder (on every new line) */
/*.ProseMirror p.is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }*/
.ProseMirror.remirror-editor {
	box-shadow: none;
}

.gigauser-TextEditor
	.ProseMirror.remirror-editor
	code:not([data-code-block-language]) {
	padding: 0 3px;
	background-color: $lightGrey;
	border-radius: 1px;
}

.TextEditorContainer.gigauser-dark
	.gigauser-TextEditor
	.ProseMirror.remirror-editor
	code:not([data-code-block-language]) {
	padding: 0 3px;
	background-color: $darkestGrey;
	border-radius: 1px;
}

.ProseMirror.remirror-editor.ProseMirror-focused {
	box-shadow: none;
	outline: none;
	// border-top: 2px solid $menuBgColor;
}

.remirror-theme .ProseMirror:focus,
.remirror-theme .ProseMirror:active {
	box-shadow: none;
}

.nb-header .nb-titlebar {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
	padding-top: 10px;
}

.remirror-toolbar button {
	border: 1px solid black;
	margin: 2px;
}

.notebook-container {
	box-sizing: border-box;
	// position: relative;
	width: 100%;
	height: calc(100vh - 140px);
	// border: 1px solid red;
	margin-top: 15px;
	overflow: scroll;
}

.light .notebook-container {
	color: black;
	--dropcursor-color: black;
}

.dark .notebook-container {
	color: white;
	--dropcursor-color: white;
}

.notebook-container img {
	-webkit-user-drag: auto;
	cursor: pointer;
}

.light .remirror-editor.ProseMirror ::selection,
.light .remirror-editor.ProseMirror .selection {
	background: #b4d7ff;
	caret-color: var(--rmr-color-selection-caret);
	color: inherit;
	// text-shadow: var(--rmr-color-selection-shadow);
}

.dark .remirror-editor.ProseMirror ::selection,
.dark .remirror-editor.ProseMirror .selection {
	background: #5d83ac;
	color: inherit;
	// caret-color: var(--rmr-color-selection-caret);
	// text-shadow: var(--rmr-color-selection-shadow);
}

.remirror-collapsible-list-item-button.disabled,
.remirror-collapsible-list-item-button.disabled:hover {
	background-color: var(--main-text-color);
	border: none;
	border-radius: 50%;
	height: 6px;
	width: 6px;
	cursor: default;
}

.remirror-collapsible-list-item-button,
.remirror-collapsible-list-item-button:hover {
	width: 0;
	height: 0;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-radius: 0%;
	border-top: 8px solid var(--main-text-color);
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	transition: none;
	background-color: transparent;
}

.remirror-collapsible-list-item-closed .remirror-collapsible-list-item-button {
	background-color: transparent;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-right: none;
	border-radius: 0%;
	border-left: 8px solid var(--main-text-color);
}

// [data-checked]{
//     text-decoration: line-through;
// }

.remirror-editor input[type="checkbox"] {
	/* Add if not using autoprefixer */
	-webkit-appearance: none;
	/* Remove most all native input styles */
	appearance: none;
	/* For iOS < 15 */
	background-color: var(--form-background);
	/* Not removed via appearance */
	margin: 0;

	font: inherit;
	color: currentColor;
	width: 1.15em;
	height: 1.15em;
	border: 0.05em solid currentColor;
	border-radius: 0.15em;
	transform: translateY(-0.075em);

	display: grid;
	place-content: center;
}

.remirror-editor input[type="checkbox"]::before {
	content: "";
	width: 0.65em;
	height: 0.65em;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
	transform: scale(0);
	transform-origin: bottom left;
	transition: 120ms transform ease-in-out;
	// box-shadow: inset 1em 1em var(--check-color, purple);
	/* Windows High Contrast Mode */
	// background-color: var(--check-color, purple);
}

.remirror-editor p {
	padding: 1px;
}

//   .remirror-editor p[:dir=='ltr']{
//     padding:0px;

//   }

.remirror-editor input[type="checkbox"]:checked::before {
	transform: scale(1);
}

.remirror-editor input[type="checkbox"]:disabled {
	--form-control-color: var(--form-control-disabled);

	color: var(--form-control-disabled);
	cursor: not-allowed;
}

.light .remirror-editor input[type="checkbox"]::before {
	background-color: steelblue;
}

.dark .remirror-editor input[type="checkbox"]::before {
	background-color: deepSkyBlue;
}

.light .remirror-list-spine {
	position: absolute;
	top: 4px;
	bottom: 0px;
	left: -20px;
	width: 16px;
	cursor: pointer;
	transition: border-left-color 0.25s ease;
	border-left-color: var(--rmr-color-border);
	border-left-style: solid;
	border-left-width: 1px;
}

.dark .remirror-list-spine {
	position: absolute;
	top: 4px;
	bottom: 0px;
	left: -20px;
	width: 16px;
	cursor: pointer;
	transition: border-left-color 0.25s ease;
	border-left-color: white;
	border-left-style: solid;
	border-left-width: 1px;
}

.remirror-editor.ProseMirror ol {
	list-style-type: decimal;
}

.remirror-editor.ProseMirror ol ol {
	list-style-type: lower-alpha;
}

.remirror-editor.ProseMirror ol ol ol {
	list-style-type: lower-roman;
}

.remirror-editor.ProseMirror ol ol ol ol {
	list-style-type: decimal;
}

.remirror-editor.ProseMirror ol ol ol ol ol {
	list-style-type: lower-alpha;
}

.remirror-editor.ProseMirror ol ol ol ol ol ol {
	list-style-type: lower-roman;
}

.light .remirror-editor.ProseMirror h1,
.light .remirror-editor.ProseMirror h2,
.light .remirror-editor.ProseMirror h3,
.light .remirror-editor.ProseMirror h4,
.light .remirror-editor.ProseMirror h5 {
	color: black;
	line-height: normal;
	// font-family: $fontFamily;
}

.dark .remirror-editor.ProseMirror h1,
.dark .remirror-editor.ProseMirror h2,
.dark .remirror-editor.ProseMirror h3,
.dark .remirror-editor.ProseMirror h4,
.dark .remirror-editor.ProseMirror h5 {
	color: white;
	line-height: normal;

	//font-family: $fontFamily;
}

.remirror-theme a {
	cursor: pointer;
}

.ProseMirror div.gigauser-rich-editor-img,
img.gigauser-rich-editor-img {
	border: 1px solid lightgrey;
	border-radius: 5px;
	width: 100%;
	margin-top: 10px;
	padding: 1px;
	position: relative;
	// max-height: 400px;
}

.gigauser-text-editor-fix {
	padding-top: 0px;
}

.remirror-editor.ProseMirror table {
	th {
		// border-bottom: 3px solid rgb(227, 232, 237);
		color: $darkestGrey;
		background-color: $clipboardGrey;
		border-color: transparent;

		font-size: 1em;
		font-weight: 400;
		padding: 0.3em;
	}
}

.remirror-editor.ProseMirror td {
	padding: 0.2em 0em;
	border-color: transparent;
	border-bottom: 1px solid $clipboardGrey;
	font-size: 1em;

	p {
		padding: 0em 0.5em;
	}
}

.remirror-editor.ProseMirror pre {
	font-size: 0.8em;

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	&::-webkit-scrollbar-track {
		background: $trackColor;
		// border-top-right-radius: 10px;
		// border-bottom-right-radius: 10px;
		// border-left: 1px solid grey;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		// border-radius: 20px;
		// margin-right: 5px;
		border: 1px solid $trackColor;
		padding-top: 0px solid $trackColor;
		// border-left: 1px solid grey;
	}
}

.gigauser-helpcenter-text-link {
	white-space: nowrap;
	padding: 5px;
	color: var(--gigauser-brand-color);
	background-color: $offWhite;
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		background-color: var(--gigauser-light-brand-color);
	}
}

.remirror-editor.ProseMirror a {
	color: $pink;
}

.TextEditorContainer.gigauser-dark {
	.ProseMirror {
		color: #dee2ea;
	}

	.remirror-editor.ProseMirror a {
		color: lightblue;
	}

	.gigauser-helpcenter-text-link {
		white-space: nowrap;
		padding: 5px;
		color: var(--gigauser-darkMode-brand-color);
		background-color: transparentize($darkerGrey, 0.6);
		border-radius: 5px;

		&:hover {
			background-color: transparentize($darkerGrey, 0.1);
		}
	}

	.ProseMirror div.gigauser-rich-editor-img,
	img.gigauser-rich-editor-img {
		border: 1px solid $darkerGrey;
	}

	.remirror-editor.ProseMirror h1,
	.remirror-editor.ProseMirror h2,
	.remirror-editor.ProseMirror h3,
	.remirror-editor.ProseMirror h4,
	.remirror-editor.ProseMirror h5 {
		color: #dee2ea;
	}

	.remirror-editor.ProseMirror table {
		th {
			// border-bottom: 3px solid rgb(227, 232, 237);
			color: $mediumGrey;
			background-color: $hoverGrey;
		}
	}

	.remirror-editor.ProseMirror td {
		padding: 0.2em 0em;
		border-color: transparent;
		border-bottom: 1px solid $darkerGrey;
	}
}

.TextEditorContainer.gigauser-dark {
	.voice-container {
		display: flex;
		align-items: center;
		width: 100%;
		font-family: "Slabo 13px", serif;
		font-size: 1rem;
		font-style: normal;
		margin-top: 0.8rem;
		font-weight: 700;
		line-height: 1.5rem; /* 171.429% */
		justify-content: space-between;
		color: white;
		user-select: none;
		padding-top: 0.2rem;
		padding-bottom: 0.75rem;
		padding-left: 1.2rem;
		padding-right: 1.2rem;

		.boxIcon-container {
			font-family: "Manrope", sans-serif;
			font-size: 0.8rem;
			font-weight: 600;
		}
	}

	.voice-flag-container {
		display: flex;
		align-items: center;
		gap: 0.7rem;
		cursor: pointer;
	}

	.voice-flag {
		width: 1.4rem;
		height: 1.4rem;
	}

	.gigauser-TextEditor-videoTranscript {
		margin-top: 0.2rem;

		.gigauser-TextEditor-innerContainer {
			font-size: 0.95rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.75rem;
			font-family: "Slabo 13px", serif;
			height: 76vh;
			overflow-y: scroll;
			padding-left: 1.2rem;
			padding-right: 1.2rem;
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;
			// text-align: justify;
		}
	}
}
