@import "../../styles/colors";
@import "../../styles/shadows";

.HoverTip-container {
	position: relative;

	&:hover {
		.hoverTip-label {
			opacity: 1;
			// scale: 1;
			pointer-events: auto;
		}
	}
}

.hoverTip-label {
	opacity: 0;
	pointer-events: none;
	z-index: 100000;

	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	transition: 0.3s;

	padding: 5px 1rem;
	margin-top: 10px;
	width: max-content;
	max-width: 200px;

	&.l {
		max-width: 300px;
		padding: 1rem 2rem;
	}

	&.s {
		max-width: 200px;
		padding: 5px 1rem;
	}

	border-radius: 5px;
	box-shadow: $darkBoxShadow;
	font-family: "Manrope", sans-serif;

	// background-color: transparentize($grey01, 0.03);
	background-color: $white;
	color: $grey01;

	display: flex;
	flex-flow: column;
	gap: 0.5rem;

	.hoverTip-title {
		font-size: 0.8rem;

		&.l {
			font-size: 0.9rem;
		}

		font-weight: 700;
		// margin-bottom: 0.5rem;
		width: 100%;
		text-align: center;
	}

	.hoverTip-para {
		font-weight: 500;
		font-size: 0.8rem;
		text-align: left;
		margin-bottom: 0.5rem;
		line-height: 1.2rem;
	}

	.hoverTip-para-li {
		font-weight: 500;
		font-size: 0.8rem;
		text-align: left;
		margin-bottom: 0.4rem;
		line-height: 1.2rem;
	}
}

@keyframes opacityappear {
	0% {
		transform: scale(0.1, 0.1);
		padding: 5x;
	}
	100% {
		padding: 5x;
		transform: scale(1, 1);
	}
}
