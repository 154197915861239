:root {
	--my-color: 255, 0, 0; /* red */
}

.gigauser-GuidesList {
	padding: 0px 20px;
	position: relative;
}
.gigauser-GuidesList.gigauser-bordered {
	border-radius: 10px;
	border: 1px solid #c7c7c7;
}

.gigauser-GuidesList-title {
	margin: 0px;
	margin-bottom: 10px;
	color: #303030;
	font-size: 1em;
}

.gigauser-GuidesList-guides {
	overflow-y: auto;
	overflow-x: hidden;
}

.gigauser-guidelist-header {
	display: flex;
	justify-content: center;
	/* align-items: center; */
	flex-flow: column;
	width: 90%;
	gap: 4px;
}

.gigauser-GuidePreview {
	border-bottom: 1px solid #c7c7c7;
	border-top: none;
	display: flex;
	justify-content: space-between;
	transition: 0.3s;
	text-align: left;
	padding: 14px 5px;
}
.gigauser-GuidePreview.gigauser-GuidesList-notloading:hover {
	cursor: pointer;
	background-color: rgba(var(--gigauser-brand-color-rgb), 0.05);
}
.gigauser-GuidePreview.gigauser-GuidesList-notloading:hover
	.gigauser-Preview-arrow {
	margin-right: 10px;
}
.gigauser-GuidePreview.firstRow {
	border-top: 1px solid #c7c7c7;
}
.gigauser-GuidePreview.lastRow {
	margin-bottom: 0px;
}
.gigauser-GuidePreview .gigauser-GuideName {
	font-weight: 600;
	font-size: 14px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	line-clamp: 1;
	-webkit-box-orient: vertical;
}
.gigauser-GuidePreview .gigauser-GuideDescription {
	margin-top: 5px;
	width: 90%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	line-clamp: 1;
	-webkit-box-orient: vertical;
}
.gigauser-GuidePreview .gigauser-Preview-arrow {
	transition: 0.3s;
	width: 20px;
	transform: rotate(-90deg);
}
.gigauser-GuidePreview .gigauser-loading-guide {
	display: flex;
	align-items: center;
	margin-right: 10px;
}

.gigauser-GuidesList-guides.gigauser-GuidesList-loading .gigauser-GuideName {
	color: transparent;
	background-color: #f0f3ff;
	padding: 3px;
	border-radius: 3px;
}
.gigauser-GuidesList-guides.gigauser-GuidesList-loading
	.gigauser-GuideDescription {
	padding: 3px;
	border-radius: 3px;
	color: transparent;
	background-color: #f0f3ff;
}
.gigauser-GuidesList-guides.gigauser-GuidesList-loading
	.gigauser-GuideDescription:after {
	background: linear-gradient(
		90deg,
		transparent,
		rgba(255, 255, 255, 0.2),
		transparent
	);
	content: "";
	display: block;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	transform: translateX(-100px);
	animation: gigauserloadingflashes 0.8s infinite;
}

.gigauser-viewAll {
	margin-top: 20px;
	width: -moz-fit-content;
	width: fit-content;
	font-weight: bold;
	color: var(--gigauser-brand-color);
	cursor: pointer;
	transition: 0.3s;
	font-size: 0.85em;
}
.gigauser-viewAll:hover {
	opacity: 0.8;
}

.gigauser-empty-articles {
	font-size: 1.1em;
	color: #727272;
	text-align: left;
}

.gigauser-GuideList-published {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}
.gigauser-GuideList-published .gigauser-published-circle {
	color: transparent;
	height: -moz-fit-content;
	height: fit-content;
	width: 8px;
	height: 8px;
	border-radius: 1000px;
}
.gigauser-GuideList-published .gigauser-published-circle.gigauser-published {
	background-color: #3a9434;
}
.gigauser-GuideList-published .gigauser-published-circle.gigauser-draft {
	background-color: #ff965e;
}

@keyframes gigauserloadingflashes {
	100% {
		transform: translateX(100%);
	}
} /*# sourceMappingURL=GuidesList.css.map */
