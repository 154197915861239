@import "../../../styles/colors";

.Breadcrumbs {
	display: flex;
	align-items: center;
	user-select: none;
}

.gigauser-Breadcrumbs {
	display: flex;
	align-items: center;
	font-size: 0.8em;
}

.Breadcrumbs-arrow {
	margin: 0px 5px;
}

.gigauser-PageTitle-pathSegment {
	display: inline-flex;
	align-items: center;
	border-radius: 999px;
	transition: 0.3s;
	padding: 4px 8px;
	color: $neutral200;
	font-weight: 600;
	user-select: none;

	.gigauser-breadcrumbs-statuslight {
		height: 0.5rem;
		width: 0.5rem;
		color: transparent;
		border-radius: 9999px;
		margin-right: 0.5rem;

		&.public {
			background-color: $darkModeGreen;
		}

		&.private {
			background-color: $darkGold;
		}
	}

	&.gigauser-home-segment {
		margin-left: -8px;
	}

	&.navigable {
		&:hover {
			cursor: pointer;
			color: var(--gigauser-brand-color);
			background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
		}

		&.gigauser-activeSegment {
			background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
			color: var(--gigauser-brand-color);
			font-weight: bold;
		}
	}

	&.platform-segment {
		&:hover {
			color: $primary500;
		}

		&.gigauser-activeSegment {
			color: $primary500;
			background-color: transparentize($primary200, 0.9);
			font-weight: bold;
		}
	}
}

.gigauser-breadcrumbs-home {
	width: 20px;
	height: 20px;
	display: inline-block;
}

.gigauser-Breadcrumb-arrow {
	transform: rotate(270deg);
	width: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0px 5px;
	transition: 0.3s;
}

.droppable-pathSegment {
	cursor: pointer;
	&:hover {
		color: $darkPurple;
	}

	&.onDrop {
		color: $darkestGrey;
		background-color: $lightestPink;
		padding: 2px 12px;
		border-radius: 3px;
	}
}

.segment-status {
	color: transparent;
	height: 6px;
	width: 6px;
	border-radius: 99px;
	display: inline-flex;
	margin-left: 6px;

	&.segment-private {
		background-color: $mediumGrey;
	}

	&.segment-public {
		background-color: $lightModeGreen;
	}
}

.gigauser-dark {
	.gigauser-PageTitle-pathSegment {
		color: $darkModeTextColor;

		&.navigable {
			&:hover {
				background-color: $darkerGrey;
				color: var(--gigauser-darkMode-brand-color);
			}

			&.gigauser-activeSegment {
				background-color: $darkestGrey;
				color: var(--gigauser-darkMode-brand-color);
			}
		}
	}
}
