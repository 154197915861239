@import "./colors";

$boxShadow:
	0 2px 4px 0 rgba(0, 0, 0, 0.2),
	0 3px 10px 0 rgba(0, 0, 0, 0.195);
$MultiProfileShadow:
	0 4px 8px 0 rgba(0, 0, 0, 0.2),
	0 5px 20px 0 rgba(0, 0, 0, 0.195);
$lighterBoxShadow: 0 2px 4px rgba(0, 0, 0, 0.1);

$glow: 0px 0px 1px 0px $pink;
$lightGreyShadow: 0px 0px 2px 1px $lightGrey;
