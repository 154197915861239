.ThemeSwitch-wrapper {
	box-sizing: inherit;
	font-size: 25.8px;
	margin-right: 12px;
	display: flex;
	align-items: center;
	box-sizing: inherit;
	font-size: 25.8px;
	margin-right: 12px;
	display: flex;
	align-items: center;
}

#hide-checkbox {
	opacity: 0;
	height: 0;
	width: 0;
}

.toggle {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 60px;
	height: 30px;
	background: rgb(var(--gigauser-brand-color-rgb), 0.5);
	border-radius: 15px;
	transition: 500ms;
	overflow: hidden;
}

.toggle-button {
	position: absolute;
	display: inline-block;
	top: 2.1px;
	left: 1.8px;
	width: 25.8px;
	height: 25.8px;
	border-radius: 50%;
	background: #faeaf1;
	overflow: hidden;
	box-shadow: 0 0 10.5px 1.2px rgb(255, 255, 255);
	transition: all 500ms ease-out;
}

.crater {
	position: absolute;
	display: inline-block;
	background: #faeaf1;
	border-radius: 50%;
	transition: 500ms;
}

.crater-1 {
	background: #fffff9;
	width: 0.3em;
	height: 0.3em;
	left: 3px;
	bottom: 3px;
}

.crater-2 {
	width: 6px;
	height: 6px;
	top: -2.1px;
	left: 13.2px;
}

.crater-3 {
	width: 4.8px;
	height: 4.8px;
	top: 6px;
	right: -1.2px;
}

.crater-4 {
	width: 3px;
	height: 3px;
	top: 7.2px;
	left: 9px;
}

.crater-5 {
	width: 4.5px;
	height: 4.5px;
	top: 12px;
	left: 14.4px;
}

.crater-6 {
	width: 3px;
	height: 3px;
	top: 14.4px;
	left: 6px;
}

.crater-7 {
	width: 12px;
	height: 12px;
	bottom: 5px;
	left: 35px;
}

.star {
	position: absolute;
	display: inline-block;
	border-radius: 50%;
	background: #fff;
	box-shadow: 0.3px 0 0.6px 0.6px rgb(255, 255, 255);
}

.star-1 {
	width: 1.8px;
	height: 1.8px;
	right: 27px;
	bottom: 12px;
}

.star-2 {
	width: 2.4px;
	height: 2.4px;
	right: 21px;
	top: 3px;
}

.star-3 {
	width: 1.5px;
	height: 1.5px;
	right: 18px;
	bottom: 4.5px;
}

.star-4 {
	width: 0.9px;
	height: 0.9px;
	right: 12px;
	bottom: 15px;
}

.star-5 {
	width: 1.2px;
	height: 1.2px;
	right: 3px;
	bottom: 10.5px;
}

.star-6,
.star-7,
.star-8 {
	width: 3px;
	height: 0.6px;
	border-radius: 0.6px;
	transform: rotate(-45deg);
	box-shadow: 1.5px 0px 1.2px 0.3px #fff;
	animation-name: travel;
	animation-duration: 1.5s;
	animation-timing-function: ease-out;
	animation-iteration-count: infinite;
}

.star-6 {
	right: 9px;
	bottom: 9px;
	animation-delay: -2s;
}

.star-7 {
	right: 15px;
	bottom: 18px;
}

.star-8 {
	right: 27px;
	top: 3px;
	animation-delay: -4s;
}

@keyframes travel {
	0% {
		transform: rotate(-45deg) translateX(70px);
	}
	50% {
		transform: rotate(-45deg) translateX(-20px);
		box-shadow: 1.5px 0px 1.8px 0.3px #fff;
	}
	100% {
		transform: rotate(-45deg) translateX(-30px);
		width: 0.6px;
		height: 0.6px;
		opacity: 0;
		box-shadow: none;
	}
}
#hide-checkbox:checked + .toggle {
	background: rgb(var(--gigauser-brand-color-rgb), 0.7);
}

#hide-checkbox:checked + .toggle .toggle-button {
	background: #f7ffff;
	transform: translateX(30.6px);
	box-shadow: 0 0 10.5px 1.5px rgb(255, 255, 255);
}

#hide-checkbox:checked + .toggle .toggle-button .crater {
	transform: rotate(-45deg) translateX(21px);
}

#hide-checkbox:checked + .toggle .star {
	animation: move 2s infinite;
	transform: none;
	box-shadow: none;
}

#hide-checkbox:checked + .toggle .star-1 {
	width: 12px;
	height: 3px;
	border-radius: 3px;
	background: #fff;
	left: 6px;
	top: 7.5px;
	box-shadow: none;
}

#hide-checkbox:checked + .toggle .star-2 {
	width: 3.6px;
	height: 3.6px;
	background: #fff;
	left: 7.8px;
	top: 6.9px;
	box-shadow: -0.3px 0 0.6px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-3 {
	width: 4.8px;
	height: 4.8px;
	background: #fff;
	left: 10.5px;
	top: 5.7px;
	box-shadow: -0.3px 0 0.6px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-4 {
	width: 4.2px;
	height: 4.2px;
	background: #fff;
	left: 13.8px;
	top: 6.3px;
	box-shadow: -0.3px 0 0.6px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-5 {
	width: 18px;
	height: 4.5px;
	border-radius: 4.5px;
	background: #fff;
	left: 9px;
	bottom: 6px;
	box-shadow: none;
}

#hide-checkbox:checked + .toggle .star-6 {
	width: 5.4px;
	height: 5.4px;
	background: #fff;
	border-radius: 50%;
	left: 11.4px;
	bottom: 6px;
	box-shadow: -0.3px 0 0.6px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-7 {
	width: 7.2px;
	height: 7.2px;
	background: #fff;
	border-radius: 50%;
	left: 15.6px;
	bottom: 6px;
	box-shadow: -0.3px 0 0.6px 0 rgba(0, 0, 0, 0.1);
}

#hide-checkbox:checked + .toggle .star-8 {
	width: 6.3px;
	height: 6.3px;
	background: #fff;
	border-radius: 50%;
	left: 21px;
	top: 17.7px;
	box-shadow: -0.3px 0 0.6px 0 rgba(0, 0, 0, 0.1);
}

@keyframes move {
	0% {
		transform: none;
	}
	25% {
		transform: translateX(0.6px);
	}
	100% {
		transform: translateX(-0.6px);
	}
}
p.morning {
	background: #e67e22;
} /*# sourceMappingURL=ThemeSwitch.css.map */
