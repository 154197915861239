@import "../../../styles/colors";
@import "../../../styles/shadows";

.gigauser-video-container {
	position: relative;
	// width: 100%;
	// max-width: 800px;
	margin: 0 auto;
	margin-bottom: 2.3em;
	width: fit-content;

	&.gigauser-embed-video {
		margin-bottom: 0;
	}

	&.gigauser-nonembed-video {
		video {
			max-height: 500px;
		}
	}

	media-player {
		border: none;
		background-color: transparent;

		div[part="scrim"] {
			height: 99%;
		}
	}

	.gigauser-laptop-mediaplayer {
		display: flex;

		.thumbnail-play {
			position: absolute;
			display: none;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 999;

			media-play-button {
				background-color: var(--gigauser-brand-color);
				opacity: 0.8;
				padding: 1.2em;
				border-radius: 999px;
				transition: 0.3s;
				border: 4px solid rgba(255, 255, 255, 0.279);
				scale: 1.4;
				svg {
					height: 5em;
					width: 5em;
				}
			}
		}

		.flash-play {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 999;
			height: 4.5em;
			width: 4.5em;
			opacity: 0.8;
			background-color: var(--gigauser-brand-color);
			justify-content: center;
			align-items: center;
			border-radius: 999px;
			opacity: 0;
			transition: 0.2s;

			.flash-icon-container {
				height: 2em;
				width: 2em;
				display: flex;
				align-items: center;
			}

			&.flash-show {
				opacity: 0.6;
				scale: 1;
				display: flex;
				.flash-icon-container {
				}
			}
		}

		&.thumbnail-preview {
			.thumbnail-play {
				display: flex;
				flex-flow: column;
				align-items: center;
				gap: calc(1em);
			}

			div[part="scrim"],
			div[part="controls-group"] {
				opacity: 0;
			}

			media-play-button {
				box-sizing: content-box;
			}
		}

		&:hover {
			.thumbnail-play {
				media-play-button {
					opacity: 1;
					padding: 0.8em;
				}

				.thumbnail-play-bg {
					opacity: 0.9;
					// height: 48px;
					// width: 48px;
					padding: 48px;
					border: 10px solid rgba(255, 255, 255, 0.279);
					// margin-top: 2em;
				}

				.thumbnail-play-icon {
					opacity: 0.9;
					height: 36px;
					width: 36px;
				}
			}
		}
	}

	.gigauser-laptop-mediaplayer:not([data-hover="true"]) {
		//When the mouse is not hovering on the video

		div[part="scrim"],
		div[part="controls-group"] {
			opacity: 0;
		}
		media-captions {
			bottom: 0;
		}

		.thumbnail-play {
			&.video-paused {
				display: flex;
			}
		}
	}

	// Styling for full screen
	.gigauser-laptop-mediaplayer[data-fullscreen] {
		:where(media-outlet video) {
			margin-top: 50vh;
			transform: translateY(-50%);
			width: 100vw;
			max-height: 100vh;
		}
	}

	media-outlet {
		background-color: transparent;
		box-shadow: none;
	}
}

.gigauser-play-button {
	position: absolute;
	z-index: 999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80px;
	height: 80px;
	background-color: var(--gigauser-brand-color);
	opacity: 0.6;
	// border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	text-align: center;
	transition: 0.3s;

	&.gigauser-isPlaying {
		display: none;
	}
}

.gigauser-play-button::before {
	content: "";
	margin-left: 4px;
	border-style: solid;
	border-width: 12px 0 12px 24px;
	border-color: transparent transparent transparent white;
}

.gigauser-editor-video {
	width: 100%;
	// border-radius: 10px;
	box-shadow: $boxShadow;
	border: none;
	margin-top: 20px;
}

.gigauser-video-container {
	&.gigauser-noplay-video {
		pointer-events: none;
	}

	&:hover {
		cursor: pointer;
		.gigauser-play-button {
			opacity: 1;
		}
	}
}

.gigauser-mobile-mediaplayer {
	.thumbnail-play {
		display: none;
	}

	.flash-play {
		display: none;
	}
}

.thumbnail-clueso-preview {
	background-color: #000000a9;
	color: white;
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 0.8em;
	padding: 0.6rem 0.4rem;
	border-radius: 10px;
	box-sizing: border-box;
	font-size: 0.7rem;
	justify-content: center;
	width: fit-content;
	// margin-top: 0.6em;
	text-decoration: none;

	.thumbnail-clueso-text {
		width: 3rem;
	}

	.thumbnail-clueso-logo {
		height: 1.6em;
		width: calc(4008 / 1200 * 1.6em);
	}
}

.thumbnail-play {
	width: fit-content;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	text-align: center;
	transition: 0.3s;
	opacity: 1;
	gap: 1em;
	transition: opacity 0.3s;

	// &.video-paused{
	//     display: block;
	// }

	&.gigauser-hide-thumbnail-play {
		opacity: 0;
	}

	.thumbnail-play-icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.thumbnail-play-icon {
		position: absolute;
		z-index: 3;
		height: 32px;
		width: 32px;
		opacity: 0.8;
		transition: 0.3s;
		transform: translateX(14%);
	}

	.thumbnail-play-bg {
		background-color: var(--gigauser-brand-color);
		padding: 56px;
		border-radius: 99px;
		border-color: rgba(255, 255, 255, 0.279);
		border: 12px solid rgba(255, 255, 255, 0.279);
		// height: 64px;
		// width: 64px;
		opacity: 0.8;
		transition: 0.3s;
		display: flex;
		justify-content: center;
		align-items: center;
		color: white;
		box-sizing: border-box;
	}
}
