.ImgShape-container {
	position: absolute;
	border-radius: 3px;
	color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;
}
.ImgShape-container .ImgShape {
	height: 100%;
	width: 100%;
	transition-property: transform; /* This applies the transition only to the transform property */
	transition-duration: 0.3s; /* This sets the duration of the transition */
}
.ImgShape-container .ImgShape.arrow .ArrowHead {
	position: absolute;
	bottom: 0;
	right: 0;
}
.ImgShape-container .ImgShape.circle {
	border-radius: 999999px;
}
.ImgShape-container .ImgShape.blur {
	filter: blur(5px);
	-webkit-backdrop-filter: blur(2px);
	backdrop-filter: blur(2px);
} /*# sourceMappingURL=ImgShape.css.map */
