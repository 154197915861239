.SizeController {
	background-color: rgb(66, 170, 245);
	border: 2px solid white;
	border-radius: 99px;
	position: absolute;
	transform: translate(-50%, -50%);
}
.SizeController.position-t {
	left: 50%;
	top: calc(0% - 0px);
	cursor: n-resize;
}
.SizeController.position-tl {
	left: calc(0% - 0px);
	top: calc(0% - 0px);
	cursor: nw-resize;
}
.SizeController.position-tr {
	left: calc(100% + 0px);
	top: calc(0% - 0px);
	cursor: ne-resize;
}
.SizeController.position-r {
	left: calc(100% + 0px);
	top: 50%;
	cursor: e-resize;
}
.SizeController.position-br {
	left: calc(100% + 0px);
	top: calc(100% + 0px);
	cursor: se-resize;
}
.SizeController.position-bl {
	left: calc(0% - 0px);
	top: calc(100% + 0px);
	cursor: sw-resize;
}
.SizeController.position-b {
	left: 50%;
	top: calc(100% + 0px);
	cursor: s-resize;
}
.SizeController.position-l {
	left: calc(0% - 0px);
	top: 50%;
	cursor: w-resize;
}
.SizeController.position-a {
	left: 50%;
	top: 50%;
	background-color: red;
	cursor: all-scroll;
	display: flex;
	justify-content: center;
	align-items: center;
}

.DragController {
	width: 100%;
	height: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	color: transparent;
	position: absolute;
	padding: 10px;
}
.DragController.text {
	border: 2px solid #d43f8c;
} /*# sourceMappingURL=SizeController.css.map */
