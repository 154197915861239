@import "../../styles/colors";

.WhiteHoverTip {
	margin-top: 0.125rem;
	animation-name: popupWhiteHoverTip;
	animation-duration: 0.15s;
	animation-timing-function: ease-out;
	animation-fill-mode: forwards;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 100;
	white-space: nowrap;
	opacity: 0;
	user-select: none;
	z-index: 2147483647;

	.WhiteHoverTip-inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: fit-content;
		height: fit-content;
		background: $neutral100;
		box-shadow: 0px 0.25rem 0.25rem 0px $translucentBlack2;
		border: 0;
		border-radius: 0.25rem;
		font-family: "Manrope";
		z-index: 999;

		.text-container {
			margin: 0.25rem 0.5rem;
			.text {
				color: $neutral600;
				font-size: 0.875rem;
				font-weight: 600;
				line-height: 1.25rem;
				margin: 0;
				padding: 0;
			}
		}

		.shortcut-container {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0.25rem 0.25rem 0.25rem 0;

			.shortcut-icon-container {
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $neutral200;
				border-radius: 0.25rem;
				width: 1.75rem;
				height: 1.75rem;

				.shortcut-icon {
					width: 1.25rem;
					height: 1.25rem;
					color: $neutral400;
					margin: 0.25rem;
				}
			}

			.shortcut-text-container {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 1.75rem;
				height: 1.75rem;
				border: 1px solid $neutral200;
				border-radius: 0.25rem;

				.shortcut-text {
					text-align: center;
					color: $neutral400;
					font-family: "Manrope";
					font-weight: 600;
					font-size: 0.75rem;
				}
			}

			.shortcut-joiner {
				margin: 0.125rem;
				color: $neutral400;
				font-family: "Manrope";
				font-weight: 600;
				font-size: 0.75rem;
			}
		}
	}
}

@keyframes popupWhiteHoverTip {
	from {
		opacity: 0;
		transform: translate(-50%, 10px); /* Start slightly below */
	}
	to {
		opacity: 1;
		transform: translate(-50%, 0); /* End perfectly aligned */
	}
}
