.gigauser-lds-ring-loader {
	display: inline-block;
	position: relative;
	color: transparent;
}
.gigauser-lds-ring-loader div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	// margin: 8px;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.gigauser-lds-ring-loader.white {
	div {
		border: 3px solid white;
		border-color: white transparent transparent transparent;
	}
}

.gigauser-lds-ring-loader.brandColor {
	div {
		border: 3px solid var(--gigauser-brand-color);
		border-color: var(--gigauser-brand-color) transparent transparent
			transparent;
	}
}

.gigauser-lds-ring-loader div:nth-child(1) {
	animation-delay: -0.45s;
}
.gigauser-lds-ring-loader div:nth-child(2) {
	animation-delay: -0.3s;
}
.gigauser-lds-ring-loader div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
