.gigauser-helpcenter-container {
	width: 100%;
	background-color: white;
	display: flex;
	align-items: center;
	flex-flow: column;
	box-shadow:
		0 4px 6px 0 rgba(0, 0, 0, 0.2),
		0 3px 10px 0 rgba(0, 0, 0, 0.195);
	position: relative;
}
.gigauser-helpcenter-container.gigauser-laptop {
	background-color: #f5f7fc;
	position: relative;
	min-height: 100vh;
	padding-bottom: 20px;
}
.gigauser-helpcenter-container.gigauser-mobile {
	overflow: hidden;
	height: 100vh;
}
.gigauser-helpcenter-container .gigauser-helpcenter-search-header {
	background-color: var(--gigauser-brand-color);
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header
	.gigauser-helpcenter-search-header-child.gigauser-laptop {
	padding: 20px 100px;
	padding-bottom: 100px;
	text-align: center;
	justify-content: center;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header
	.gigauser-helpcenter-search-header-child.gigauser-mobile {
	display: flex;
	width: 95%;
	align-items: center;
	justify-content: space-between;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header.gigauser-mobile {
	display: flex;
	align-items: center;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header
	.gigauser-helpcenter-searchbar {
	/* margin: auto; */
	width: 100%;
	background-color: var(--gigauser-brand-color);
	height: 42px;
	border-radius: 7px;
	display: flex;
	justify-content: center;
	vertical-align: center;
	align-items: center;
	padding: 5px 3px;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header
	.gigauser-helpcenter-searchbar.gigauser-laptop {
	width: 100%;
	border: 1px solid rgba(255, 255, 255, 0.459);
	margin-top: 40px;
	padding: 5px;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header
	.gigauser-helpcenter-searchbar.gigauser-mobile {
	max-width: 400px;
	margin-left: 10px;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header
	.gigauser-close-helpcenter {
	height: 16px;
	width: 16px;
	margin-right: -8px;
	border-radius: 999px;
	cursor: pointer;
	transition: 0.3s;
	opacity: 80%;
	padding-left: 10px;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header
	.gigauser-close-helpcenter:hover {
	opacity: 60%;
}
.gigauser-helpcenter-container
	.gigauser-helpcenter-search-header
	.gigauser-helpcenter-laptop-topheader {
	display: flex;
	justify-content: space-between;
	color: white;
	margin-bottom: 20px;
}

.gigauser-guideslist-title-help {
	padding: 20px 20px;
	font-size: 1.2em;
	margin: 0px;
	color: var(--gigauser-brand-color);
	text-align: left;
}

.gigauser-collectionslist-container {
	box-sizing: border-box;
	max-width: 450px;
	width: 100%;
	padding: 0 20px 0 20px;
}

.gigauser-search-loader {
	position: relative;
	display: flex;
	align-items: center;
}

.gigauser-search-icon {
	display: flex;
	align-items: center;
	height: 24px;
	width: 24px;
	box-sizing: content-box;
}
.gigauser-search-icon.gigauser-laptop {
	height: 40px;
}

.gigauser-search-input {
	border: none;
	background-color: transparent;
	color: white;
	padding-left: 14px;
	width: calc(95% - 26px);
	transition: 0.3s;
	font-size: 1.2em;
	font-weight: 600;
}
.gigauser-search-input::-moz-placeholder {
	-moz-transition: 0.3s;
	transition: 0.3s;
	color: rgba(255, 255, 255, 0.648);
}
.gigauser-search-input::placeholder {
	transition: 0.3s;
	color: rgba(255, 255, 255, 0.648);
}
.gigauser-search-input:focus::-moz-placeholder {
	color: transparent;
}
.gigauser-search-input:focus::placeholder {
	color: transparent;
}
.gigauser-search-input.gigauser-laptop {
	font-size: 1.8em;
}

.gigauser-search-input:focus {
	border: none;
	outline: none;
	padding-left: 10px;
	width: calc(95% - 26px);
}

.gigauser-no-help {
	padding: 0px 15px;
	font-weight: 500;
}

.gigauser-helpcenter-breadcrumbs {
	width: 80%;
	font-size: 1em;
	padding-left: 20px;
	padding-top: 20px;
	text-align: left;
}

.style-scroll::-webkit-scrollbar {
	width: 8px;
}
.style-scroll::-webkit-scrollbar-track {
	background: white;
}
.style-scroll::-webkit-scrollbar-thumb {
	background-color: rgba(var(--gigauser-brand-color-rgb), 0.15);
	border: 2px solid white;
	padding-top: 10px solid white;
}

.style-scroll-square {
	/* Works on Chrome, Edge, and Safari */
}
.style-scroll-square::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
}
.style-scroll-square::-webkit-scrollbar-track {
	background: rgba(242, 242, 247, 0.3);
}
.style-scroll-square::-webkit-scrollbar-thumb {
	background-color: #d0d0d0;
}
.style-scroll-square:hover::-webkit-scrollbar {
	-webkit-transition: 0.3s;
	transition: 0.3s;
	display: block;
}

.gigauser-emptysearch {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.gigauser-emptysearch.gigauser-mobile {
	margin-top: 20%;
}
.gigauser-emptysearch.gigauser-laptop {
	margin-top: 20%;
}
.gigauser-emptysearch .gigauser-emptysearch-icon {
	width: 120px;
}
.gigauser-emptysearch .gigauser-emptysearch-title {
	font-weight: bold;
	font-size: 1.3em;
	margin: 10px 0px;
}
.gigauser-emptysearch .gigauser-emptysearch-subtitle {
	font-size: 1.2em;
	margin-top: 20px;
	line-height: 1.8em;
}

.gigauser-helpcenter-guides-container.gigauser-laptop {
	margin-top: -60px;
	margin-bottom: 30px;
	border: 1px solid #f0f3ff;
	background-color: white;
	border-radius: 5px;
	top: 200px;
	width: 50vw;
	padding: 20px 60px;
	box-shadow:
		0 4px 6px 0 rgba(0, 0, 0, 0.2),
		0 3px 10px 0 rgba(0, 0, 0, 0.195);
	min-height: 60vh;
}
.gigauser-helpcenter-guides-container.gigauser-mobile {
	box-sizing: border-box;
	overflow-y: auto;
	width: 100%;
}

.gigauser-laptop .gigauser-guideslist-title-help {
	margin-bottom: 10px;
	margin-top: 20px;
}

.gigauser-domain-link {
	color: white;
}

.gigauser-helpcenter-center {
	width: 100%;
}

.gigauser-contact-support {
	padding: 10px 20px;
	font-weight: bold;
	width: -moz-fit-content;
	width: fit-content;
	background-color: #f2f2f7;
	cursor: pointer;
	height: -moz-fit-content;
	height: fit-content;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	transition: 0.3s;
}
.gigauser-contact-support:hover {
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.gigauser-contact-support:hover .gigauser-airplane-icon {
	translate: 5px;
}

.gigauser-airplane-icon {
	width: 20px;
	transition: 0.3s;
	height: 20px;
}

.gigauser-dark .style-scroll-square {
	/* Works on Chrome, Edge, and Safari */
}
.gigauser-dark .style-scroll-square::-webkit-scrollbar-track {
	background: rgba(200, 200, 200, 0.05);
}
.gigauser-dark .style-scroll-square::-webkit-scrollbar-thumb {
	background-color: #727272;
}
.gigauser-dark .style-scroll-square:hover::-webkit-scrollbar {
	-webkit-transition: 0.3s;
	transition: 0.3s;
	display: block;
} /*# sourceMappingURL=HelpCenter.css.map */
