.HelpCenterSearchBlur {
	position: fixed;
	color: transparent;
	left: 0;
	top: 0;
	z-index: 999;
	width: 100vw;
	height: 100vh;
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.05);
}

.HelpCenterSearchBox {
	z-index: 1000;
	position: absolute;
	top: 6em;
	left: 50%;
	transform: translateX(-50%);
	max-width: 600px;
	width: 80%;
	background-color: white;
	border-radius: 10px;
	box-shadow:
		2px 8px 8px 2px rgb(var(--gigauser-brand-color-rgb), 0.2),
		0 3px 10px 0 rgb(var(--gigauser-brand-color-rgb), 0.2);
}
.HelpCenterSearchBox .searchinput-container {
	display: flex;
	align-items: center;
	height: 100%;
	border-radius: 5px;
	padding: 0em 1em;
}
.HelpCenterSearchBox .searchinput-container .searchIcon {
	width: 1.4em;
	height: 1.4em;
}
.HelpCenterSearchBox .searchinput-container .search-input {
	height: 100%;
	width: 100%;
	padding: 1.2em 0.7em;
	font-size: 1.1em;
	color: var(--gigauser-brand-color);
	border: none;
	background-color: white;
}
.HelpCenterSearchBox .searchinput-container .search-input::-moz-placeholder {
	color: rgb(var(--gigauser-brand-color-rgb), 0.7);
	-moz-transition: 0.3s;
	transition: 0.3s;
}
.HelpCenterSearchBox .searchinput-container .search-input::placeholder {
	color: rgb(var(--gigauser-brand-color-rgb), 0.7);
	transition: 0.3s;
}
.HelpCenterSearchBox .searchinput-container .search-input:focus {
	outline: none;
}

.ext-searchResult {
	display: flex;
	align-items: center;
	padding: 0.8em 1em;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	margin: 0.4em;
	border-radius: 5px;
}
.ext-searchResult .ext-searchResult-icon {
	height: 20px;
	width: 20px;
	margin-right: 1em;
	padding: 4px;
	border: 1px solid #f2f2f7;
	border-radius: 5px;
	background-color: white;
	box-sizing: content-box;
}
.ext-searchResult .ext-searchResult-text {
	text-align: left;
	width: 90%;
	transition: 0s;
}
.ext-searchResult .ext-searchResult-text .ext-searchResult-title {
	font-size: 0.9em;
	margin-bottom: 0.2em;
	font-weight: 500;
}
.ext-searchResult .ext-searchResult-text .ext-searchResult-desc {
	font-size: 0.85em;
	width: 90%;
	color: #727272;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	line-clamp: 1;
	-webkit-box-orient: vertical;
}
.ext-searchResult .ext-searchResult-arrow {
	height: 20px;
	width: 20px;
	transform: rotate(-90deg);
}
.ext-searchResult:hover {
	background-color: var(--gigauser-brand-color);
	color: white;
}
.ext-searchResult:hover .ext-searchResult-desc {
	color: white;
}

.gigauser-dark .HelpCenterSearchBlur {
	-webkit-backdrop-filter: blur(4px);
	backdrop-filter: blur(4px);
	background-color: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1);
}
.gigauser-dark .HelpCenterSearchBox {
	background-color: #0e1117;
	box-shadow:
		2px 8px 8px 2px rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2),
		0 3px 10px 0 rgb(var(--gigauser-darkMode-brand-color-rgb), 0.2);
}
.gigauser-dark .HelpCenterSearchBox .search-input {
	background-color: #0e1117;
	color: white;
}
.gigauser-dark .HelpCenterSearchBox .search-input::-moz-placeholder {
	color: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.7);
}
.gigauser-dark .HelpCenterSearchBox .search-input::placeholder {
	color: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.7);
}
.gigauser-dark .ext-searchResult-text .ext-searchResult-title {
	color: white;
}
.gigauser-dark .ext-searchResult-text .ext-searchResult-desc {
	color: #9ca3af;
}
.gigauser-dark .ext-searchResult .ext-searchResult-icon {
	border: 1px solid #4b4b4b;
	background-color: #0e1117;
}
.gigauser-dark .ext-searchResult:hover .ext-searchResult-title {
	color: white;
}
.gigauser-dark .ext-searchResult:hover .ext-searchResult-desc {
	color: white;
} /*# sourceMappingURL=HelpCenterSearchBox.css.map */
