@import "../../styles/colors";
@import "../../styles/shadows";

$footerHeight: 40px;

$trackColor: white;
$thumbColor: rgba(var(--gigauser-brand-color-rgb), $lightenValue);
$borderRadius: 5px;

.gigauser-helpcenter-container {
	width: calc(100%);
	// border: 1px solid pink;
	background-color: white;
	display: flex;
	align-items: center;
	flex-flow: column;
	box-shadow: $boxShadow;
	position: relative;
	// padding-bottom: $footerHeight + 5px;
	// border: 2px solid $brandColor;

	&.gigauser-laptop {
		background-color: $offWhite;
		position: relative;
		min-height: 100vh;
		padding-bottom: 20px;
	}

	&.gigauser-mobile {
		// border-radius: 10px;
		overflow: hidden;
		height: 100vh;
	}

	.gigauser-helpcenter-search-header {
		background-color: var(--gigauser-brand-color);
		// height: 80px;
		padding-top: 10px;
		padding-bottom: 10px;
		width: 100%;

		.gigauser-helpcenter-search-header-child {
			&.gigauser-laptop {
				padding: 20px 100px;
				padding-bottom: 100px;
				text-align: center;
				justify-content: center;
			}
			&.gigauser-mobile {
				display: flex;
				width: 95%;
				align-items: center;
				justify-content: space-between;
			}
		}

		&.gigauser-mobile {
			display: flex;
			align-items: center;
			// border-top-left-radius: 5px;
			// border-top-right-radius: 5px;
		}

		.gigauser-helpcenter-searchbar {
			/* margin: auto; */
			// border: 1px solid yellow;
			width: calc(100%);

			background-color: var(--gigauser-brand-color);
			height: 42px;
			border-radius: 7px;
			display: flex;
			justify-content: center;
			vertical-align: center;
			align-items: center;
			padding: 5px 3px;

			&.gigauser-laptop {
				width: 100%;
				border: 1px solid rgba(255, 255, 255, 0.459);
				margin-top: 40px;
				padding: 5px;
			}

			&.gigauser-mobile {
				max-width: 400px;
				margin-left: 10px;
			}
		}

		.gigauser-close-helpcenter {
			height: 16px;
			width: 16px;
			margin-right: -8px;
			border-radius: 999px;
			cursor: pointer;
			transition: 0.3s;
			opacity: 80%;
			padding-left: 10px;
			&:hover {
				opacity: 60%;
			}
		}

		.gigauser-helpcenter-laptop-topheader {
			display: flex;
			justify-content: space-between;
			color: white;
			margin-bottom: 20px;
		}
	}
}

.gigauser-guideslist-title-help {
	padding: 20px 20px;
	font-size: 1.2em;
	margin: 0px;
	color: var(--gigauser-brand-color);
	text-align: left;
}

.gigauser-collectionslist-container {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	max-width: 450px;
	width: 100%;
	padding: 0 20px 0 20px;
}

.gigauser-search-loader {
	position: relative;
	display: flex;
	align-items: center;
}

.gigauser-search-icon {
	display: flex;
	align-items: center;
	height: 24px;
	width: 24px;
	box-sizing: content-box;

	&.gigauser-laptop {
		height: 40px;
	}
}

.gigauser-search-input {
	border: none;
	background-color: transparent;
	color: white;
	padding-left: 14px;
	width: calc(95% - 26px);
	transition: 0.3s;
	font-size: 1.2em;
	font-weight: 600;

	&::placeholder {
		transition: 0.3s;
		color: rgba(255, 255, 255, 0.648);
	}

	&:focus {
		&::placeholder {
			color: transparent;
		}
	}

	&.gigauser-laptop {
		font-size: 1.8em;
	}
}

.gigauser-search-input:focus {
	border: none;
	outline: none;
	padding-left: 10px;
	width: calc(95% - 26px);
}

.gigauser-no-help {
	padding: 0px 15px;
	font-weight: 500;
}

.gigauser-helpcenter-breadcrumbs {
	width: 80%;
	font-size: 1em;
	padding-left: 20px;
	padding-top: 20px;
	text-align: left;
}

.style-scroll {
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: $trackColor;
		// border-top-right-radius: 10px;
		// border-bottom-right-radius: 10px;
		// border-left: 1px solid grey;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $thumbColor;
		// border-radius: 20px;
		// margin-right: 5px;
		border: 2px solid $trackColor;
		padding-top: 10px solid $trackColor;
		// border-left: 1px solid grey;
	}
}

.style-scroll-square {
	/* Works on Chrome, Edge, and Safari */
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		// visibility: hidden;
		transition: 0.3s;
	}

	&::-webkit-scrollbar-track {
		background: transparentize($color: $clipboardGrey, $amount: 0.7);

		// border-top-right-radius: 10px;
		// border-left: 1px solid grey;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #d0d0d0;
		// margin-right: 5px;
		// border-left: 1px solid grey;
	}
	&:hover {
		&::-webkit-scrollbar {
			transition: 0.3s;
			display: block;
		}
	}
}

.gigauser-emptysearch {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.gigauser-mobile {
		margin-top: 20%;
	}

	&.gigauser-laptop {
		margin-top: 20%;
	}

	.gigauser-emptysearch-icon {
		width: 120px;
	}

	.gigauser-emptysearch-title {
		font-weight: bold;
		font-size: 1.3em;
		margin: 10px 0px;
	}

	.gigauser-emptysearch-subtitle {
		font-size: 1.2em;
		margin-top: 20px;
		line-height: 1.8em;
	}
}

.gigauser-helpcenter-guides-container {
	&.gigauser-laptop {
		margin-top: -60px;
		margin-bottom: 30px;
		border: 1px solid $lightGrey;

		background-color: white;
		border-radius: 5px;
		top: 200px;
		width: 50vw;
		padding: 20px 60px;
		box-shadow: $boxShadow;
		min-height: 60vh;
	}

	&.gigauser-mobile {
		box-sizing: border-box;
		overflow-y: auto;
		width: 100%;
	}
}

.gigauser-laptop {
	.gigauser-guideslist-title-help {
		margin-bottom: 10px;
		margin-top: 20px;
	}
}

.gigauser-domain-link {
	color: white;
}

.gigauser-helpcenter-center {
	width: 100%;
}

.gigauser-contact-support {
	padding: 10px 20px;
	font-weight: bold;
	width: fit-content;
	background-color: $clipboardGrey;
	cursor: pointer;

	height: fit-content;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 10px;

	display: flex;
	align-items: center;
	gap: 10px;
	transition: 0.3s;

	&:hover {
		box-shadow: $lighterBoxShadow;

		.gigauser-airplane-icon {
			translate: 5px;
		}
	}
}

.gigauser-airplane-icon {
	width: 20px;
	transition: 0.3s;
	height: 20px;
}

.gigauser-dark {
	.style-scroll-square {
		/* Works on Chrome, Edge, and Safari */

		&::-webkit-scrollbar-track {
			background: $hoverGrey;

			// border-top-right-radius: 10px;
			// border-left: 1px solid grey;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $darkGrey;
			// margin-right: 5px;
			// border-left: 1px solid grey;
		}
		&:hover {
			&::-webkit-scrollbar {
				transition: 0.3s;
				display: block;
			}
		}
	}
}
