@import "../../../styles/colors";
// @import '../../styles/shadows';

$trackColor: white;
$thumbColor: $inputGrey;
$borderRadius: 5px;

:root {
	--my-color: 255, 0, 0; /* red */
}

.gigauser-GuidesList {
	&.gigauser-bordered {
		border-radius: 10px;
		border: 1px solid $mediumGrey;
	}

	padding: 0px 20px;
	position: relative;
}

.gigauser-GuidesList-title {
	margin: 0px;
	margin-bottom: 10px;
	color: $darkestGrey;
	font-size: 1em;
}

.gigauser-GuidesList-guides {
	// border: 1px solid $mediumGrey;
	// border-radius: $borderRadius;
	// box-shadow: $boxShadow;

	overflow-y: auto;
	overflow-x: hidden;
}

.gigauser-guidelist-header {
	display: flex;
	justify-content: center;
	/* align-items: center; */
	flex-flow: column;
	width: 90%;
	gap: 4px;
}

.gigauser-GuidePreview {
	border-bottom: 1px solid $mediumGrey;
	border-top: none;
	display: flex;
	justify-content: space-between;

	transition: 0.3s;
	// border-radius: 10px;
	// margin-bottom: 15px;
	// height: 40px;
	text-align: left;
	padding: 14px 5px;

	&.gigauser-GuidesList-notloading:hover {
		cursor: pointer;

		background-color: rgba(var(--gigauser-brand-color-rgb), 0.05);
		// background-color: var(--gigauser-brand-color);

		.gigauser-Preview-arrow {
			margin-right: 10px;
		}
	}

	&.firstRow {
		// border: 1px solid $mediumGrey;
		border-top: 1px solid $mediumGrey;

		// border-top-right-radius: $borderRadius;
		// border-top-left-radius: $borderRadius;
	}

	&.lastRow {
		margin-bottom: 0px;
		// border-bottom-right-radius: $borderRadius;
		// border-bottom-left-radius: $borderRadius;
		// border-bottom: 1px solid $mediumGrey;
	}

	.gigauser-GuideName {
		font-weight: 600;
		font-size: 14px;

		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.gigauser-GuideDescription {
		margin-top: 5px;
		width: 90%;

		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1; /* number of lines to show */
		line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.gigauser-Preview-arrow {
		transition: 0.3s;
		width: 20px;
		transform: rotate(-90deg);
	}

	.gigauser-loading-guide {
		display: flex;
		align-items: center;
		margin-right: 10px;
	}
}

.gigauser-GuidesList-guides.gigauser-GuidesList-loading {
	.gigauser-GuideName {
		color: transparent;
		background-color: $lightGrey;
		padding: 3px;
		border-radius: 3px;
	}

	.gigauser-GuideDescription {
		padding: 3px;
		border-radius: 3px;
		color: transparent;
		background-color: $lightGrey;

		&:after {
			background: linear-gradient(
				90deg,
				transparent,
				rgba(255, 255, 255, 0.2),
				transparent
			);
			content: "";
			display: block;
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			transform: translateX(-100px);
			animation: gigauserloadingflashes 0.8s infinite;
		}
	}
}

.gigauser-viewAll {
	margin-top: 20px;
	width: fit-content;
	font-weight: bold;
	color: var(--gigauser-brand-color);
	cursor: pointer;
	transition: 0.3s;
	font-size: 0.85em;

	&:hover {
		opacity: 0.8;
	}
}

.gigauser-empty-articles {
	font-size: 1.1em;
	color: $darkGrey;
	text-align: left;
}

.gigauser-GuideList-published {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	.gigauser-published-circle {
		color: transparent;
		height: fit-content;
		width: 8px;
		height: 8px;
		border-radius: 1000px;

		&.gigauser-published {
			background-color: $lightModeGreen;
		}

		&.gigauser-draft {
			background-color: $lightModeOrange;
		}
	}
}

@keyframes gigauserloadingflashes {
	100% {
		transform: translateX(100%);
	}
}
