.gigauser-RichTextToolbar {
	display: flex;
	align-items: center;
	/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
}
.gigauser-RichTextToolbar.gigauser-dark {
	margin-top: 1rem;
	background-color: hsla(222, 19%, 10%, 0.24);
	border: none;
	border-radius: 0;
}
.gigauser-RichTextToolbar.fullWidthToolbar {
	padding: 0.5rem 1rem;
	border-radius: 0.7rem;
	background: rgba(0, 0, 0, 0.24);
	box-shadow: 0 0 0.625rem 0 rgba(255, 255, 255, 0.08);
}
.gigauser-RichTextToolbar.transcriptToolbar {
	border-radius: 0;
}
.gigauser-RichTextToolbar .Transcript-toolbar-container {
	width: 100%;
	background-color: hsl(228, 16%, 18%);
	border-radius: 0.3333rem;
	border: 0.0667rem solid hsl(220, 14%, 20%);
	padding: 0.2rem;
}
.gigauser-RichTextToolbar.gigauser-isTop {
	position: fixed;
	top: 4rem;
	z-index: 999;
	animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.gigauser-RichTextToolbar.gigauser-isTop {
	z-index: 999;
	animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.gigauser-special-button {
	display: flex;
	align-items: center;
	border-radius: 0.3333rem;
	width: -moz-fit-content;
	width: fit-content;
	cursor: pointer;
	border: 0.0667rem solid transparent;
	transition: border 0.3s;
	font-weight: bold;
}
.gigauser-special-button.gigauser-enhance-button {
	color: #d89f03;
	background-color: #fffbec;
}
.gigauser-special-button.gigauser-enhance-button:hover {
	border: 0.0667rem solid #d89f03;
}
.gigauser-special-button.gigauser-generate-button {
	color: #b1acff;
	background-color: rgba(78, 70, 229, 0.4666666667);
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}
.gigauser-special-button.gigauser-generate-button.generate-disabled {
	opacity: 0.5;
	cursor: auto;
}
.gigauser-special-button.gigauser-generate-button.generate-enabled:hover {
	border: 0.0667rem solid #4f46e5;
}
.gigauser-special-button.gigauser-generate-button.generate-enabled:hover
	.gigauser-special-button-icon {
	animation: gigauserRotate 2s infinite linear;
}
.gigauser-special-button .gigauser-special-button-icon {
	height: 1.3333rem;
	width: 1.3333rem;
	margin-left: 0.3333rem;
}
.gigauser-special-button .gigauser-special-button-text {
	font-weight: 600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-left: 0.6667rem;
}
.gigauser-special-button
	.gigauser-special-button-text.gigauser-special-button-generate {
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
}

.gigauser-toolbar-special-buttons {
	display: flex;
	align-items: center;
}
.gigauser-toolbar-special-buttons .down-arrow {
	transition: 0.3s;
	height: 2.6667rem;
	padding-right: 0.2rem;
	padding-left: 0.2rem;
	display: flex;
	border-left: 1px solid rgba(241, 226, 184, 0.5);
	transition: 0.3s;
	padding-left: 0.5rem;
}
.gigauser-toolbar-special-buttons .down-arrow:hover {
	color: white;
}

.toolbar-special-buttons-divider {
	border-left: 1px solid rgba(255, 255, 255, 0.08);
	height: 2rem;
	margin: 0 0.5rem;
}

.gigauser-toolbar-regular-buttons {
	display: flex;
	align-items: center;
}
.gigauser-toolbar-regular-buttons .gigauser-RichTextToolbar-reg-icon-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0.125rem;
	padding: 0.5rem;
	border-radius: 0.25rem;
	position: relative;
	cursor: pointer;
}
.gigauser-toolbar-regular-buttons
	.gigauser-RichTextToolbar-reg-icon-container
	.reg-icon-container-hovertip {
	display: none;
}
.gigauser-toolbar-regular-buttons
	.gigauser-RichTextToolbar-reg-icon-container
	.gigauser-RichTextToolbar-reg-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.25rem;
	height: 1.25rem;
}
.gigauser-toolbar-regular-buttons
	.gigauser-RichTextToolbar-reg-icon-container:hover {
	background-color: rgba(255, 255, 255, 0.08);
	box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2);
}
.gigauser-toolbar-regular-buttons
	.gigauser-RichTextToolbar-reg-icon-container:hover
	.reg-icon-container-hovertip {
	display: block;
}

.gigauser-dark .gigauser-RichTextToolbar-reg-icon-container:hover {
	background-color: rgba(255, 255, 255, 0.08);
	box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2);
}

.boxIcon-container {
	display: flex;
	align-items: center;
	font-size: 1em;
	color: #fe78bd;
	padding: 0.2rem 0.5333rem;
	border-radius: 0.2667rem;
	gap: 0.6667rem;
	cursor: pointer;
	transition: background-color 0.3s;
}
.boxIcon-container .gigauser-voice-name {
	max-width: 5.3333rem;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	line-clamp: 1;
	-webkit-box-orient: vertical;
}
.boxIcon-container .gigauser-RichTextToolbar-voice-icon {
	width: 0.9333rem;
	height: 0.9333rem;
}
.boxIcon-container:hover {
	background-color: rgba(176, 42, 111, 0.145);
}
.boxIcon-container.wandIcon {
	color: #f1e2b8;
	border: 0.0667rem solid rgba(255, 217, 0, 0.326);
	background-color: rgba(241, 226, 184, 0.1);
}
.boxIcon-container.wandIcon:hover {
	background-color: rgba(241, 226, 184, 0.3);
}

.gigauser-dark .gigauser-enhance-button {
	color: #f1e2b8;
	background-color: #7d6f3f;
}
.gigauser-dark .gigauser-enhance-button:hover {
	border: 0.0667rem solid #f1e2b8;
}

.transcriptToolbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-family: Manrope, sans-serif;
}
.transcriptToolbar .ToolbarRow1 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem; /* 171.429% */
	padding-left: 1.2rem;
	padding-right: 1.2rem;
}
.transcriptToolbar .ToolbarRow1 .ToolbarRow1-left {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	gap: 0.35rem;
	margin-right: 0.35rem;
}
.transcriptToolbar .ToolbarRow1 .d-none {
	display: none;
}
@media (min-width: 1440px) {
	.transcriptToolbar .ToolbarRow1 .d-none {
		display: inline;
	}
}
.transcriptToolbar .ToolbarRow1 .genspeech-container {
	width: 56%;
}
.transcriptToolbar .ToolbarRow1 .aiRewrite-container {
	width: 44%;
}
.transcriptToolbar .ToolbarRow1 .addSyncMarker-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 9999px;
	background-color: rgba(255, 255, 255, 0.04);
	border: 1px solid rgba(255, 255, 255, 0.04);
	width: 2.5rem;
	height: 2.5rem;
}
.transcriptToolbar .ToolbarRow1 .addSyncMarker-container:hover {
	border: none;
	background-color: rgba(255, 255, 255, 0.08);
	box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.2);
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 0.75rem;
	gap: 0.5rem;
	height: 100%;
	border-radius: 2rem;
	color: #fafbff;
	background-color: rgba(255, 255, 255, 0.04);
	transition: 0.3s;
	cursor: pointer;
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button .ai-rewrite-button-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 0.5rem;
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button .transbuttonloading {
	margin-right: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 1rem;
	width: 1rem;
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button.disabled {
	opacity: 0.5;
	cursor: pointer;
	pointer-events: none;
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button.enabled:hover {
	background: rgba(255, 255, 255, 0.08);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button .ai-rewrite-icon {
	height: 1.5rem;
	width: 1.5rem;
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button .video-ai-line-separator {
	border-left: 0.0667rem solid #6a6c7b;
	height: 1.2rem;
	margin-left: 0.5rem;
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button .down-icon-container {
	color: #b1b5c9;
	display: flex;
	align-items: center;
	padding: 0.3rem;
	background-color: rgba(255, 255, 255, 0.08);
	border-radius: 9999px;
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button .down-icon-container:hover {
	background-color: hsla(222, 19%, 10%, 0.24);
	box-shadow: 0 0.25rem 0.25rem 0 rgba(255, 255, 255, 0.04);
	transition: all ease 0.3s;
}
.transcriptToolbar
	.ToolbarRow1
	.AI-rewrite-button
	.down-icon-container:hover
	.down-icon {
	transform: translateY(0.05rem);
	transition: transform 0.3s ease;
}
.transcriptToolbar
	.ToolbarRow1
	.AI-rewrite-button
	.down-icon-container
	.down-icon {
	height: 0.8rem;
	width: 0.8rem;
	transition: transform 0.3s ease;
}
.transcriptToolbar .ToolbarRow1 .AI-rewrite-button .d-none {
	display: none;
}
@media (min-width: 1440px) {
	.transcriptToolbar .ToolbarRow1 .AI-rewrite-button .d-none {
		display: inline;
	}
}
.transcriptToolbar .ToolbarRow1 .d-small-none {
	display: none;
}
@media (min-width: 1080px) {
	.transcriptToolbar .ToolbarRow1 .d-small-none {
		display: inline;
	}
}

.ToolbarRow2 {
	position: absolute;
	bottom: 0.6667rem;
	border-radius: 0.3333rem;
	padding: 0.3rem;
	display: flex;
	align-items: center;
	margin-top: 0.3333rem;
	margin-bottom: 0.3333rem;
	z-index: 5;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.TranscriptToolbarButton {
	display: flex;
	padding: 0.5rem;
	border-radius: 999px;
	background-color: hsl(228, 16%, 18%);
	align-items: center;
	justify-content: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	cursor: pointer;
	transition: 0.3s;
}
.TranscriptToolbarButton.disabled {
	opacity: 0.5;
	cursor: pointer;
	pointer-events: none;
}
.TranscriptToolbarButton.minor {
	width: 2.2rem;
	height: 2.2rem;
	background-color: rgba(255, 255, 255, 0.04);
	border: 1px solid rgba(255, 255, 255, 0.08);
	margin: 0.2rem;
	backdrop-filter: blur(0.2rem);
	-webkit-backdrop-filter: blur(0.2rem);
}
.TranscriptToolbarButton.minor:hover {
	background-color: rgba(176, 42, 111, 0.445);
}
.TranscriptToolbarButton.voiceButton .TranscriptToolbarButtonText {
	max-width: 3.5rem;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
}
.TranscriptToolbarButton .transcriptToolbarIcon {
	height: 0.9rem;
	width: 0.9rem;
}
.TranscriptToolbarButton .transcriptToolbarIcon.yestext {
	margin-right: 0.5rem;
}
.TranscriptToolbarButton .TranscriptToolbarButtonText {
	font: 500;
	color: white;
}
.TranscriptToolbarButton .trans-down-icon {
	height: 1rem;
	width: 1rem;
	transition: 0.3s;
	margin-left: 0.3rem;
}
.TranscriptToolbarButton.enabled:hover {
	background-color: hsla(222, 19%, 10%, 0.24);
	border-color: hsl(230, 20%, 35%);
}
.TranscriptToolbarButton.enabled:hover .trans-down-icon {
	transform: translateY(0.2rem);
}

.GenerateCircle {
	position: absolute;
	background-color: #3549cc;
	transition: 0.3s;
	border: 0.1333rem solid transparent;
	border-radius: 66.6rem;
	padding: 1rem;
	box-shadow:
		0 4px 6px 0 rgba(0, 0, 0, 0.2),
		0 3px 10px 0 rgba(0, 0, 0, 0.195);
	cursor: pointer;
	bottom: 1.3333rem;
	right: 4rem;
	z-index: 999;
}
.GenerateCircle:hover {
	background-color: #213196;
	border-color: #3549cc;
}
.GenerateCircle .GenerateIcon {
	height: 2rem;
	width: 2rem;
}

.GenerateSpeechButton {
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	cursor: pointer;
	padding: 0.5rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6.25rem;
	border: 1px solid #e179af;
	color: #e179af;
	background: linear-gradient(
		180deg,
		rgba(225, 121, 175, 0.08) 0%,
		rgba(218, 88, 155, 0.08) 50%,
		rgba(225, 121, 175, 0.08) 100%
	);
	gap: 0.6rem;
	transition: 0.3s;
	z-index: 9;
}
.GenerateSpeechButton.disabled {
	opacity: 0.5;
	cursor: pointer;
}
.GenerateSpeechButton.enabled:hover {
	background: #320b1f;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
.GenerateSpeechButton .generateSpeechIcon {
	height: 1.5rem;
	width: 1.5rem;
}

@keyframes gigauserRotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
} /*# sourceMappingURL=RichTextToolbar.css.map */
