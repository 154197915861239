@import "../../../../styles/colors";

.HelpCenterBrowser {
	// align-items: center;
	display: flex;
	justify-content: center;
	// height: 100%;
	.HelpCenterBrowser-row2 {
		display: flex;
		overflow: hidden;
		width: 100%;
		// justify-content: center;
		max-width: 92em;
	}
}

.gigauser-dark {
	.HelpCenterBrowser {
		background-color: $darkModeBg;
	}
}
