.HelpCenterViewer {
	display: flex;
	width: 100%;
	height: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
	margin-left: 20em;
}
.HelpCenterViewer.remove-margin {
	margin-left: 0;
	justify-content: center;
}
.HelpCenterViewer .ViewerContainer {
	height: 100%;
	padding: 0em 3em;
	box-sizing: border-box; /* Padding and border are included within the width */
	width: 900px;
	max-width: 100%;
	padding-top: 6em;
}
.HelpCenterViewer .ViewerContainer.CollectionViewer {
	width: 1000px;
}
.HelpCenterViewer .TocContainer {
	width: 15%;
	box-sizing: border-box;
	padding: 2em 0em;
	text-align: left;
}
.HelpCenterViewer .TocContainer.hide {
	display: none;
}

.HelpCenter-private-container {
	padding: 20px;
	margin-top: 1em;
	text-align: left;
}

.gigauser-light .HelpCenter-private-container {
	color: #4b4b4b;
}
.gigauser-light .ViewerContainer.ViewOnly {
	border-left: 1px solid var(--gigauser-light-brand-color);
	border-right: 1px solid var(--gigauser-light-brand-color);
}
.gigauser-light .gigauser-viewer-branding {
	color: #727272;
}

.gigauser-dark .HelpCenter-private-container {
	color: #c7c7c7;
}
.gigauser-dark .gigauser-viewer-branding {
	color: #727272;
} /*# sourceMappingURL=HelpCenterViewer.css.map */
