.inline-ai-positioner {
	position: relative;
	background: hsl(225, 19%, 9%);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24);
	display: flex;
	align-items: start;
	border-radius: 0.533rem;
	overflow: hidden;
	flex-direction: column;
	transform-origin: left;
	opacity: 0.92;
	border: 1px solid #424559;
	margin-bottom: 2rem;
	cursor: pointer;
}
.inline-ai-positioner.expanded {
	opacity: 1;
	cursor: default;
}
.inline-ai-positioner:hover {
	opacity: 1;
}
.inline-ai-positioner .inline-ai-top-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}
.inline-ai-positioner .inline-ai-top-row .inline-ai-heading {
	color: white;
	font-family: "Manrope";
	font-weight: 600;
	line-height: 1.6rem;
}
.inline-ai-positioner .inline-ai-top-row .inline-ai-icon {
	width: 1.6rem;
	height: 1.6rem;
}
.inline-ai-positioner .inline-ai-top-row .close-icon {
	height: 1.733rem;
	width: 1.733rem;
	cursor: pointer;
	padding: 0.266rem;
}
.inline-ai-positioner .inline-ai-top-row .close-icon:hover {
	background-color: #212433;
	border-radius: 4px;
}
.inline-ai-positioner .inline-ai-container {
	width: 33.333rem;
}
.inline-ai-positioner .inline-ai-container .scroll-container {
	overflow-y: auto;
	display: flex;
	max-height: 26.6467rem;
	flex-direction: column;
}
.inline-ai-positioner
	.inline-ai-container
	.scroll-container::-webkit-scrollbar-track {
	background-color: hsl(228, 16%, 18%);
}
.inline-ai-positioner
	.inline-ai-container
	.scroll-container::-webkit-scrollbar-thumb {
	background-color: hsl(230, 22%, 30%);
}
.inline-ai-positioner .inline-ai-container .inline-ai-line {
	width: 100%;
	height: 1px;
	background: #262626;
}
.inline-ai-positioner .inline-ai-container .premade-prompt-grid {
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
	display: flex;
	flex-direction: column;
	gap: 0.5333rem;
	overflow: visible;
}
.inline-ai-positioner
	.inline-ai-container
	.premade-prompt-grid
	.premade-prompt-row {
	display: flex;
	flex-direction: row;
	gap: 0.5333rem;
}
.inline-ai-positioner
	.inline-ai-container
	.premade-prompt-grid
	.premade-prompt-row
	.premade-prompt {
	padding: 0.133rem 0.533rem;
	border-radius: 4px;
	border: 1px solid rgba(33, 36, 51, 0.8);
	background: #212433;
	box-shadow: 0px 3.4px 3.4px 0px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: row;
	width: -moz-fit-content;
	width: fit-content;
	gap: 0.533rem;
	transition: 0.3s;
	cursor: pointer;
}
.inline-ai-positioner
	.inline-ai-container
	.premade-prompt-grid
	.premade-prompt-row
	.premade-prompt:hover {
	background: #333545;
}
.inline-ai-positioner
	.inline-ai-container
	.premade-prompt-grid
	.premade-prompt-row
	.premade-prompt
	.prompt-icon {
	width: 1.066rem;
	height: 1.066rem;
	color: #da589b;
}
.inline-ai-positioner
	.inline-ai-container
	.premade-prompt-grid
	.premade-prompt-row
	.premade-prompt
	.premade-prompt-text {
	font-size: 0.8rem;
	color: white;
	margin: 0px;
	font-weight: 600;
	font-family: "Manrope";
}
.inline-ai-positioner .inline-ai-container .inline-ai-text-skeleton {
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
}
.inline-ai-positioner .inline-ai-container .ai-result-container {
	margin-top: 0.2667rem;
}
.inline-ai-positioner .inline-ai-container .ai-result-container .ai-result {
	color: white;
	font-family: Manrope;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	margin-bottom: 0.8rem;
	-webkit-user-select: text;
	-moz-user-select: text;
	user-select: text;
}
.inline-ai-positioner .inline-ai-container .option-select-heading-container {
	display: flex;
	flex-direction: row;
	gap: 0.533rem;
	align-items: center;
	padding-top: 0.8rem;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.magic-wand-icon {
	width: 1.066rem;
	height: 1.066rem;
	color: #b1b5c9;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.loading-gif {
	width: 1.2rem;
	height: 1.2rem;
	flex-shrink: 0;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.ai-result-selector {
	display: flex;
	flex-direction: row;
	gap: 0.533rem;
	color: #b1b5c9;
	font-family: Manrope;
	font-size: 0.933rem;
	font-weight: 500;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.ai-result-selector
	.ai-result-selector-icon-left {
	rotate: 90deg;
	padding: 0.266rem;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.ai-result-selector
	.ai-result-selector-icon-left:hover:not(.disabled) {
	background-color: #212433;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.ai-result-selector
	.ai-result-selector-icon-right {
	rotate: -90deg;
	padding: 0.266rem;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.ai-result-selector
	.ai-result-selector-icon-right:hover:not(.disabled) {
	background-color: #212433;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.rewrite-with-ai-heading {
	background: #b1b5c9;
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	font-family: "Manrope";
	font-size: 1rem;
	font-weight: 600;
	transition: 0.3s;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow-x: hidden;
}
.inline-ai-positioner
	.inline-ai-container
	.option-select-heading-container
	.rewrite-with-ai-heading.loading {
	background: linear-gradient(0deg, #4f46e5 -114.01%, #d43f8c 149.87%);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
}
.inline-ai-positioner .inline-ai-container .bottom-row {
	padding-top: 1.06rem;
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 0.533rem;
}
.inline-ai-positioner .inline-ai-container .bottom-row .prompt-input-border {
	background: linear-gradient(180deg, #d43f8c 0%, #4038b6 100%);
	padding: 1px;
	border-radius: 6px;
	width: 100%;
	display: inline-block;
	height: 2.667rem;
	opacity: 0.6;
	transition: opacity 0.3s ease;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.prompt-input-border:hover:not(.focused) {
	opacity: 0.8;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.prompt-input-border.focused {
	opacity: 1;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.prompt-input-border
	.prompt-input {
	width: 100%;
	height: 100%;
	font-size: 0.933rem;
	padding-left: 1.06rem;
	padding-top: 0.53rem;
	padding-bottom: 0.53rem;
	font-family: "Manrope";
	border: none;
	border-radius: 6px;
	background: #070709;
	color: white;
	position: relative;
	z-index: 1;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.prompt-input-border
	.prompt-input:hover {
	background: black;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.prompt-input-border
	.prompt-input:focus {
	background: black;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.inline-ai-submit-button {
	height: 2.667rem;
	background: linear-gradient(180deg, #d43f8c 0%, #4038b6 100%);
	display: flex;
	justify-content: center;
	gap: 0.53rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 6px;
	color: white;
	font-weight: 600;
	font-family: "Manrope";
	font-size: 0.933rem;
	transition: 0.3s;
	opacity: 1;
	padding-right: 1rem;
	padding-left: 1rem;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.inline-ai-submit-button
	.submit-btn-icon {
	height: 1.06rem;
	width: 1.06rem;
	color: white;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.inline-ai-submit-button.disabled {
	opacity: 0.4;
	transition: 0.3s;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.inline-ai-submit-button:hover {
	transition: 0.3s;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.inline-ai-submit-button:hover:not(.disabled) {
	opacity: 0.8;
}
.inline-ai-positioner .inline-ai-container .bottom-row .btn-group-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	justify-content: space-between;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.copy-ai-btn {
	height: 2.667rem;
	width: 2.667rem;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #212433;
	background: linear-gradient(180deg, #161820 -21.25%, #212433 186.25%);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
	transition: 0.3s;
	color: white;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.copy-ai-btn
	.copy-icon {
	width: 1.33rem;
	height: 1.33rem;
	display: flex;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.copy-ai-btn:hover {
	background: #212433;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.copy-ai-btn.disabled {
	background: #212433;
	cursor: not-allowed;
	border: 1px solid #212433;
	background: #161820;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.replace-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-left: 1.06rem;
	padding-right: 1.06rem;
	height: 2.667rem;
	border-radius: 6px;
	background: #da589b;
	gap: 0.53rem;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
	color: white;
	transition: 0.3s;
	font-size: 1rem;
	font-weight: 500;
	transition: 0.3s;
	opacity: 1;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.replace-btn.disabled {
	opacity: 0.2;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.replace-btn
	.replace-icon {
	width: 1.33rem;
	height: 1.33rem;
	display: flex;
	color: white;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.replace-btn:hover {
	background: #c82c7d;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.try-again-btn {
	display: flex;
	min-width: 8rem;
	padding: 0.6rem 1.06rem;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #212433;
	background: linear-gradient(180deg, #161820 -21.25%, #212433 186.25%);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
	color: white;
	text-align: center;
	font-family: Manrope;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	transition: 0.3s;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.try-again-btn:hover {
	background: #212433;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.try-again-btn.disabled {
	background: #212433;
	color: rgba(250, 251, 255, 0.2);
	cursor: not-allowed;
	border: 1px solid #212433;
	background: #161820;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.customize-btn {
	display: flex;
	height: 2.667rem;
	padding: 0.6rem 1.06rem;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	border: 1px solid #212433;
	background: linear-gradient(180deg, #161820 -21.25%, #212433 186.25%);
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
	color: white;
	text-align: center;
	font-family: Manrope;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	transition: 0.3s;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.customize-btn:hover {
	background: #212433;
}
.inline-ai-positioner
	.inline-ai-container
	.bottom-row
	.btn-group-container
	.btn-group
	.customize-btn.disabled {
	background: #212433;
	color: rgba(250, 251, 255, 0.2);
	cursor: not-allowed;
	border: 1px solid #212433;
	background: #161820;
} /*# sourceMappingURL=InlineAiRewrite.css.map */
