@import "../../../../../../styles/colors";

.gigauser-texteditor-toolbar-blockquote-dropdown {
	position: relative;
	display: flex;
}

.gigauser-texteditor-toolbar-blockquote-dropdown-content {
	display: none;
	position: absolute;
	background-color: $sectionBackground;
	// min-width: 160px;
	width: 54px;
	margin-top: 25px;
	z-index: 1;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.gigauser-texteditor-toolbar-blockquote-dropdown-content-item:hover svg {
	color: white;
	cursor: pointer;
}
//   .gigauser-texteditor-toolbar-blocktype-content * {
//     color: black;
//     text-decoration: none;
//     display: block;
//   }

.gigauser-texteditor-toolbar-blockquote-dropdown:hover
	.gigauser-texteditor-toolbar-blockquote-dropdown-content {
	display: block;
}

.remirror-editor.ProseMirror blockquote {
	position: relative;
	padding: 15px 30px 15px 30px;
	margin: 10px 0;
	border-radius: 5px;
	p {
		margin-bottom: 0;
		font-style: normal;
	}
}

.remirror-editor.ProseMirror blockquote.green {
	p {
		color: darkgreen;
	}
	border-left: 4px solid green;
	background-color: rgb(217, 248, 217);
}

.remirror-editor.ProseMirror blockquote.gray {
	p {
		color: #555;
	}
	border-left: 4px solid $mediumGrey;
	background-color: $clipboardGrey;
}

.remirror-editor.ProseMirror blockquote.red {
	p {
		color: maroon;
	}
	border-left: 4px solid rgb(217, 83, 83);
	background-color: rgb(256, 220, 220);
}

.remirror-editor.ProseMirror blockquote.yellow {
	p {
		color: rgb(131, 96, 8);
	}
	border-left: 4px solid rgb(200, 200, 30);
	background-color: rgb(250, 240, 200);
}

.remirror-editor.ProseMirror blockquote.blue {
	p {
		color: #006a8a;
	}
	border-left: 4px solid #0182a9;
	// border-left: none;
	background-color: #ddf7ff;
}

.remirror-editor.ProseMirror blockquote cite {
	display: block;
	text-align: right;
	color: #333;
	font-size: 0.95em;
}

.TextEditorContainer.gigauser-dark {
	.remirror-editor.ProseMirror blockquote.green {
		p {
			color: rgb(192, 221, 192);
		}
		border-left: 4px solid rgb(69, 159, 69);
		background-color: rgba(0, 100, 0, 0.383);
	}

	.remirror-editor.ProseMirror blockquote.gray {
		p {
			color: $clipboardGrey;
		}
		border-left: 4px solid #8e8e8e;
		background-color: #5555556d;
	}

	.remirror-editor.ProseMirror blockquote.red {
		p {
			color: rgba(256, 220, 220, 1);
		}
		border-left: 4px solid rgb(155, 57, 57);
		background-color: rgba(172, 5, 5, 0.25);
	}

	.remirror-editor.ProseMirror blockquote.yellow {
		p {
			color: rgb(250, 240, 200);
		}
		border-left: 4px solid rgb(190, 169, 82);
		background-color: rgba(185, 135, 11, 0.428);
	}

	.remirror-editor.ProseMirror blockquote.blue {
		p {
			color: #ddf7ff;
		}
		border-left: 4px solid #55b1ce;
		background-color: #0184ab46;
	}

	.remirror-editor.ProseMirror blockquote cite {
		display: block;
		text-align: right;
		color: #333;
		font-size: 0.95em;
	}
}
