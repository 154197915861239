.FolderAccordion {
	width: 100%;
	max-width: 24em;
}

.accordion-folder-container {
	display: flex;
	flex-direction: column;
}
.accordion-folder-container .accordion-folder {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0.2em 0em;
}
.accordion-folder-container .accordion-folder .accordion-folder-name {
	cursor: pointer;
	white-space: nowrap;
	overflow-wrap: unset;
	width: 80%;
	white-space: normal; /* This property makes the text wrap to the next line */
	word-wrap: break-word; /* This property handles long words by breaking them */
}
.accordion-folder-container
	.accordion-folder
	.accordion-folder-name.primary-accordion-folder {
	font-weight: 600;
}
.accordion-folder-container .accordion-folder .accordion-folder-icon {
	padding: 2px;
	cursor: pointer;
	height: 1.4em;
	width: 1.4em;
	transition: 0.3s;
	border-radius: 999px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.accordion-folder-container .accordion-folder .accordion-folder-icon.explored {
	transform: rotate(0deg);
}
.accordion-folder-container
	.accordion-folder
	.accordion-folder-icon.not-explored {
	transform: rotate(-90deg);
}
.accordion-folder-container .accordion-folder .folder-icon-name {
	display: flex;
	cursor: pointer;
}
.accordion-folder-container .accordion-folder .folder-icon-name .folder-icon {
	padding: 0px 5px;
	display: flex;
	align-items: center;
}
.accordion-folder-container .accordion-folder .folder-icon-name .opened {
	font-weight: bold;
}
.accordion-folder-container .isDropActive {
	border: 1px #665aef solid;
	background-color: #e0e7ff;
}
.accordion-folder-container .explored-folder-list {
	padding-left: 20px;
}

.no-folders {
	font-size: 0.9em;
	padding-left: 24px;
	margin-top: 0.4em;
	margin-bottom: 1em;
	color: gray;
}

.accordion-item {
	line-height: 1.4em;
	padding: 0.5em;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	transition: 0.3s;
	border-radius: 5px;
	font-size: 0.9em;
}
.accordion-item.accordion-inactive-item:hover {
	background-color: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.15);
}

.accordion-guide {
	color: #727272;
	margin-bottom: 0.2em;
}

.accordion-active-item {
	background-color: rgb(var(--gigauser-brand-color-rgb), 0.1);
	color: var(--gigauser-brand-color);
	font-weight: bold;
}

.light .accordion-folder-icon:hover {
	background-color: #c7c7c7;
}

.gigauser-dark .accordion-guide-name {
	color: #9ca3af;
}
.gigauser-dark .accordion-folder-name {
	color: white;
}
.gigauser-dark .accordion-active-item {
	background-color: rgb(var(--gigauser-darkMode-brand-color-rgb), 0.1);
	color: var(--gigauser-darkMode-brand-color);
	font-weight: bold;
}
.gigauser-dark .accordion-active-item .accordion-folder-name {
	color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .accordion-active-item .accordion-guide-name {
	color: var(--gigauser-darkMode-brand-color);
}
.gigauser-dark .accordion-inactive-item:hover {
	background-color: rgba(200, 200, 200, 0.05);
}
.gigauser-dark .accordion-folder-icon:hover {
	background-color: #282828;
} /*# sourceMappingURL=FolderAccordion.css.map */
